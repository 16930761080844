import * as Constants from './Constants';

export function hivesMatchingSortOrder(hives, primary, secondary) {
  return hives.filter((hive) => {
    var so = sortOrderComponentsForHive(hive);
    return so[Constants.idxForPrimary] === primary && so[Constants.idxForSecondary] === secondary;
  });
}

export function sortOrderStringForHive(hive) {
  // console.log("sortOrderStringForHive", hive);

  if (!hive) {
    console.error("sortOrderStringForHive: no hive");
    return null;
  }

  if (!hive.fields.sortOrder) {
    console.error("sortOrderStringForHive: no sortOrder on hive");
    return null;
  }

  //console.log('sortOrderStringForHive: hive',hive);
  var sortOrder = hive.fields.sortOrder;
  if (sortOrder) {
    if (sortOrder.value) {
      // var x = sortOrder.value.split(':');
      var x = sortOrder.value;
      //console.log('SO:', hive, sortOrder, x);
      return x;
    }
    else {
      console.error('hive with no sort order value defined', hive);
//!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!
// Att lösa:
// 1. hur hantera de som inte har en sort order angiven? fråga om ska tilldela automatiskt?
// 2. hur hantera redigering? Bara poppa en dialog "Vilket värde till du tilldela p och s?"
//!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!
    }
  }
  else {
    console.error('hive with no sort order defined', hive);
  }

  console.error("hive is missing sort order -> using 0:0", hive);
  return "0:0"; // Undefined sort order -> sort first (but may conflict if any has this value defined!!)
}

export function sortOrderComponentsForHive(hive) {
  var x = sortOrderStringForHive(hive).split(":");
  // console.log("sortOrderComponentsForHive", hive, x);
  return [Number(x[Constants.idxForPrimary]), Number(x[Constants.idxForSecondary])];
  // return x;
}

export function hivesSortedOnSortOrder(unsortedHives) {
    const sortedHives = unsortedHives.sort((h0, h1) => {
      const p0 = Number(sortOrderComponentsForHive(h0)[Constants.idxForPrimary]);
      const p1 = Number(sortOrderComponentsForHive(h1)[Constants.idxForPrimary]);
      const s0 = Number(sortOrderComponentsForHive(h0)[Constants.idxForSecondary]);
      const s1 = Number(sortOrderComponentsForHive(h1)[Constants.idxForSecondary]);
      return p0 > p1 || (p0 === p1 && s0 > s1);
    });
    return sortedHives;
  }

class SortOrderUtil {}

export default SortOrderUtil;
