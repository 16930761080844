import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './../css/HivesOverviewTableItem.css';
import * as Constants from './Constants';
import * as HiveItem from './HiveItem';
import placeholder from '../img/placeholder.png';

// Borde heta SmallHiveItem
class HivesOverviewTableItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiveRecord: this.props.hiveRecord,
    };
  }

  renderBadge(record) {
    const s = record.fields.shortName;

    if (s !== undefined && s.value.length > 0) {
      return (
        <div className="idBadge" key={'HOTI-badge' + record.recordName + new Date()}>
          <div className="caption-small">{s.value}</div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  thumbURL(record) {
    const filename = record.fields.thumbnail;
    
    if (filename !== undefined) { // } && filename.value.length > 0) {
      const s = filename.value.downloadURL;
      if (s !== undefined) { // } && s.value.length > 0) {
        return s;
      }
    }

    return placeholder;
  }

  render() {
    const {hiveRecord} = this.state;
// console.log('hh', hiveRecord);
// <div className="caption-normal">
//   {hiveRecord.fields.description.value}
// </div>
    return (
      <div className="HivesOverviewTableItem" key={'xGOTI'+new Date()}>
        <Link to={'/' + Constants.hiveDetailsPageId + '?id=' + hiveRecord.recordName}>

          <div className="HivesOverviewTableItem-headerArea">
            <div className="caption-normal b center-text">{hiveRecord.fields.name.value}</div>
          </div>

          <div className="HivesOverviewTableItem-inner" key={hiveRecord.recordName}>
            <div className="HivesOverviewTableItem-descriptionArea">

              <div className="p-small">
                {HiveItem.hiveClearTextName(hiveRecord.fields.hiveTypeIndex.value)}<br/>
                {HiveItem.framesClearTextName(hiveRecord.fields.frameTypeIndex.value)}<br/>
                <br/>
                <div className="buttonRowLCR">
                  <div/>
                  {HiveItem.yearColorSymbol(hiveRecord)}
                  <div/>
                </div>
                {HiveItem.queenRaceClearTextName(hiveRecord.fields.queenRaceIndex.value)}<br/>
              </div>
            </div>

            <div className="HivesOverviewTableItem-badge-container">
              <img className="HivesOverviewTableItem-image" src={this.thumbURL(hiveRecord)} alt={hiveRecord.recordName} />
              <div className="HivesOverviewTableItem-badge">{this.renderBadge(hiveRecord)}</div>
            </div>

          </div>

        </Link>
      </div>
    );
  }
}

export default HivesOverviewTableItem;
