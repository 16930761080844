import React, { Component } from 'react';
import * as EventItemUtil from './EventItemUtil';
import './../css/EventTimeLineItem.css';
import EventImageItem from './EventImageItem';
import EventTimeLineVerticalLine from './EventTimeLineVerticalLine';

class EventTimeLineItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventRecord: this.props.eventRecord,
      rowIndex: this.props.rowIndex,
      isFirstInTimeLine: this.props.isFirstInTimeLine,
      isLastInTimeLine: this.props.isLastInTimeLine,
    };
  }

  d0(fields) {
    const numFramesStr = (numberOfBroodFrames) => {
      if (numberOfBroodFrames === 1) {
        return "(" + numberOfBroodFrames + " ram)";
      }
      else if (numberOfBroodFrames > 1) {
        return "(" + numberOfBroodFrames + " ramar)";
      }
      return null;
    }

    const eggVisible = EventItemUtil.marker(fields.eggVisible.value);
    const y = EventItemUtil.marker(fields.larvasVisible.value);
    const qCell = EventItemUtil.marker(fields.queenCellsVisible.value);
    const q = EventItemUtil.marker(fields.queenObserved.value);
    const exp = EventItemUtil.marker(fields.expansionAllowed.value);
    const foodIsAvailable = EventItemUtil.marker(fields.foodIsAvailable.value);
    const numberOfBroodFrames = fields.numberOfBroodFrames.value;

    return (
      <div className="p-small">
        <div className="EventItem-threeColGrid-inspection">
          <div className=" smallcaps uppercase">
            Styrka<br/>{EventItemUtil.hiveStrengthStr(fields.hiveStrength.value)}<br/>
            Temperament<br/>{EventItemUtil.temperamentStr(fields.temperamentTypeIdNumber.value)}<br/>
          </div>
          <div className=" smallcaps uppercase">
            {eggVisible} Ägg<br/>
            {y} Yngel {numFramesStr(numberOfBroodFrames)}<br/>
            {qCell} Viceceller
          </div>
          <div className=" smallcaps uppercase">
            {exp} Expansionsutrymme<br/>
            {foodIsAvailable} Foder<br/>
            {q} Drottning observerad<br/>
          </div>
        </div>
        {EventItemUtil.renderNotes(fields)}
      </div>);
  }

  eventInfo(idx, record) {
    const f = record.fields;

    if (idx === 0) {
      return this.d0(f);
    }
    else if (idx === 1) { // Notes
      return (
        <div>
          {EventItemUtil.renderNotes(f)}
        </div>
      );
    }
    else if (idx === 5) { // Utfordring
      return (
        <div>
          {EventItemUtil.d5(f)}
          {EventItemUtil.renderNotes(f)}
        </div>
      );
    }
    else if (idx === 6) { // Drönarram
      return (
        <div>
          {EventItemUtil.d6(f)}
          {EventItemUtil.renderNotes(f)}
        </div>
      );
    }
    else if (idx === 8) { // Förlorat samhälle
      return (
        <div>
          {EventItemUtil.d8(f)}
          {EventItemUtil.renderNotes(f)}
        </div>
      );
    }
    else if (idx === 12) { // Hälsoåtgärd
      return (
        <div>
          {EventItemUtil.d12(f)}
          {EventItemUtil.renderNotes(f)}
        </div>
      );
    }
    else if (idx === 100) { // Bild
      return (
        <div>
          <div>{EventItemUtil.renderNotes(f)}</div>
          <div className="space-above-small" />
          <div>{EventItemUtil.d100(f)}</div>
          <div className="space-under-small" />
        </div>
      );
    }
    else {
      // case 2: return "Skörd";
      // case 3: return "Inspektion tillsynsman";
      // case 4: // "Invintring";
      // case 7: // "Rensningsflykt";
      // case 9: return "Svärmning";
      // case 10: return "Drottningbyte";
      // case 11: return "Avläggare";
      // case 13: // "Avyttrat samhälle";
      //case 14: //return (<div>{EventItemUtil.renderNotes(f)}</div>);

      return (
        <div>
          {EventItemUtil.renderNotes(f)}
        </div>
      );
    }
  }

  renderEventName(idx) {
    return (
      <div className="EventItem-eventName caption-small">{EventItemUtil.nameOfEvent(idx)}</div>
    );
  }

  renderDate(eventRecord) {
    // {EventItemUtil.formattedDate(eventRecord.fields.date.value)}
    return (
      <div className="EventItem-date-table caption-small">
        {EventItemUtil.formattedDateMonthDay(eventRecord.fields.date.value)}
      </div>
    );
  }

  renderEvent(idx, eventRecord, rowIndex) {
    const {isFirstInTimeLine, isLastInTimeLine} = this.state;
    const cnBkg = rowIndex % 2 ? "EventItem-table-bkg-even" : "EventItem-table-bkg-odd";
    const renderEventTimeLineVerticalLine = (shouldHide) => {
        return (
          <EventTimeLineVerticalLine invisible={shouldHide} />
        );
    };

    const iconClass = EventItemUtil.isGroupEvent(eventRecord) ? "EventItem-icon-groupEvent" : "EventItem-icon-hiveEvent";

    // Print no title for Bild or Film
    const titleString = idx !== 100 && idx !== 101 ? this.renderEventName(idx) : null

    return (
      <div className={cnBkg}>
        <div className="EventItem-timeline">

          <div className="EventItem-imageWithLineArea">
            {renderEventTimeLineVerticalLine(isFirstInTimeLine)}
            <div className={iconClass}>
              <EventImageItem image={EventItemUtil.iconForEvent(idx)} />
            </div>
            {renderEventTimeLineVerticalLine(isLastInTimeLine)}
          </div>

          <div className="EventsItem-nameDescriptionArea-timeline">
            {titleString}
            <div className="p-small">{this.eventInfo(idx, eventRecord)}</div>
          </div>

          <div className="EventItem-date-timeline noWrap">
            {this.renderDate(eventRecord)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {eventRecord, rowIndex} = this.state;
    const idx = parseInt(eventRecord.fields.typeId.value, 10);

    return (
      <div>
        {this.renderEvent(idx, eventRecord, rowIndex)}
      </div>
    );
  }
}

export default EventTimeLineItem;
