import React, { Component } from 'react';
import * as Constants from './Constants';
import './../css/ContactPage.css';
import PageHeader from './PageHeader';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
    };
  }

  render() {
    const {title} = this.state;

    return (
      <div>
        <PageHeader title={title} hidePrintButton={true} />
        <div className="ContactPage">

          <div className="underlineRow space-above-medium">Support och feedback</div>
          <div className="buttonRowLCR space-above-small">
            <div className="p-small">Support och feedback tas tacksamt emot på den här adressen.</div>
            <div/>
            <a href={Constants.link__supportEmailaHref()}>{Constants.link__supportEmail}</a>
          </div>

        </div>
      </div>
    );
  }
}

export default ContactPage;
