import React, { Component } from 'react';
import './../css/UnderlinedIdAndCaptionSeparator.css';

class UnderlinedIdAndCaptionSeparator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
    };
  }

  render() {
    const {title} = this.state;
    return (
      <div className="underlineRow p-normal center-text space-above-medium">{title}</div>
    );
  }
}

export default UnderlinedIdAndCaptionSeparator;
