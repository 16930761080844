import appStoreIcon_en from './../img/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';
import appStoreIcon_se from './../img/Download_on_the_App_Store_Badge_SE_RGB_wht_100317.svg';


//  ============================================================================
//  BUILD TIME CONFIGURATIONS
//  ============================================================================
export const releaseMode = true; // Use this to toggle between dev/prod
export const debugMode = !releaseMode;
export const releaseVersion = "230612.0";


//  ============================================================================
//  RUN TIME CONFIGURATIONS
//  ============================================================================

// NOTE: Default to se. The window object is used to allow for configuration by the app in runtime.
export const languageIndexes = {
  se: 0,
  en: 1,
};
window.languageIndex = languageIndexes.en;

export const languageName = () => { return [
  'Svenska',
  'English',
][window.languageIndex];};


//  ============================================================================
//  CONSTANTS
//  ============================================================================

// Generate a web token through CloudKit Dashboard.
const apiToken_prod = 'e17b131946e4ab05261fd14f39a3b258ff02eee45a318fb501bc6d164c6f20c2';
const apiToken_dev = '067022d3bac2e03b394d100b032ba3a885a9884c65d853d992468a0321d3de49';
export const apiToken = releaseMode === true ? apiToken_prod : apiToken_dev;

const environment_prod = window.CloudKit.PRODUCTION_ENVIRONMENT;
const environment_dev = window.CloudKit.DEVELOPMENT_ENVIRONMENT;
export const environment = releaseMode === true ? environment_prod : environment_dev;

const defaultZoneName_prod = 'hive-yard-zone-v102';
const defaultZoneName_dev = 'zone-dev';
// const defaultZoneName_dev = 'zone-demo'; -- 200323: vill nog röra mig bort från demo
export const defaultZoneName = releaseMode === true ? defaultZoneName_prod : defaultZoneName_dev;

export const cloudKitLocale = () => { return [
  "sv-se",
  'en-us',
][window.languageIndex];};

export const mapKitLangugageCode = () => { return [
  "sv",
  "en",
][window.languageIndex];};

export const appName = () => { return [
  "Mina bigårdar",
  "My Apiaries",
][window.languageIndex];};

export const overviewPageId = "overview";
export const allGroupsPageId = "groups";
export const groupDetailsPageId = "group";
export const allHivesPageId = "hives";
export const hiveDetailsPageId = "hive";
export const statisticsPageId = "statistics";
export const userGuidePageId = "userguide";
export const toolsPageId = "tools";
export const contactPageId = "contact";
//export const newsletterPageId = "newsletter"


//
//  MENU TITLES
//
export const overviewPageTitle = () => { return [
  "Översikt",
  "Overview",
][window.languageIndex];};
export const allHivesPageTitle = () => { return [
  "Samhällen",
  "Hives",
][window.languageIndex];};
export const hiveDetailsPageTitle = () => { return [
  "Samhälle",
  "Hive",
][window.languageIndex];};
export const allGroupsPageTitle = () => { return [
  "Bigårdar",
  "Apiaries",
][window.languageIndex];};
export const groupDetailsPageTitle = () => { return [
  "Bigård",
  "Apiary",
][window.languageIndex];};
export const statisticsPageTitle = () => { return [
  "Statistik",
  "Statistics",
][window.languageIndex];};
export const userGuidePageTitle = () => { return [
  "Användarguide",
  "User Guide",
][window.languageIndex];};
export const toolsPageTitle = () => { return [ // TODO: Borde heta Export istället för Tools
  "Exportera data",
  "Export data",
][window.languageIndex];};
export const contactPageTitle = () => { return [
  "Kontakt",
  "Contact",
][window.languageIndex];};
//export const newsletterPageTitle = () => { return [
//  "Nyhetsbrev",
//  "News letter",
//][window.languageIndex];};


//
//  Sort order
//  A sort order string is like "1:2" where 1 is primary and 2 is secondary
//
export const idxForPrimary = 0;
export const idxForSecondary = 1;


//
//  EVENTS
//
export const event__setupAuthDone = "event__setupAuthDone";
export const event__userSignedIn = "event__userSignedIn";


export const link__appStore = "https://itunes.apple.com/se/app/mina-bigårdar/id1287634692?mt=8";
//export const link__googlePlay = "https://itunes.apple.com/se/app/mina-bigårdar/id1287634692?mt=8";

export const link__supportEmail = "info@minabigardar.se";
export const link__supportEmailaHref = () => {
  const commonPart = 'mailto:' + link__supportEmail + "?subject=Support: " + appName();
  return [
    commonPart + " [SE]",
    commonPart + " [EN]",
][window.languageIndex];};

export const link__newsletterEmail = "newsletter@minabigardar.se";
export const link__newsletterEmailaHref = () => {
  const commonPart = 'mailto:' + link__newsletterEmail + "?subject=Newsletter: " + appName();
  return [
    commonPart + " [SE]",
    commonPart + " [EN]",
][window.languageIndex];};


export const icon__appStoreDownload = () => { return [
  appStoreIcon_se,
  appStoreIcon_en,
][window.languageIndex];};


//
//  HARVEST
//
export const harvestTypeNames = ['Honung', 'Propolis', 'Pollen'];

//
//  EVENT
//
export const eventTypeTitles = ['Datum', 'Händelse', 'Styrka', 'Temperament', 'Synliga ägg', 'Synligt yngel', 'Drottning observerad', 'Viceceller synliga', 'Antal ramar yngel', 'Tillgång till foder', 'Expansionsutrymme', 'Kommentarer'];


export const event__statisticsSelectedYearChanged = "event__statisticsSelectedYearChanged";
