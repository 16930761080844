import React, { Component } from 'react';
import * as Constants from './Constants';
//import '../css/DownloadApp.css';

class DownloadApp extends Component {
//<div className="p-medium">Testa appen kostnadsfritt ner appen till iPhone/iPad</div>
  render() {
    return (
      <div className="center-text">
        <a href={Constants.link__appStore} target="_blank" rel="noopener noreferrer">
          <img src={Constants.icon__appStoreDownload()} alt="" />
        </a>
        <div className="p-tiny">
          Appen är kostnadsfri med upp till<br/>
          två registrerade samhällen/bigårdar
        </div>
      </div>
    );
  }
}

export default DownloadApp;
