import React, { Component } from 'react';
import './../css/ToggleDetailsItem.css';

import collapseIcon from './../img/icons8-collapse-arrow-52.png';
import expandIcon from './../img/icons8-expand-arrow-52.png';

class ToggleDetailsItem extends Component {
  constructor(props) {
    super(props);

    var callbackToggleOn = this.props.callbackToggleOn;
    var toggleState = this.props.toggleState;
    if (this.props.eventsCount === 0) {
      console.log('setting toggleState to false since no events');
      toggleState = false;
      callbackToggleOn = null;
    }



    // const getTitle = () => {
    //   const {toggleState, title} = this.state;
    //   if (toggleState) {
    //     return 'Göm händelser';//Minimera
    //   }
    //   else {
    //     return title;
    //   }
    // }

    this.state = {
      toggleState: toggleState, // true or false
      callbackToggleOn: callbackToggleOn,
      callbackToggleOff: this.props.callbackToggleOff,
      eventsCount: this.props.eventsCount,
    };
  }

  getTitle() {
    const {toggleState, eventsCount} = this.state;
    var eventsStr = "";

    if (toggleState) {
      return 'Göm händelser';
    }
    else {
      switch (eventsCount) {
        case 0:
          eventsStr = 'Inga händelser';
          break;
        case 1:
          eventsStr = eventsCount + ' händelse';
          break;
        default:
          eventsStr = eventsCount + ' händelser';
      }
    }

    return (
      <div>{eventsStr}</div>
    );
  };

  icon() {
    if (this.state.toggleState) {
      return collapseIcon;
    }
    else {
      return expandIcon;
    }
  }

  render() {
    const {callbackToggleOn} = this.state;

    const toggle = () => {
      const {toggleState, callbackToggleOn, callbackToggleOff} = this.state;
      if (toggleState) {
        callbackToggleOff();
        this.setState({toggleState: false});
      }
      else {
        callbackToggleOn();
        this.setState({toggleState: true});
      }
    }

    if (callbackToggleOn === null) {
      return (
        <div className="ToggleDetailsItem">
          <div className="caption-small">{this.getTitle()}</div>
        </div>
      );
    }
    else {
      return (
        <div className="ToggleDetailsItem" onClick={toggle}>
          <div className="caption-small">{this.getTitle()}</div>
          <img src={this.icon()} className="ToggleDetailsItem-icon" alt='' />
        </div>
      );
    }

  }
}

export default ToggleDetailsItem;
