import React, { Component } from 'react';
import './../css/PageHeader.css';

class PageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      hidePrintButton: this.props.hidePrintButton,
    };
  }

  renderPrintButton() {
    const {hidePrintButton} = this.state;
    if (hidePrintButton) {
      return null;
    }
    else {
      return (
        <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
          <div className="caption-small">Skriv ut</div>
        </div>
      );
    }
  }

  render() {
    const {title} = this.state;
    return (
      <div className="PageHeader">
        <div className="caption-big">{title}</div>
        {this.renderPrintButton()}
      </div>
    );
  }
}

export default PageHeader;
