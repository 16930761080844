import React, { Component } from 'react';
import './../css/OverviewPage.css';
import LoadingIndicator from './LoadingIndicator';
import PageHeader from './PageHeader';
import MapOverview from './MapOverview';
import MapOverviewTable from './MapOverviewTable';

class OverviewPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      zones: this.props.zones, // {private, shared} where shared is an array
      groups: this.props.groups, // {private, shared}
      hives: this.props.hives, // {private, shared}
    };
  }

  render() {
    const {zones, title, groups, hives} = this.state;
    const privateGroups = groups.private;
    const sharedGroups = groups.shared;

    // console.log('MEEPZp', zones.private);
    // console.log('MEEPZs', zones.shared);
    // console.log('MEEPGp', groups.private);
    // console.log('MEEPGs', groups.shared);

    if (zones.private !== null && zones.shared !== null) {
      if (privateGroups && sharedGroups) {
        const allGroups = privateGroups.concat(sharedGroups);
        return (
          <div>
            <PageHeader title={title} hidePrintButton={true} />

            <div className="OverviewPage">
              <MapOverview groupRecords={allGroups} />

              <MapOverviewTable
                privateGroupRecords={privateGroups}
                sharedGroupRecords={sharedGroups}
                hives={hives}
                key={'MOT' + new Date()} />
            </div>
          </div>
        );
      }
      else {
        return <LoadingIndicator label={'OP, Groups...'}/>;
      }
    }
    else {
      return <LoadingIndicator label={'Overview...'}/>;
    }
  }
}

export default OverviewPage;
