import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Constants from './Constants';
import './../css/GroupDetailsPage.css';
import GroupDetail from './GroupDetail';
import PageHeader from './PageHeader';

class GroupDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      groupRecord: this.props.groupRecord,
      hivesInGroup: this.props.hivesInGroup,
    };
  }

  render() {
    const {title, groupRecord, hivesInGroup} = this.state;
    return (
      <div className="GroupPage">
        <PageHeader title={title} hidePrintButton={true} />

        <div className="buttonRowLCR">
          <div>
            <Link to={'/' + Constants.allGroupsPageId}>
              <div className="generic-button">
                <div className="caption-small">Visa alla bigårdar</div>
              </div>
            </Link>
          </div>

          <div />

          <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
            <div className="caption-small">Skriv ut</div>
          </div>
        </div>

        <div>
          <GroupDetail
            groupRecord={groupRecord}
            hivesInGroup={hivesInGroup}
            link={null}
            key={groupRecord.recordName} />
        </div>
      </div>
    );
  }
}

export default GroupDetailsPage;
