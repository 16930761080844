import imgTools from './../../img/tab-tools.png';
import imgHives from './../../img/tab-hives.png';
import imgQR from './../../img/tab-qr.png';
import imgGroups from './../../img/tab-groups.png';


//
//  SUB PAGE: APP STRUCTURE
//

const topic0 = {
  icon: imgGroups,
  topicTitle: null,
  subTopics: [
    {
      title: 'Bigårdar',
      text: 'Här presenteras alla dina bigårdar i bokstavsordning. Om du placerat dem på kartan visas de med en kartbild. Tryck på en bigård för att gå till detaljvyn för just denna bigård.',
    },
    {
      title: 'Bigårdar > Detaljvy',
      text: 'I detaljvyn presenteras all information om en bigård. Redigera information genom att trycka på dess rubrik eller via menyn (≡). Du kan ange ett unikt ID för varje bigård, ange markägare, placering på kartan, om uppställningen är temporär eller permanent, samt anteckningar. Här presenteras också de samhällen som ingår i bigården, inklusive senaste händelsen i varje samhälle. Här kan du också skapa bigårdhändelser (se Arbeta med appen > Bigårdhändelser).',
    },
  ],
};

const topic1 = {
  icon: imgHives,
  topicTitle: null,
  subTopics: [
    {
      title: 'Samhällen',
      text: 'Den andra symbolen nedtill på skärmen är Samhällen. Här presenteras alla samhällen med den bild som biodlaren valt från fotorullen eller fotograferat med appen. Samhällena är sorterade i en sektion per bigård. När biodlaren till arbeta med ett enskilt samhälle görs ett tryck på samhället för att komma till en detaljvy. Här visas en tidslinjen över allt arbete som loggats i samhället (s k händelser, se nedan). Tidslinjen ger snabbt en överblick över när och vad som gjorts i samhället, motsvarande ett skötselkort. Här kan även information om drottningen och kupans konfiguration sparas.',
    },
  ],
};

const topic2 = {
  icon: imgTools,
  topicTitle: null,
  subTopics: [
    {
      title: 'Hjälpmedel',
      text: 'Den tredje symbolen nedtill på skärmen är Hjälpmedel. Här skulle många tänkbara små hjälpmedel kunna ta plats. Men än så länge har endast ett hittat hit, nämligen en liten förteckning som visar vilket år som har vilken färg vid drottningmärkning. Fler, betydligt mer användbara hjälpmedel är planerade till en kommande uppdatering.',
    },
  ],
};

const topic3 = {
  icon: imgQR,
  topicTitle: null,
  subTopics: [
    {
      title: 'QR-läsare',
      text: 'När antalet samhällen är stort kan QR-koder vara ett smidigt sätt att hitta enskilda bigårdar eller samhällen i appen. En QR-kod är en sorts streckkod, och appen hjälper dig att skapa dessa i form av en PDF-fil. Denna kan skrivas ut, lamineras och klippas i bitar. De ca 5x5 cm stora koderna är unika och hör samman med en bigård eller ett samhälle. Koderna fästes t ex på kuporna, och läses sedan av med appens inbyggda QR-läsaren (fjärde symbolen nedtill på skärmen). Läsaren använder telefonens kamera och en avläsning är klar på under en sekund. Planen är att den här metoden ska kunna användas även för att läsa av enskilda lådor i framtiden, vilket potentiellt kan effektivisera t ex inventering och slungning.',
    },
  ],
};

export const subPage_appStructure = {
  title: 'Appens uppbyggnad',
  topics: [
    topic0,
    topic1,
    topic2,
    topic3,
  ],
};



//
//  SUB PAGE: TOOLS
//

const tools_topic0 = {
  icon: null,
  topicTitle: 'Rapporter',
  subTopics: [
    {
      title: 'Översiktsdokument',
      text: 'Översiktsdokumentet skapas i form av en PDF-fil, som sedan kan skrivas ut. Dokumentet innehåller en bigårdsförteckning och en karta med alla uppställningsplatser utmärkta, följt av en sida per bigård där all tillgänglig information såsom markägare, kartbild, koordinater och en lista på samhällen presenteras.',
    },
  ],
};

const tools_topic1 = {
  icon: null,
  topicTitle: 'Rapportera uppställningsplats till Länsstyrelsen',
  subTopics: [
    {
      title: 'Pappersblankett',
      text: 'Tidigare har alla behövt skicka in en pappersblankett med posten. Appen hjälper dig med att skapa denna blankett och automatiskt fylla i alla uppgifter så det bara är för dig att skriva ut och skickas in den. ',
    },
    {
      title: 'Elektronisk',
      text: 'Om du har uppställningsplatser i rätt län kan du rapportera helt elektroniskt direkt i appen (via email). Funktionen är ny för 2018 och är än så länge bara tillgänglig för uppställningsplatser i Östergötland, Jönköping, Kalmar, Växjö, Blekinge och Skåne.',
    },
  ],
};


const tools_topic2 = {
  icon: null,
  topicTitle: 'Skörd av honung, pollen eller propolis',
  subTopics: [
    {
      title: '',
      text: 'Använd detta verktyg för att registrera en skörd och få hjälp att skapa händelser som ger information om mängd skörd, batch-nummer mm. Du anger mängd honung, pollen eller propolis samt vilka samhällen som ingår i skörden. Du anger även antal ramar per samhälle, vilket låter appen göra en uppskattning av mängd per samhälle. Du kan ha en eller flera pågående skördomgångar, vilka stängs först när du väljer att slutföra. Då skapas händelser på de ingående samhällena, och skördomgången flyttas till historiken. Arbetsgången är:<br>1. Skapa en ny skördomgång 2. Ange vilka individuella samhällen som ska ingå i skörden. Du kan ändra detta ända fram till att du slutför omgången. 3. När du erhållit ett resultat anger du mängd och enhet (gr eller kg) och väljer sedan Slutför. En händelse av typen Skörd skapas då på varje samhälle, med information om den beräknade mängden honung, pollen eller propolis per samhälle.',
    },
  ],
};


export const subPage_tools = {
  title: 'Verktyg',
  topics: [
    tools_topic0,
    tools_topic1,
    tools_topic2,
  ],
};



//
//  SUB PAGE: INTEGRITY
//

const integrity_topic0 = {
  icon: null,
  topicTitle: 'Varför ber appen om tillgång till...',
  subTopics: [
    {
      title: 'Kontakter (valfritt)',
      text: 'Du väljer själv om appen ska få tillgång till dina kontakter. Detta låter dig ange en markägare för varje bigård genom att välja från adressboken. På så vis håller appen sig uppdaterad med informationen i adressboken.',
    },
    {
      title: 'Platsinformation (valfritt)',
      text: 'Du väljer själv om appen ska få tillgång till din platsinformation. Detta låter dig ange bigårdarnas uppställningsplatser genom att placera dem på en karta (du slipper alltså ange koordinater manuellt). Detta används både för att skapa en kartbild som ikon för bigården, men också i de rapporter du skapar, samt ger möjlighet att rapportera uppställningsplats till Länsstyrelsen direkt i appen.',
    },
    {
      title: 'iCloud och iCloud Drive (krävs)',
      text: 'Appen kräver att du är inloggad i ditt iCloud-konto, samt att iCloud Drive är aktiverat i iCloud-inställningarna. Genom att lagra dina data i iCloud blir all data säkerhetskopierad, vilket innebär att ingen information försvinner om du t ex förlorar din telefon. När du installerar om appen återställs all data automatiskt.',
    },
    {
      title: 'Notiser (valfritt, men rekommenderas!)',
      text: 'Notiser krävs om du har appen installerad på flera enheter. De ser till att informera alla enheter när förändringar sker på någon av dem. Notiserna hanteras helt i bakgrunden och kommer inte vara synliga för dig som användare.',
    },
  ],
};

const integrity_topic1 = {
  icon: null,
  topicTitle: 'Vem har tillgång till min information?',
  subTopics: [
    {
      title: 'Lagring i iCloud',
      text: 'Din information lagras i ditt användarkonto i iCloud, där den endast är åtkomlig för dig. Ingen av din information analyseras eller sprids av Apple, som tillhandahåller iCloud.',
    },
    {
      title: 'Säljs min information vidare?',
      text: 'NEJ. Ingen av din information förmedlas vidare eller säljs. Informationen skickas endast vidare då du väljer att rapportera uppgifter till myndigheter via appen, vilket är valfritt och något du aktivt måste välja ska ske själv.',
    },
  ],
};


export const subPage_integrity = {
  title: 'Integritet',
  topics: [
    integrity_topic0,
    integrity_topic1,
  ],
};



//
//  SUB PAGE: FUNCTIONALITY
//

const functionality_topic0 = {
  icon: null,
  topicTitle: 'Händelser',
  subTopics: [
    {
      title: 'Händelse på samhälle',
      text: 'Appen bygger på att du loggar ditt arbete i bigården genom att skapa händelser. Dessa ligger till grund för presentation av tidslinjen/skötselkortet för ett samhälle, med även för statistik och uppföljning. Välj rätt typ av händelse, eller välj Anteckning om du vill notera något allmänt.',
    },
    {
      title: 'Händelse på bigård',
      text: 'Om du utför samma händelse på en hel bigård skapar du en Bigårdhändelse. Detta kommer logga en likadan händelse på varje samhällen i bigården.',
    },
  ],
};

const functionality_topic1 = {
  icon: null,
  topicTitle: 'Adress & kontaktinformation',
  subTopics: [
    {
      title: 'Kontaktuppgifter',
      text: 'Du kan ange dina kontaktuppgifter i Inställningarna. Då kommer dessa visas i de PDF-rapporter du skapar, samt fylla i rapporter och email automatiskt. Informationen är din privata och finns inte tillgänglig för någon annan än dig.',
    },
    {
      title: 'Företagsinformation',
      text: 'Om du har ett företag kan du ange företagsnamn och organisationsnummer i Inställningarna. Då kommer detta visas i de PDF-rapporter du skapar. Det finns även möjlighet att ange en extra rad med valfri information.',
    },
  ],
};

const functionality_topic2 = {
  icon: null,
  topicTitle: 'Redigera information',
  subTopics: [
    {
      title: 'Tryck på det du vill redigera',
      text: 'Överallt i appen kan du tryck på den information eller rubrik du vill redigera. Du kan oftast även välja att redigera via menyn.',
    },
    {
      title: 'Redigera datum på händelse',
      text: 'Du kan redigera datumet på en händelse efter att den skapats genom att trycka på själva datumet.',
    },
    {
      title: 'Diktering',
      text: 'Du kan diktera text överallt där du matar in information via tangentbordet. Du dikterar genom att hålla in mikrofon-symbolen.',
    },
  ],
};

const functionality_topic3 = {
  icon: null,
  topicTitle: 'Uppställningsplats inom en bigård',
  subTopics: [
    {
      title: 'Sortera kupornas plats inom bigården',
      text: 'Du kan ändra ordning på dina kupor/samhällen genom att dra och flytta dem under Samhällen.',
    },
  ],
};

const functionality_topic4 = {
  icon: null,
  topicTitle: 'Dela & samarbeta',
  subTopics: [
    {
      title: 'Arbeta på flera enheter',
      text: 'Du kan installera appen på flera enheter, t ex på en iPhone och en iPad. Dessa kommer hålla sig synkroniserade med varandra helt automatiskt, under förutsättning att de använder samma iCloud-konto.',
    },
    {
      title: 'Dela ut bigårdar eller samhällen',
      text: 'I menyn för en bigård kan du välja Delning. Detta tillåter dig att dela ut en bigård till en eller flera personer som också har appen. Du väljer själv vem som ska kunna göra ändringar eller bara se din information. Du kan ändra detta i efterhand, och även när som helst stoppa utdelningen. Du kan bara dela ut hela bigårdar, och delar då ut all information i den, dvs samhällen och händelser.',
    },
  ],
};

const functionality_topic5 = {
  icon: null,
  topicTitle: 'Arbeta utan Internet-uppkoppling',
  subTopics: [
    {
      title: 'Offlinestöd',
      text: 'Appen är gjord för att fungera utan Internetuppkoppling. Så snart appen återfår Internetuppkoppling kommer den nya informationen synkroniseras och därmed säkerhetskopieras.',
    },
  ],
};

export const subPage_functionality = {
  title: 'Funktionalitet',
  topics: [
    functionality_topic0,
    functionality_topic1,
    functionality_topic2,
    functionality_topic3,
    functionality_topic4,
    functionality_topic5,
  ],
};



//
//  GENERIC
//

export const subPages = [
    subPage_appStructure,
    subPage_tools,
    subPage_integrity,
    subPage_functionality,
  ];

function getAllTitles() {
  var allTitles = [];
  for (var i = 0; i < subPages.length; i++) {
    allTitles.push(subPages[i].title);
  }
  return allTitles;
}
export const subPage_titles = getAllTitles();
