import React, { Component } from 'react';
import LoadingIndicator from './../LoadingIndicator';
import GenericBarDiagram from './GenericBarDiagram';
import * as GenericDiagramUtil from './GenericDiagramUtil';

class HiveDeathDiagramArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // selectedYear: this.props.selectedYear, // The year to show data for
      groups: this.props.groups, // {private, shared}
      allHives: this.props.allHives, // {private, shared}
      allEventsOfType4: null, // Invintring
      allEventsOfType8: null, // Förlorat samh
    };
  }

  componentDidMount() {
    const ck = window.ckUtilInstance;
    ck.loadEventsWithEventType(ck.privateDb(), 4/*invintring*/)
      .then((events4) => {
        ck.loadEventsWithEventType(ck.privateDb(), 8/*invintring*/)
          .then((events8) => {
            this.setState({
              allEventsOfType4: events4,
              allEventsOfType8: events8,
            });
          });
      });
  }

  // renderBarDiagramGeneric(divId, allLabels, allData, allSectionLabels, options) {
  //   var allDataSets = [];
  //   for (var i=0; i<allLabels.length; i++) {
  //     allDataSets.push({
  //       label: allLabels[i],
  //       data: allData[i],
  //       backgroundColor: GenericDiagramUtil.greenRedColors[i],
  //       borderColor: GenericDiagramUtil.greenRedColorsLine[i],
  //       borderWidth: 1,
  //     });
  //   }
  //
  //   const data = {
  //     labels: allSectionLabels,
  //     datasets: allDataSets,
  //   };
  //
  //   return(
  //     <div key={divId+Date()}>
  //       <GenericBarDiagram
  //         divId={divId}
  //         diagramType={'bar'}
  //         chartData={data}
  //         allSectionLabels={allSectionLabels}
  //         showNoDataAvailable={data.datasets.length === 0}
  //         chartOptions={options} />
  //     </div>
  //   );
  // }

  // Invintring vs Förlorade samhällen

  // NOTE: Tag med alla år som det har invintrats + året efter.
  // Tag även med alla år där det förekommit förlust före slutdatumet (1 maj?)
  getYearsOfInterestForHiveDeath() {
    const {allEventsOfType4, allEventsOfType8} = this.state;

    var years4 = [];
    var years8 = [];

    for (var i4=0; i4<allEventsOfType4.length; i4++) {
      years4.push(new Date(allEventsOfType4[i4].fields.date.value));
    }
    //console.log('i4', years4);

    for (var i8=0; i8<allEventsOfType8.length; i8++) {
      years8.push(new Date(allEventsOfType8[i8].fields.date.value));
    }
    //console.log('i8', years8);

    if (years4.length === 0) {
      console.log('inga invintringar!!!');
      return [];
    }
    // else if (years8.length === 0) {
    //   console.log('inga förluster!!!');
    //   return [];
    // }
    // else if (years.length === 1) {
    //   return [years[0]];
    // }
    else {
      years4.sort(function(a, b) {
        return new Date(a) - new Date(b);
      });

      years8.sort(function(a, b) {
        return new Date(a) - new Date(b);
      });

      // Get min and max for invintringar
      const minY4 = new Date(years4[0]).getFullYear();
      const maxY4 = new Date(years4[years4.length - 1]).getFullYear();

      // Get min and max for förluster (if any)
      var minY8 = minY4;
      var maxY8 = maxY4;
      if (years8.length > 0) {
        minY8 = new Date(years8[0]).getFullYear();
        maxY8 = new Date(years8[years8.length - 1]).getFullYear();
      }

      // Calcualte the range. Use the last year + 1 for invintringar
      const minY = Math.min(minY4, minY8);
      const maxY = Math.max(maxY4 + 1, maxY8);
      //console.log('GGG', minY, maxY, years4, years8);

      var allYearsInRange = [];
      for (var y=minY; y<=maxY; y++) {
        allYearsInRange.push(y);
      }

      //console.log('GGG-->', allYearsInRange);
      return allYearsInRange;
    }
  }

  renderBarDiagram_percentDeath(divId) {
    var allLabels = [];
    var allData = [];

    const years = this.getYearsOfInterestForHiveDeath();
    for (var i=0; i<(years.length - 1); i++) {
      allLabels.push(years[i] + '/' + years[i+1]);

      const eventsInvintradeDuringSeasonY0 = this.eventsOfTypeForPeriod(
        4/*invintring*/,
        this.getSettingDateOfInvintradeHiveStart(years[i]),
        this.getSettingDateOfInvintradeHiveEnd(years[i])
      );

      const eventsLostDuringSeasonY0Y1 = this.eventsOfTypeForPeriod(
        8/*förloratsamh*/,
        this.getSettingDateOfLostHiveStart(years[i]),
        this.getSettingDateOfLostHiveEnd(years[i])
      );

      const tot = eventsInvintradeDuringSeasonY0.length - eventsLostDuringSeasonY0Y1.length;
      const shareLost = tot / eventsInvintradeDuringSeasonY0.length;
      //console.log('>>=', eventsInvintradeDuringSeasonY0, eventsLostDuringSeasonY0Y1, tot, shareLost);
      allData.push([Math.round((1 - shareLost) * 100) ]);
    }

    const options = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            // stacked: true,
          }
        ],
        yAxes: [{
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
          }
        }]
      }
    };

    var allDataSets = [];
    allDataSets.push({
      label: [''],
      data: allData,
      backgroundColor: GenericDiagramUtil.grayColor,
      borderColor: GenericDiagramUtil.grayColorLine,
      borderWidth: 1,
    });

    const data = {
      labels: allLabels,
      datasets: allDataSets,
    };

    return(
      <div key={divId+Date()}>
        <GenericBarDiagram
          divId={divId}
          diagramType={'bar'}
          chartData={data}
          allSectionLabels={['']}
          showNoDataAvailable={allData.length === 0}
          chartOptions={options} />
      </div>
    );
  }

  renderBarDiagram_invintradeVsForlorade(divId) {
    var allLabels = [];
    var allData = [];

    // var allDataInvintrade = [];
    // var allDataLost = [];

    const years = this.getYearsOfInterestForHiveDeath();
    for (var i=0; i<(years.length - 1); i++) {
      allLabels.push(years[i] + '/' + years[i+1]);

      const eventsInvintradeDuringSeasonY0 = this.eventsOfTypeForPeriod(
        4/*invintring*/,
        this.getSettingDateOfInvintradeHiveStart(years[i]),
        this.getSettingDateOfInvintradeHiveEnd(years[i])
      );

      const eventsLostDuringSeasonY0Y1 = this.eventsOfTypeForPeriod(
        8/*förloratsamh*/,
        this.getSettingDateOfLostHiveStart(years[i]),
        this.getSettingDateOfLostHiveEnd(years[i])
      );

      // allDataInvintrade.push(eventsInvintradeDuringSeasonY0.length);
      // allDataLost.push(eventsLostDuringSeasonY0Y1.length);
      allData.push([
        eventsInvintradeDuringSeasonY0.length,
        eventsLostDuringSeasonY0Y1.length
      ]);
    }
    //
    // for (var ii=0; ii<allDataInvintrade.length; ii++) {
    //   allData.push([allDataInvintrade[ii], allDataLost[ii]]);
    // }

    const options = {
      legend: {
        labels: {
        }
      },
      scales: {
        xAxes: [
          {
            // stacked: true,
          }
        ],
        yAxes: [{
          // stacked: true,
          ticks: {
            // beginAtZero: true,
            suggestedMin: 0,
            precision: 0,
            // suggestedMax: 100,
          }
        }]
      }
    };

    // return this.renderBarDiagramGeneric(
    //   divId,
    //   allLabels,
    //   allData,
    //   ["", ""],//["Invintrade", "Förlorade"],
    //   options,
    // );
    var allDataSets = [];
    for (var j=0; j<allLabels.length; j++) {
      allDataSets.push({
        label: allLabels[j],
        data: allData[j],
        backgroundColor: GenericDiagramUtil.positiveYearColors(j),
        borderColor: GenericDiagramUtil.positiveYearColorsLine(j),
        borderWidth: 1,
      });
    }

    const data = {
      labels: ['Invintrade', 'Förlorade'],
      datasets: allDataSets,
    };

    return(
      <div key={divId+Date()}>
        <GenericBarDiagram
          divId={divId}
          diagramType={'bar'}
          chartData={data}
          allSectionLabels={['']}
          showNoDataAvailable={data.datasets.length === 0}
          chartOptions={options} />
      </div>
    );
  }


  // ---------------------------------------------------------------------------
  // Date/settings util
  // ---------------------------------------------------------------------------

  // hämta alla events som är av denna typen
  // inkludera bara för det medskickade tidsintervallet
  // Ska bara egna samh visas, eller även shared????????????????????????????????
  eventsOfTypeForPeriod(eventTypeId, startDate, endDate) {
    const filterOnDatePeriod = function(eventRecord) {
      const d = new Date(eventRecord.fields.date.value);
      const isAfterStartDate = d >= startDate;
      const isBeforeEndDate = d < endDate;
      return isAfterStartDate && isBeforeEndDate;
    }

    var events = null;

    if (eventTypeId === 4) {
      const {allEventsOfType4} = this.state;
      events = allEventsOfType4;
    }
    else if (eventTypeId === 8) {
      const {allEventsOfType8} = this.state;
      events = allEventsOfType8;
    }
    else {
      console.log('ERROR: unknown eventTypeId', eventTypeId);
      return null;
    }

    return events.filter(event => filterOnDatePeriod(event));
  }


  // Första sekunden på året
  getSettingDateOfInvintradeHiveStart(year) {
    const d = new Date(year, 0, 1); // Årets start
    return d;
  }


  // Sista sekunden på året
  getSettingDateOfInvintradeHiveEnd(year) {
    const d = new Date(year, 11, 31); // Årets slut
    return d;
  }


  // When calculating hive death, include events starting this point of the year.
  // Use a setting later on!
  // TODO: brytpunkter ska  kunna justeras i inställnignarna
  getSettingDateOfLostHiveStart(year) {
    var d = new Date(year, 5, 1); // 1 juni (mån idx 5) aktuellt år
    return d;
  }


  // When calculating hive death, include hive death events ending this point of the year.
  // Use a setting later on!
  getSettingDateOfLostHiveEnd(year) {
    var d = new Date(year + 1, 4, 31); // 1 maj (mån idx 4) efterkommande år
    return d;
  }


  render() {
    const {allEventsOfType4, allEventsOfType8} = this.state;
    if (allEventsOfType4 && allEventsOfType8) {
      return (
        <div>
          <div className="buttonRowLCR-80">
            <div className="left-text">
              {GenericDiagramUtil.renderSubTitle('Invintrade respektive förlorade samhällen')}
              {this.renderBarDiagram_invintradeVsForlorade('myChart')}
              <canvas id="myChart" width="auto" height="auto"></canvas>
            </div>

            <div/>

            <div className="right-text">
              {GenericDiagramUtil.renderSubTitle('Andel förluster (%)')}
              {this.renderBarDiagram_percentDeath('myChart_r')}
              <canvas id="myChart_r" width="auto" height="auto"></canvas>
            </div>
          </div>
        </div>
      );
    }
    else {
      return (<LoadingIndicator label={'Events och type 4 and 8...'}/>);
    }
  }
}

export default HiveDeathDiagramArea;
