import React, { Component } from 'react';
import './../css/HiveImageItem.css';

class HiveImageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: this.props.url,
    };
  }

  render() {
    return (
      <div className="HiveImageItem">
        <img src={this.state.url} className="HiveImageItem-image" alt={''} />
      </div>
    );
  }
}

export default HiveImageItem;
