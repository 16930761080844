import React, { Component } from 'react';
import './../css/GroupImageItem.css';

class GroupImageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: this.props.url,
    };
  }

  render() {
    return (
      <div className="GroupImageItem">
        <img src={this.state.url} className="GroupImageItem-image" alt={''} />
      </div>
    );
  }
}

export default GroupImageItem;
