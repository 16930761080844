import React, { Component } from 'react';
import './../css/MapOverviewTable.css';
import MapOverviewTableItem from './MapOverviewTableItem';
import UnderlinedCenterCaptionSeparator from './UnderlinedCenterCaptionSeparator';

class MapOverviewTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privateGroupRecords: this.props.privateGroupRecords,
      sharedGroupRecords: this.props.sharedGroupRecords,
      hives: this.props.hives,
    };
  }

  renderTable(groupRecords, hiveRecords) {
    var GroupDetails = [];

    // TOOD: setting om jag ska sortera på ID eller NAME
    const sorted = groupRecords.sort((a, b) => {
      return a.fields.name.value > b.fields.name.value;
    });

    for (var i = 0; i < sorted.length; i++) {
      const record = sorted[i];

      const f = function(r) {
        return r.fields.groupRef.value.recordName === record.recordName;
      }

      const hivesInGroup = hiveRecords.filter(f);
      GroupDetails.push(
        <div className="MapOverviewTable-item" key={'moti' + record.recordName + i + new Date()}>
          <MapOverviewTableItem
            groupRecord={record}
            hivesInGroup={hivesInGroup}
            key={record.recordName + i} />
        </div>);
    }

    return GroupDetails;
  }

  render() {
    const {privateGroupRecords, sharedGroupRecords, hives} = this.state;
    // NOTE:
    // - If there is shared and private groups, show two sections with caption on both
    // - If there is only privat, show no caption
    // - If there is only shared, show a caption
    if (privateGroupRecords.length > 0 && sharedGroupRecords.length > 0) {
      return (
        <div className="MapOverviewTable">
          <div className="MapOverviewTable-section">
            {this.renderTable(privateGroupRecords, hives.private)}
          </div>

          <UnderlinedCenterCaptionSeparator title={'Delade bigårdar'}/>
          <div className="MapOverviewTable-section">
            {this.renderTable(sharedGroupRecords, hives.shared)}
          </div>
        </div>
      );
    }

    else if (privateGroupRecords.length > 0) {
      return (
        <div className="MapOverviewTable">
          <div className="MapOverviewTable-section">
            {this.renderTable(privateGroupRecords, hives.private)}
          </div>
        </div>
      );
    }

    else if (sharedGroupRecords.length > 0) {
      return (
        <div className="MapOverviewTable">
          <div className="underlineRow caption-big">Delade bigårdar</div>
          <div className="MapOverviewTable-section">
            {this.renderTable(sharedGroupRecords, hives.shared)}
          </div>
        </div>
      );
    }

    else {
      return (
        <div className="MapOverviewTable">
          <div className="p-normal center-text space-above-small">
            Det finns ännu inga bigårdar eller samhällen att visa.<br/>
            <br/>
            Välkommen tillbaka hit när du registrerat data i appen!
          </div>
        </div>
      );
    }

  }
}

export default MapOverviewTable;
