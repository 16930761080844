import React, { Component } from 'react';
// import './../css/HarvestDiagramArea.css';
import * as Constants from './../Constants';
import SegmentControllerItem from './../SegmentControllerItem';
import LoadingIndicator from './../LoadingIndicator';
import * as GenericDiagramUtil from './GenericDiagramUtil';
import GenericBarDiagram from './GenericBarDiagram';

class HarvestDiagramArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedYear: null, // The year to show data for
      selectedHarvestType: 0/*honung*/, // Which of the harvets types to show
      groups: this.props.groups, // {private, shared}
      allHives: this.props.allHives, // {private, shared}

      // Loaded at component init
      harvestSessions: null, // Array of harvest sessions, one aray for each type of harvest

      harvestSessionsForSelectedYear: null, // Array of harvest sessions, one aray for each type of harvest

      // Calculated after harvestSessions is set -- filtered by year!
      normalizedSessionData: null, // [{hiveId, frameTypeIdx, frameCount}]
    };
  }

  componentDidMount() {
    // Fetch all harvest sessions
    const ck = window.ckUtilInstance;
    ck.loadHarvestSessions(ck.privateDb())
      .then((sessions) => {
        var harvestSessions = [[], [], []];
        for (var i = 0; i < sessions.length; i++) {
          const s = sessions[i];
          const t = s.fields.harvestType.value;
          harvestSessions[t].push(s);
        }

        // // When all harvest sessions is fetched, generate a normalized version
        this.setState({
          selectedYear: new Date().getFullYear(), // Default to this year -- later use setting?
          harvestSessions: harvestSessions, // array with three arrays - one for each type
        }, () => {
          this.generateNormalizedData();
        });
      });
  }

  getFinishedSessionsOfTheCurrentYear() {
    const {harvestSessions, selectedYear} = this.state;

    var result = [];

    for (var t=0; t<[0,1,2].length/*harvest types*/; t++) {
      const harvestSessionsOfCurrentType = harvestSessions[t];
      const harvestSessionsOfCurrentTypeAndFinished = harvestSessionsOfCurrentType.filter((session) => {
        const d = session.fields.finishedDate;
        if (d === undefined) {
          return false;
        }
        const  y = new Date(d.value).getFullYear();
        return y === selectedYear;
      });

      //console.log('<<YEAR', harvestSessionsOfCurrentTypeAndFinished);
      result.push(harvestSessionsOfCurrentTypeAndFinished);
    }

    //console.log('getFinishedSessionsOfTheCurrentYear', selectedYear, result);

    return result;
  }


  // ---------------------------------------------------------------------------
  // Normalization of session data
  // ---------------------------------------------------------------------------

  normalizedSessionData(sessions) {
    // console.log('> normalizedSessionData', sessions);
    var normalizedData = [];

    for (var s=0; s<sessions.length; s++) {
      const session = sessions[s];
      const hiveIdsInSession = this.hiveIdsInSession(session);
      //console.log('hiveIdsInSession', hiveIdsInSession);
      for (var h=0; h<hiveIdsInSession.length; h++) {
        const x = this.frameInfoForHiveInSession(session, hiveIdsInSession[h]);
        normalizedData = normalizedData.concat(x);
      }
    }

    //console.log('normalizedSessionData', normalizedData);
    return normalizedData;
  }

  generateNormalizedData() {
    // const {harvestSessions, selectedYear} = this.state;

    // Only return data for the currently selected year
    const harvestSessionsForSelectedYear = this.getFinishedSessionsOfTheCurrentYear();

    // When all harvest sessions is fetched, generate a normalized version
    const normalizedSessionData0 = this.normalizedSessionData(harvestSessionsForSelectedYear[0]);
    const normalizedSessionData1 = this.normalizedSessionData(harvestSessionsForSelectedYear[1]);
    const normalizedSessionData2 = this.normalizedSessionData(harvestSessionsForSelectedYear[2]);
    const result = [
      normalizedSessionData0,
      normalizedSessionData1,
      normalizedSessionData2,
    ];

    //console.log('generateNormalizedData', result, harvestSessionsForSelectedYear);
    this.setState({
      normalizedSessionData: result,
      harvestSessionsForSelectedYear: harvestSessionsForSelectedYear,
    });
  }

  // Antal ramar och typ för en viss hive i en session
  // [{sessionid, groupId, hiveId, frameTypeIdx, numFrames}]
  frameInfoForHiveInSession(session, hiveId) {
    var groupId = ""; // What group does this hive belong to? Empty = a deleted hive
    const hive = this.hiveWithId(hiveId);
    if (hive) {
      groupId = hive.fields.groupRef.value.recordName;
    }

    const hiveIdsInSessionStr = session.fields.framesInSession.value.split(';');
    const frameInfo = hiveIdsInSessionStr.filter((hiveStr) => {
      const x = hiveStr.split(':');
      return x[0] === hiveId;
    }).filter((pair) => {
      return pair !== '';
    }).map((hiveFramePair) => {
      const p = hiveFramePair.split(':');
      const x = p[1].split('#');
      return {
        sessionId: session.recordName,
        groupId: groupId,
        hiveId: hiveId,
        frameTypeIdx: x[0],
        numFrames: x[1]
      };
    });

    // console.log('< frameInfoForHiveInSession', frameInfo);
    return frameInfo.filter((row) => {
      return row !== undefined;
    });
  }


  // ---------------------------------------------------------------------------
  // Utility
  // ---------------------------------------------------------------------------

  hiveWithId(hiveId) {
    const {allHives} = this.state;
    var h = null;

    function forHives(hives) {
      // console.log('searching for hive in', hives);
      for (var i = 0; i < hives.length; i++) {
        const hCandidate = hives[i].recordName;
        // console.log(' ->', hCandidate);
        if (hCandidate === hiveId) {
          return hives[i];
        }
      }
      return null;
    }

    h = forHives(allHives.private);
    if (h === null) {
      h = forHives(allHives.shared);
    }

    return h;
  }

  groupWithGroupId(groupId) {
    const {groups} = this.state;
    const dBs = [groups.private, groups.shared];
    for (var i=0; i<dBs.length; i++) {
      const db = dBs[i];
      for (var j=0; j<db.length; j++) {
        const group = db[j];
        if (group.recordName === groupId) {
          return group;
        }
      }
    }
    return null;
  }

  groupForHive(hive) {
    return this.groupWithGroupId(hive.fields.groupRef.value.recordName);
  }

  groupIdsInSession(session) {
    const hiveIds = this.hiveIdsInSession(session);
    const groupIds = hiveIds.map((hiveId) => {
      const hive = this.hiveWithId(hiveId);
      if (hive) {
        return hive.fields.groupRef.value.recordName;
      }

      return false;
    });

    return groupIds;
  }

  hiveIdsInSession(session) {
    const hiveIdsInSessionStr = session.fields.framesInSession.value.split(';');
    const hiveIds = hiveIdsInSessionStr.map((hiveStr) => {
      return hiveStr.split(':');
    }).map((hiveFramePair) => {
      return hiveFramePair[0];
    });
    return hiveIds;
  }

  groupsInSessions(sessions) {
    var groups = new Set();
    for (var i = 0; i < sessions.length; i++) {
      const groupIds = this.groupIdsInSession(sessions[i]);
      for (var g = 0; g < groupIds.length; g++) {
        const group = this.groupWithGroupId(groupIds[g]);
        if (group) {
          groups.add(group);
        }
      }
    }
    return Array.from(groups);
  }


  // ---------------------------------------------------------------------------
  // Amount calculations
  // ---------------------------------------------------------------------------

  optimalAmountPerFrameWithFrameTypeIdx(frameTypeIdx) {
    // console.log("> optimalAmountPerFrameWithFrameTypeIdx", frameTypeIdx);

    var result = -1;

    const svea =                 0; // S
    const halvSvea =             1; // HS
    const lagnormal =            2; // LN
    const lagnormalSvea =        3; // LS
    const halvLagnormalSvea =    4; // Halv LS
    const norsk =                5;
    const halvNorsk =            6;
    const wieslander =           7;
    const hoffmannLagnormal =    8;
    const langstroth =           9;
    const farrar =               10; // 3/4 Langstroth
    const shallow =              11;
    const dadant =               12;
    const flowHive =             13; // Langstroth
    const flowHiveLN =           133; // LN
    const topBarHive =           14; // Topplistkupa
    const halmKupa =             15; // Halmkupa

    switch (parseInt(frameTypeIdx)) {
      case svea:
          result = 2.61;
          break;
      case halvSvea:
          result = 1.18;
          break;
      case lagnormal:
          result = 2.33;
          break;
      case lagnormalSvea:
          result = 3.23;
          break;
      case halvLagnormalSvea:
          result = 1.45;
          break;
      case norsk:
          result = 2.76;
          break;
      case halvNorsk:
          result = 1.61;
          break;
      case wieslander:
          result = 2.98;
          break;
      case hoffmannLagnormal:
          result = 2.27; // 97% of .lagnormal
          break;
      case langstroth:
          result = 3.03;
          break;
      case farrar:
          result = 1.70;
          break;
      case shallow:
          result = 1.63;
          break;
      case dadant:
        result = 3.65;
        break;
      case flowHive:
        result = 3.03; // langstroth
        break;
      case flowHiveLN:
        result = 2.33; // LN
        break;
      case topBarHive:
        result = 1; // vid skörd anges antal kg (uppskattat)
        break;
      case halmKupa:
        result = 1; // vid skörd anges antal kg (uppskattat)
        break;
      default:
        console.log("!!! unknown frame type idx", frameTypeIdx);
        break;
    }

    return result;
  }

  renderDiagram_harvestPerGroup(sessions, divId) {
    const {normalizedSessionData, selectedHarvestType} = this.state;
    const n = normalizedSessionData[selectedHarvestType];
    var datasets = [];
    //console.log('nnn', n);
    const allGroupIds = n.map((row) => {
      return row.groupId;
    });

    var allUniqueGroupIds = new Set();
    for (var u=0; u<allGroupIds.length; u++) {
      allUniqueGroupIds.add(allGroupIds[u]);
    }

    //console.log('allG', allGroupIds, allUniqueGroupIds);

    const uniqueGroupIds = Array.from(allUniqueGroupIds);

    var harvestPerGroup = uniqueGroupIds.map((groupId) => {
      const rowsForGroupId = n.filter((row) => {
        return row.groupId === groupId;
      });

      const amountPerRow = rowsForGroupId.map((row) => {
        const optimalAmountForFrameType = this.optimalAmountPerFrameWithFrameTypeIdx(row.frameTypeIdx);
        return optimalAmountForFrameType * row.numFrames;
      });

      const red = (acc, currentValue) => {
        return acc + currentValue;
      };

      const totalOptimalHarvestForGroup = amountPerRow.reduce(red, 0);
      return totalOptimalHarvestForGroup;
    });

    // Calculate ratio:
    const reducerSessionAmount = (acc, currentValue) => {
      return acc + currentValue;
    };
    const faktisk = sessions.map((session) => {
      return session.fields.amountValue.value;
    }).reduce(reducerSessionAmount, 0);
    // sessions.map((session) => {
    //   faktisk += session.fields.amountValue.value;
    // });
    const optimal = harvestPerGroup.reduce((acc, curr) => acc + curr, 0); // optimal) ∑(skörd per bigård)
    const ratio = faktisk / optimal; // ratio = faktisk / optimal

    for (var i = 0; i < uniqueGroupIds.length; i++) {
      const groupId = uniqueGroupIds[i];
      const group = this.groupWithGroupId(groupId);

      if (group) {

        var label = '';
        const sn = group.fields.shortName;
        if (sn !== undefined) {
          if (sn.value !== '') {
            label += '[' + sn.value + '] ';
          }
        }
        label += group.fields.name.value;

        datasets.push({
          label: label,
          data: [(harvestPerGroup[i] * ratio).toFixed(2)],
          backgroundColor: GenericDiagramUtil.yearColors(i),
          borderColor: GenericDiagramUtil.yearColorsLine(i),
          borderWidth: 1,
        });
      }
      else {
        console.log('Group not found for groupId', groupId);
      }
    }

    //console.log('### harvestPerGroup', harvestPerGroup);

    const data = {
      labels: [''],//['Skörd per bigård'],
      datasets: datasets,
    };

    const options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          }
        }]
      }
    };

    // console.log('renderDiagram_harvestPerGroup', data, options);

    return (
      <div key={divId+Date()}>
        <GenericBarDiagram
          divId={divId}
          diagramType={'bar'}
          chartData={data}
          allSectionLabels={['']}
          showNoDataAvailable={data.datasets.length === 0}
          chartOptions={options} />
      </div>
    );
  }


  // ---------------------------------------------------------------------------
  // Harvest per batch
  // ---------------------------------------------------------------------------

  renderDiagram_harvestPerBatch(sessions, divId) {
    var datasets = [];

    for (var i = 0; i < sessions.length; i++) {
      const session = sessions[i];
      datasets.push({
        label: session.fields.batchId.value,
        data: [session.fields.amountValue.value],
        backgroundColor: GenericDiagramUtil.yearColors(i),
        borderColor: GenericDiagramUtil.yearColorsLine(i),
        borderWidth: 1,
      });
    }

    const data = {
      labels: [''],//['Skörd per skördeomgång'],
      datasets: datasets,
    };

    const options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          }
        }]
      }
    };

    //console.log('renderDiagram_harvestPerBatch', data, options);

    return (
      <div key={divId+Date()}>
        <GenericBarDiagram
          divId={divId}
          diagramType={'bar'}
          chartData={data}
          allSectionLabels={['']} // Skörd per skördeomgång -- används inte....
          showNoDataAvailable={data.datasets.length === 0}
          chartOptions={options} />
      </div>
    );
  }


  // ---------------------------------------------------------------------------
  // Harvest per hive
  // ---------------------------------------------------------------------------

  renderDiagram_harvestPerHive(sessions, divId) {
    var allLabels = [];
    var allData = [];

    const numHives = 3; // dummy!!!

    for (var i=0; i<numHives; i++) {
      allLabels.push('Hive ' + i);

      //console.log('>>=', eventsInvintradeDuringSeasonY0, eventsLostDuringSeasonY0Y1, tot, shareLost);
      allData.push([Math.round((1 - Math.random()) * 100) ]);
    }

    const data = {
      labels: allLabels,
      datasets: [{
        data: allData,
        backgroundColor: GenericDiagramUtil.yearColors(i),
        borderColor: GenericDiagramUtil.yearColorsLine(i),
        borderWidth: 1,
      }],
    };

    const options = {
      legend: {
        labels: {
        }
      },
      scales: {
        xAxes: [
          {
            // stacked: true,
          }
        ],
        yAxes: [{
          // stacked: true,
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
          }
        }]
      }
    };

    return(
      <div key={divId+Date()}>
        <GenericBarDiagram
          divId={divId}
          diagramType={'bar'}
          chartData={data}
          allSectionLabels={['']}
          showNoDataAvailable={numHives === 0}
          chartOptions={options} />
      </div>
    );
  }

  setSelectedHarvestType(idx) {
    //console.log('setselectedHarvestType', idx);
    this.setState({selectedHarvestType: idx});
  }

  renderSegment() {
    const {selectedHarvestType} = this.state;

    const setSelectedHarvestType0 = () => {
      this.setSelectedHarvestType(0);
    }

    const setSelectedHarvestType1 = () => {
      this.setSelectedHarvestType(1);
    }

    const setSelectedHarvestType2 = () => {
      this.setSelectedHarvestType(2);
    }

    return(
      <SegmentControllerItem
        titles={Constants.harvestTypeNames}
        callbacks={[setSelectedHarvestType0, setSelectedHarvestType1, setSelectedHarvestType2]}
        selectedIndex={selectedHarvestType}
        key={'sci' + new Date()} />
    );
  }

  renderYearSegment() {
    const {selectedYear} = this.state;

    const harvestAreaChangeToPrevYear = () => {
      const {selectedYear} = this.state;
      this.setState({
        selectedYear: selectedYear - 1,
        normalizedSessionData: null,
        harvestSessionsForSelectedYear: null,
      }, () => {
        // const {selectedYear} = this.state;
        //console.log('harvestAreaChangeToPrevYear', selectedYear);
        window.dispatchEvent(new Event(Constants.event__statisticsSelectedYearChanged));
        this.generateNormalizedData();
      });
    }

    const harvestAreaChangeToNextYear = () => {
      const {selectedYear} = this.state;
      this.setState({
        selectedYear: selectedYear + 1,
        normalizedSessionData: null,
        harvestSessionsForSelectedYear: null,
      }, () => {
        // const {selectedYear} = this.state;
        //console.log('harvestAreaChangeToNextYear', selectedYear);
        window.dispatchEvent(new Event(Constants.event__statisticsSelectedYearChanged));
        this.generateNormalizedData();
      });
    }

    return(
      <SegmentControllerItem
        titles={['<', selectedYear, '>']}
        callbacks={[harvestAreaChangeToPrevYear, null, harvestAreaChangeToNextYear]}
        selectedIndex={null}
        key={'ys' + new Date()} />
    );
  }

  render() {
    const {harvestSessionsForSelectedYear, normalizedSessionData, selectedHarvestType, selectedYear} = this.state;

    const divId_type = 'harvestDiagram_' + selectedYear + '_';
    const divId0 = divId_type + 'harvestPerGroup';
    const divId1 = divId_type + 'harvestPerSession';
    const divId2 = divId_type + 'harvestPerHive';

    const unit = ['kg', 'gr', 'gr'][selectedHarvestType];

    const dia0 = (sessions) => {
      return (
        <div className="" key={divId0}>
          {GenericDiagramUtil.renderSubTitle('Skörd per bigård (' + unit + ')')}
          {this.renderDiagram_harvestPerGroup(sessions, divId0)}
          <canvas id={divId0} width="auto" height="auto"></canvas>
        </div>
      );
    };

    const dia1 = (sessions) => {
      return (
        <div className="" key={divId1}>
          {GenericDiagramUtil.renderSubTitle('Skörd per skördomgång (' + unit + ')')}
          {this.renderDiagram_harvestPerBatch(sessions, divId1)}
          <canvas id={divId1} width="auto" height="auto"></canvas>
        </div>
      );
    };

    const dia2 = (sessions) => {
      console.log('--------------------------INTE KORREKT ÄNNU -- FIXA!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
return null;
      return (
        <div className="center-text" key={divId2}>
          {GenericDiagramUtil.renderSubTitle('Skörd per samhälle (' + unit + ')')}
          {this.renderDiagram_harvestPerHive(sessions, divId2)}
          <canvas id={divId2} width="100%" height="auto"></canvas>
        </div>
      );
    };

    if (harvestSessionsForSelectedYear) {
      // const sessions = this.getFinishedSessionsOfTheCurrentYear()[selectedHarvestType];
      // console.log('SESSIONS/SESSIONS FOR SELECTED YEAR', harvestSessions, sessions);
      const sessionsForTheSelectedHarvestType = harvestSessionsForSelectedYear[selectedHarvestType];

      if (normalizedSessionData && harvestSessionsForSelectedYear) {
        //console.log('Normalized data for harvest type', selectedHarvestType, normalizedSessionData[selectedHarvestType], sessionsForTheSelectedHarvestType);

        return (
          <div>
            <div>

              <div className="buttonRowLCR-fixed space-under-medium">
                <div className="align-left">
                  {this.renderYearSegment()}
                </div>
                {this.renderSegment()}
                <div/>
              </div>
            </div>

            <div className="" key={divId_type}>
              <div className="buttonRowLCR-80 space-under-big">
                {dia0(sessionsForTheSelectedHarvestType)}
                <div/>
                {dia1(sessionsForTheSelectedHarvestType)}
              </div>

              {dia2(sessionsForTheSelectedHarvestType)}
            </div>

          </div>
        );
      }
      else {
        return (<LoadingIndicator label={'normalized data...'} />);
      }
    }
    else {
      return (<LoadingIndicator label={'Harvest session...'} />);
    }
  }
}

export default HarvestDiagramArea;
