import React, { Component } from 'react';
import './../css/GroupDetail.css';
import GroupImageItem from './GroupImageItem';
import HivesInGroupDetail from './HivesInGroupDetail';
import UnderlinedIdAndCaptionSeparator from './UnderlinedIdAndCaptionSeparator';
import LoadingIndicator from './LoadingIndicator';
import EventsArea from './EventsArea';
import GroupHiveArrangementArea from './GroupHiveArrangementArea';
import placeholder from '../img/placeholder.png';

class GroupDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupRecord: this.props.groupRecord,
      hivesInGroup: this.props.hivesInGroup,
      link: this.props.link,
      contacts: null,
      groupEventRecords: null,
    };
  }

  componentDidMount() {
    const {groupRecord} = this.state;
    const ck = window.ckUtilInstance;
    const db = ck.recordIsPrivate(groupRecord) ? ck.privateDb() : ck.sharedDb();
    ck.loadContacts(db).then((contacts) => {
      // console.log('contacts', contacts);
      this.setState({contacts: contacts}, () => {
        ck.loadEventsInGroup(db, groupRecord).then((events) => {
          this.setState({groupEventRecords: events});
        });
      });
    });
  }

  renderGroupEvents() {
    const {groupRecord, groupEventRecords} = this.state;

    // console.log('groupEventRecords', groupEventRecords);

    const existsGroupEventsForGroup = groupEventRecords.length > 0;

    if (existsGroupEventsForGroup) {
      return (
        <div className="space-under-medium print-break-inside-never">
          <div className="group-event-top">
            <div className="left-text caption-medium">Bigårdhändelser</div>
            <div className="right-text">
              <div className="tooltip">[ℹ︎]
                <span className="tooltiptext p-small">
                  Här visas alla bigårdhändelser, dvs händelser som registrerats på bigården. Dessa händelser visas även i historiken för de samhällen som ingick i bigården vid tillfället för händelsen.
                </span>
              </div>
            </div>
          </div>

          <div className="group-event-bottom">
            <EventsArea groupRecord={groupRecord} expanded={false} asTimeLine={false} />
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  renderGroupHiveArrangement() {
    const {groupRecord, /*groupEventRecords,*/ hivesInGroup} = this.state;

    // console.log('renderGroupHiveArrangement: groupEventRecords', groupEventRecords);

    if (hivesInGroup.length === 0) {
      return null;
    }
    else {
      return (
        <div className="space-under-medium print-break-inside-never">
          <div className="group-event-top">
            <div className="left-text caption-medium">Plats för alla samhällen i bigården</div>
            <div className="right-text">
              <div className="tooltip">[ℹ︎]
                <span className="tooltiptext p-small">
                  Här visas den fysiska platsen för alla samhällen i bigården.
                </span>
              </div>
            </div>
          </div>

          <div className="group-event-bottom">
            <GroupHiveArrangementArea
              groupRecord={groupRecord}
              hivesInGroupRecords={hivesInGroup}
              expanded={false} />
          </div>
        </div>
      );
    }
  }

  renderHivesInGroup() {
    const {groupRecord, hivesInGroup} = this.state;
    return (
      <div className="print-break-inside-never">
        <HivesInGroupDetail
          showNavBar={false}
          showHiveLinkButton={true}
          filterOnGroupRecord={groupRecord}
          groups={[groupRecord]}
          hivesInGroup={hivesInGroup}
          key={groupRecord.recordName} />
      </div>
    );
  }

  renderIsTemporaryArea(fields) {
    const isTemp = fields.isTemporaryLocation.value ? 'Tillfällig' : 'Permanent';
    return (
      <div className="GroupDetail-descriptionItem">
        <div className="caption-small">Uppställning:</div>
        <div>{isTemp}</div>
      </div>
    );
  }

  renderDescriptionArea(fields) {
    if (fields.description.value !== undefined && fields.description.value !== "") {
      return (
        <div className="GroupDetail-descriptionItem">
          {fields.description.value}
        </div>
      );
    }
    else {
      return null;
    }
  }

  coordinateValues(loc) {
    if (loc === undefined || (loc.latitude === 0 && loc.longitude === 0)) {
      return (
        <div>Ej angivet</div>
      );
    }
    else {
      const lat = loc.latitude.toFixed(15);
      const long = loc.longitude.toFixed(15);
      return (
        <div className="smallcaps">
          <div>
            {lat}°
          </div>
          <div>
            {long}°
          </div>
        </div>
      );
    }
  }

  renderCoordinatesArea(fields) {
    if (fields.location) {
      const loc = fields.location.value;
      return (
        <div className="GroupDetail-descriptionItem">
            <div className="caption-small space-under-tiny">Koordinater:</div>
            <div>
              <div className="noWrap">{this.coordinateValues(loc)}</div>
              <div className="p-tiny smallcaps-italic ">(SWEREF95)</div>
            </div>
          </div>
      );
    }
    else {
      return null;
    }
  }

  renderLandownerArea(forGroupRecordNameId) {
    // console.log('renderLandownerArea -------------! fetch contact!', fields);
    const {contacts} = this.state;
    for (var i=0; i<contacts.length; i++) {
      const c = contacts[i];
      const f = c.fields;
      if (f.groupRef) {
        if (f.groupRef.value.recordName === forGroupRecordNameId) {
          const name = f.firstName.value + ' ' + f.lastName.value;
          const email = f.email.value;
          const phone = f.phone.value;
          const addressLine1 = f.address1.value;
          const addressLine2 = f.address2.value;
          const c = f.zipNumber.value + ' ' + f.city.value;

          // Only print if there is any content (there is always two space)
          const all = name + email + phone + addressLine1 + addressLine2 + c;
          if (all.length > 2) {
            return (
              <div className="GroupDetail-descriptionItem">
                <div className="caption-small space-under-tiny">Markägare:</div>
                <div className="p-small space-under-tiny">{name}</div>
                <div className="p-small">{addressLine1}</div>
                <div className="p-small">{addressLine2}</div>
                <div className="p-small">{c}</div>
                <div className="space-above-tiny">{email}</div>
                <div className="space-above-tiny">{phone}</div>
              </div>
            );
          }
        }
      }
    }

    return null;
  }

  renderSharedStatusArea() {
    const isPublished = false;
    const isSubscribed = false;
    if (isSubscribed || isPublished) {
      return (
        <div className="GroupDetail-sharingStatusItem">
          <div className="caption-small">Delning:</div>
          <div>Ägs av: Du har r/o-rättigheter</div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  thumbURL(record) {
    const filename = record.fields.thumbnail;
    
    if (filename !== undefined) { // } && filename.value.length > 0) {
      const s = filename.value.downloadURL;
      if (s !== undefined) { // } && s.value.length > 0) {
        return s;
      }
    }

    return placeholder;
  }

  render() {
    const {groupRecord, link, contacts, groupEventRecords} = this.state;
    const fields = groupRecord.fields;

    if (contacts) {
      if (groupEventRecords) {
        return (
          <div className="GroupDetail print-break-inside-never print-break-before">

            <UnderlinedIdAndCaptionSeparator record={groupRecord} link={link} />

            <div className="GroupDetail-layout">

              <div className="GroupDetail-groupthumbnailAndDetailsArea">
                <div className="GroupDetail-thumbnail">
                  <GroupImageItem url={this.thumbURL(groupRecord)} />
                </div>

                <div className="p-small">
                  {this.renderSharedStatusArea()}
                  {this.renderDescriptionArea(fields)}
                  {this.renderIsTemporaryArea(fields)}
                  {this.renderLandownerArea(groupRecord.recordName)}
                  {this.renderCoordinatesArea(fields)}
                </div>
              </div>

              <div className="GroupDetail-hivesArea">
                {this.renderGroupHiveArrangement()}
                {this.renderGroupEvents()}
                {this.renderHivesInGroup()}
              </div>

            </div>
          </div>
        );
      }
      else {
        return (<LoadingIndicator label={'group events...'} />);
      }
    }
    else {
      return (<LoadingIndicator label={'contacts...'} />);
    }
  }
}

export default GroupDetail;
