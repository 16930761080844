import React, { Component } from 'react';
import * as Constants from './Constants';
// import EventImageItem from './EventImageItem';
// import EventTimeLineVerticalLine from './EventTimeLineVerticalLine';

import eventIconIdx0 from './../img/eventIcons/icons8-inspection-100.png';
import eventIconIdx1 from './../img/eventIcons/icons8-task-100.png';
import eventIconIdx2 from './../img/eventIcons/Food-Honey-icon-100.png';
import eventIconIdx3 from './../img/eventIcons/icons8-survey-100.png';
import eventIconIdx4 from './../img/eventIcons/Astrology-Winter-icon-100.png';
import eventIconIdx5 from './../img/eventIcons/icons8-spoon-of-sugar-100.png';
import eventIconIdx6 from './../img/eventIcons/icons8-view-column-100.png';
import eventIconIdx7 from './../img/eventIcons/icons8-toilet-paper-100.png';
import eventIconIdx8 from './../img/eventIcons/icons8-cemetery-100.png';
import eventIconIdx9 from './../img/eventIcons/icons8-alcoholic-100.png';
import eventIconIdx10 from './../img/eventIcons/icons8-fairytale-100.png';
import eventIconIdx11 from './../img/eventIcons/icons8-division-100.png';
import eventIconIdx12 from './../img/eventIcons/icons8-heart-with-pulse-100.png';
import eventIconIdx13 from './../img/eventIcons/icons8-receive-cash-100.png';
import eventIconIdx14 from './../img/eventIcons/icons8-external_link-100.png';
import eventIconIdx15 from './../img/eventIcons/icons8-wasp-100.png';
import eventIconIdx100 from './../img/eventIcons/icons8-picture-100.png';
import eventIconIdx101 from './../img/eventIcons/icons8-video-100.png';

export function nameOfEvent(idx) {
  switch (idx) {
    case 0: return "Inspektion";
    case 1: return "Anteckning";
    case 2: return "Skörd";
    case 3: return "Inspektion tillsynsman";
    case 4: return "Invintring";
    case 5: return "Utfordring";
    case 6: return "Drönarram utskuren";
    case 7: return "Rensningsflykt";
    case 8: return "Förlorat samhälle";
    case 9: return "Svärmning";
    case 10: return "Drottningbyte";
    case 11: return "Avläggare";
    case 12: return "Hälsoåtgärd";
    case 13: return "Avyttrat samhälle";
    case 14: return "Rapport av uppställning"; // ReportLocationToLansstyrelsen
    case 15: return "Bistick";
    case 100: return "Bild";
    case 101: return "Film";
    default: return "<<name: UNDEFINED EVENT IDX (" + idx + ")>>";
  }
}

export function iconForEvent(idx) {
  switch (idx) {
    case 0: return eventIconIdx0;
    case 1: return eventIconIdx1;
    case 2: return eventIconIdx2;
    case 3: return eventIconIdx3;
    case 4: return eventIconIdx4;
    case 5: return eventIconIdx5;
    case 6: return eventIconIdx6;
    case 7: return eventIconIdx7;
    case 8: return eventIconIdx8;
    case 9: return eventIconIdx9;
    case 10: return eventIconIdx10;
    case 11: return eventIconIdx11;
    case 12: return eventIconIdx12;
    case 13: return eventIconIdx13;
    case 14: return eventIconIdx14;
    case 15: return eventIconIdx15;
    case 100: return eventIconIdx100;
    case 101: return eventIconIdx101;
    default: return "<<icon: UNKNOWN EVENT IDX (" + idx + ")>>";
  }
}

export function d5(fields) {
  const value = parseInt(fields.feedingTypeIdNumber.value, 10);
  const other = 0;
  const foderdeg = 1;
  const fodersaft = 2;
  const sockerlosning = 3;
  const honungsram = 4;

  var str = "";
  switch (value) {
  case other: str = "Annat"; break;
  case foderdeg: str = "Foderdeg"; break;
  case fodersaft: str = "Fodersaft"; break;
  case sockerlosning: str = "Sockerlösning"; break;
  case honungsram: str = "Honungsram"; break;
  default:
    break;
  }
  return str;
}

export function d6(fields) {
  const value = parseInt(fields.droneFrameCutOutTypeIdNumber.value, 10);
  const leftOf3 = 0;
  const midOf3 = 1;
  const rightOf3 = 2;
  const leftOf2 = 3;
  const rightOf2 = 4;

  var str = "";
  switch (value) {
  case leftOf2: str = "▮▯"; break;
  case rightOf2: str = "▯▮"; break;
  case leftOf3: str = "▮▯▯"; break;
  case midOf3: str = "▯▮▯"; break;
  case rightOf3: str = "▯▯▮"; break;
  default: str = "?"; break;
  }
  return str;
}

export const d8constants = [
  "Annan",
  "Slut på mat",
  "Skadegörelse",
  "Åverkan från djur",
  "Sjukdom",
  "Väder",
  "Stöld",
  "Röveri",
  "Förlust av drottning"
];
export function d8(fields) {
  const value = parseInt(fields.hiveDeathTypeIdNumber.value, 10);

  if (value < d8constants.length) {
    return d8constants[value];
  }
  else {
    return "?";
  }
}

export const d12constants = [
  "Annan",
  "Oxalsyra",
  "Myrsyra",
  "Apistan",
  "Apiguard",
  "Tymol",
  "BeeVital/HiveClean",
  "Bee Gym",
  "HopGuard",
  "Mjölksyra"
];
export function d12(fields) {
  const value = parseInt(fields.medicationTypeIdNumber.value, 10);

  if (value < d12constants.length) {
    return d12constants[value];
  }
  else {
    return "?";
  }
}

// function showImageFullScreen(url) {
//   console.log('klickade bild', url);
//   // document.getElementById(url).style.display = "block";
//   //visa bilden i full screen overlay, och med klick på den stängs overlayen
//
// }

export function d100(fields) {
  // console.log('100, asset', fields.asset.value);

// Med klickbarhet och kör funk ovan
  // return (
  //   <div>
  //       <a href={imgurl} target="_blank">
  //         <img
  //           className="imageShadowBox"
  //           src={imgurl}
  //           height='auto'
  //           width='100%'
  //           onClick={() => showImageFullScreen(imgurl)}/>
  //         </a>
  //   </div>
  // );

  if (fields.asset) {
    const imgurl = fields.asset.value.downloadURL;
    return (
      <div>
        <img
          className="imageShadowBox"
          src={imgurl}
          height='auto'
          width='100%'
          alt='' />
      </div>
    );
  }
  else {
    console.error('no imgurl', fields)
    return (
      <div>Bilden kunde inte visas
        <b><a href={Constants.link__supportEmailaHref()}> (Klicka här för att rapportera felet)</a></b>
      </div>
    );
  }
}

export function renderNotes(fields) {
  if (fields.notes.value) {
    return (<div>{fields.notes.value}</div>);
  }
  return null;
}

export function formattedDate(dateString) {
  const d = new Date(dateString);
  return d.toLocaleDateString();
}

export function formattedDateMonthDay(dateString) {
  const d = new Date(dateString);
  const monthName = ['jan','feb','mars','april','maj','juni','juli','aug','sep','okt','nov','dec'][d.getMonth()];
//  const monthName = ['Jan','Feb','Mars','April','Maj','Juni','Juli','Aug','Sep','Okt','Nov','Dec'][d.getMonth()];
  return d.getDate() + ' ' + monthName;
}

export function marker(value) {
  const yes = "✓";//"✔︎";//""☐";//'&#9745;'; // ☐	9744, ☑	9745, ☒	9746
  const no = "𐄂";//𐄂 ⤫"☑";//'&#9744;'
  return value ? yes : no;
}

export function numBroadFramesStr(n) {
  return n > -1 ? n : ""; //"?"
}

// export function eventClearTextName(typeId) {
//   switch (typeId) {
//     case 0: return "Inspektion";
//     case 1: return "Anteckning";
//     case 2: return "Skörd";
//     case 3: return "Inspektion tillsynsman";
//     case 4: return "Invintring";
//     case 5: return "Utfordring";
//     case 6: return "Drönarram utskuren";
//     case 7: return "Rensningsflykt";
//     case 8: return "Förlorat samhälle";
//     case 9: return "Svärmning";
//     case 10: return "Drottningbyte";
//     case 11: return "Avläggare";
//     case 12: return "Hälsoåtgärd";
//     case 13: return "Avyttrat samhälle";
//     case 14: return "Rapport av uppställning";
//     case 100: return "Bild";
//     case 101: return "Film";
//     default: break;
//   }
//   return "<<name: UNDEFINED EVENT IDX (" + typeId + ")>>";
// }

//const stepValues = ["▮▯▯▯▯", "▮▮▯▯▯", "▮▮▮▯▯", "▮▮▮▮▯", "▮▮▮▮▮"];

export function hiveStrengthStr(idx) {
  const stepValues = ["Mycket svagt", "Svagt", "Medel", "Starkt", "Mycket starkt"];
  if (idx < 0 && idx > (stepValues.count - 1)) {
    return "?";
  }
  return stepValues[idx];
}

export function temperamentStr(idx) {
  const stepValues = ["Mycket lugnt", "Lugnt", "Neutralt", "Upprört", "Mycket upprört"];
  if (idx < 0 && idx > (stepValues.count - 1)) {
    return "?";
  }
  return stepValues[idx];
}

export function isGroupEvent(record) {
    // console.log('record.fields.hivesInGroupWhenCreated ' + record.fields.hiveRef + ' | ' + record.fields.groupRef, record);
    if (record.fields.groupRef) {
      return true;
    }
    return false;
  }


class EventItemUtil extends Component {
  render() {
    return null;
  }
}

export default EventItemUtil;
