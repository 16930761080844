import * as Constants from './Constants';

var instance = null;
var userInfo = null;

class CKUtil {

  constructor() {
    console.log('CKUtil.constructor()');

    if (instance === null) {
      this.createInstance();
      instance = this;
      console.log("CKUtil.getInstance(): created", instance);

      // Trigger init CloudKit
      this.container().setUpAuth().then((ui) => {
        console.log('setUpAuth() --> ui', ui);
        this.onSetUpAuthDone(ui);
      });

    }
  }

  createInstance() {
    console.log('CKUtil.createInstance()');

    window.CloudKit.configure({
      locale: Constants.cloudKitLocale(),
      containers: [{
        containerIdentifier: 'iCloud.se.rdahl.Min-Bigard',
        apiTokenAuth: {
          apiToken: Constants.apiToken,
          persist: true, // Sets a cookie.
          signInButton: {
            id: 'apple-sign-in-button',
            theme: 'white-with-outline' // Other options: 'white', 'white-with-outline'.
          },
          signOutButton: {
            id: 'apple-sign-out-button',
            theme: 'white-with-outline'
          }
        },
        environment: Constants.environment,
      }]
    });

    return this;
  }

  // demoFetchAllSubscriptions() {
  //   console.log('*** demoFetchAllSubscriptions()');
  //
  //   // var container = this.getDefaultContainer();
  //   // var privateDB = container.privateCloudDatabase;
  //
  //   this.privateDb()
  //     .fetchAllSubscriptions()
  //     .then(function(response) {
  //     if (response.hasErrors) {
  //
  //       // Handle the error.
  //       throw response.errors[0];
  //
  //     } else {
  //       var title = 'Subscriptions:';
  //       return title;//renderSubscriptions(title,response.subscriptions);
  //     }
  //   });
  // }

  setUserInfo(ui) {
    userInfo = ui;
    console.log('CKUtil.setUserInfo()', userInfo);
  }

  onSetUpAuthDone(ui) {
    console.log('CKUtil.onSetUpAuthDone()', ui);
    if (ui) {
      this.setUserInfo(ui);

      this.setupSubscription(Constants.defaultZoneName, this.privateDb());
      // this.setupSubscription(Constants.defaultZoneName, this.sharedDb());

      // **** TEST ****
      // console.log('>>', this.demoFetchAllSubscriptions());

      // //1
      // var querySubscription = {
      //   subscriptionType: 'query',
      //   subscriptionID: userInfo.userRecordName,
      //   firesOn: ['create', 'update', 'delete'],
      //   query: { recordType: 'Group', sortBy: [{ fieldName: 'title'}] }
      // };
      //
      // console.log('- querySubscription: ', querySubscription);
      //
      // //2
      // const db = this.privateDb();
      // db.fetchSubscriptions([querySubscription.subscriptionID])
      //   .then(function(response) {
      //   if(response.hasErrors) {  // subscription doesn't exist, so save it
      //     db.saveSubscriptions(querySubscription).then(function(response) {
      //       if (response.hasErrors) {
      //         console.error('- fetchSubscriptions, error: ', response.errors[0]);
      //         throw response.errors[0];
      //       } else {
      //         console.log("successfully saved subscription")
      //       }
      //     });
      //   }
      // });

      //3
      this.registerForCloudKitNotifications();

      window.dispatchEvent(new Event(Constants.event__setupAuthDone));
    }

    this.container().whenUserSignsIn().then(ui => {
      this.setUserInfo(ui);
      console.log('CKUtil.whenUserSignsIn())');
      window.dispatchEvent(new Event(Constants.event__userSignedIn));
    })
  }

  isSignedIn() {
    const isSignedIn = (window.ckUtilInstance && userInfo !== null && userInfo.userRecordName);
    console.log('CKUtil.isSignedIn(): ' + isSignedIn + ': ' + userInfo, window.ckUtilInstance);
    // console.log('CKUtil.isSignedIn()', isSignedIn);
    return isSignedIn;
  }

  displayUserName(str) {
    console.log('CKUtil.displayUserName()', str);
    alert('User Name: ', str);
    //console.log('displayUserName: ', str);
  }

  gotoAuthenticatedState(userIdentity) {
    console.log('CKUtil.gotoAuthenticatedState()');
    var name = userIdentity.nameComponents;
    if (name) {
      const msg = name.givenName + ' ' + name.familyName;
      this.displayUserName(msg);
    } else {
      const msg = 'User record name: ' + userIdentity.userRecordName;
      this.displayUserName(msg);
    }

    this.container()
      .whenUserSignsOut()
      .then(this.gotoUnauthenticatedState);
  }

  gotoUnauthenticatedState(error) {
    console.log('CKUtil.gotoUnauthenticatedState()');
    if (error && error.ckErrorCode === 'AUTH_PERSIST_ERROR') {
      alert("PersistError");
    }

    this.displayUserName('Unauthenticated User');

    this.container()
      .whenUserSignsIn()
      .then(this.gotoAuthenticatedState)
      .catch(this.gotoUnauthenticatedState);
  }


  //
  //  Utility
  //

  cloudKitInstance() {
    // console.log("CKUtil.cloudKitInstance()", window.CloudKit);
    return window.CloudKit;
  }

  container() {
    const c = this.cloudKitInstance().getDefaultContainer();
    // console.log("container()", c);
    return c;
  }

  privateDb() {
    // console.log("");
    return this.container().privateCloudDatabase;
  }

  sharedDb() {
    // console.log("");
    return this.container().sharedCloudDatabase;
  }

  defaultZoneName() {
    // console.log("");
    return Constants.defaultZoneName;
  }

  recordIsPrivate(record) {
    return this.defaultOwnerName() === record.zoneID.ownerRecordName;
  }

  recordIsShared(record) {
    return !this.recordIsPrivate(record);
  }

  defaultOwnerName() {
    var name = null;
    const ui = this.getUserInfo();
    if (ui) {
      if (ui.userRecordName) {
        name = userInfo.userRecordName;
      }
      // console.log("defaultOwnerName()", name);
    }
    else {
      console.log('defaultOwnerName() user record name could not be looked up');
    }

    return name;
  }

  getUserInfo() {
    //console.log('getUserInfo()', userInfo);
    return userInfo;
  }

  renderNotification(notification) {
    console.log("notification", notification);

    function closeToast() {
      console.log("");
      var x = document.getElementById("snackbar");
      x.className = x.className.replace("show", "");
    }

    console.log('shwotoast');
    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(() => {
      // x.className = x.className.replace("show", "");
      closeToast();
    }, 10000);
  }

  registerForCloudKitNotifications() {
    console.log("");
    var container = this.container();

    // Check if our container is already registered for notifications. If so, return.
    if (container.isRegisteredForNotifications) {
      return window.ckUtilInstance.Promise.resolve();
    }
    else {

      // Now let's park a connection with the notification backend so that
      // we can receive notifications.
      return container.registerForNotifications()
        .then((container) => {
          if (container.isRegisteredForNotifications) {
            console.log('isRegisteredForNotifications');

            // Add a notification listener which appends the received notification object
            // to the table below.
            container.addNotificationListener(this.renderNotification);
          }
          else {
            console.log('ERROR: !isRegisteredForNotifications');
          }
          return;
        });
    }
  }

  setupSubscription(zoneName, db) {
    console.log("setupSubscription()", zoneName, db);
    var subscription = {
      subscriptionType: 'zone',
      // subscriptionID: 'privateZoneSubscriptionIdWeb',
      // zoneID: { zoneName: zoneName },
    };

    subscription.zoneID = { zoneName: zoneName };

    subscription.shouldSendContentAvailable = true;

    db.saveSubscriptions(subscription)
      .then(function(response) {
        if (response.hasErrors) {
          console.log('setupSubscription error:', response.errors[0]);
      } else {
        console.log('setupSubscription OK:', response.subscriptions[0]);
      }
    });
  }


  //  --------------------------------------------------------------------------
  //  Zone and record fetching
  //  --------------------------------------------------------------------------

  loadZones(db) {
    // console.log("");
    return db.fetchAllRecordZones()
      .then(function(response) {
        if (response.hasErrors) {
          console.log('loadZones ERROR', response);
        } else {
          // console.log('loadZones result (will be filtered)', response.zones);
          // response.zones is an array of zone objects.

          return response.zones.filter(function (zone) {
            if (zone) {
              if (zone.zoneID.zoneName === Constants.defaultZoneName) {
                //console.log('loadZones: found the default zone:', zone, Constants.defaultZoneName);
                return true;
              }
            }

            //console.log('loadZones: ignoring zone since not is not the default zone', zone, Constants.defaultZoneName);
            return false;
          });
        }
      });
  }

  loadPrivateZones() {
    return this.loadZones(this.privateDb());
  }

  loadSharedZones() {
    return this.loadZones(this.sharedDb());
  }


  //  --------------------------------------------------------------------------
  //  GENERIC QUERY FUNCTION
  //  --------------------------------------------------------------------------

  query(db, zoneID, ownerRecordName, recordType) {
    //console.log('CKUtil.query: ', zoneID, ownerRecordName, recordType);
    // const zoneID = this.defaultZoneName();

    if (ownerRecordName === null || ownerRecordName === undefined) {
      ownerRecordName = this.defaultOwnerName();
      // console.log('query: recordType=' + recordType + ', zoneId=' + zoneID + ', ownerRecordName(no zone owner name was provided, using default)=' + ownerRecordName);
    }
    else {
      // console.log('query: recordType=' + recordType + ', zoneId=' + zoneID + ', ownerRecordName=' + ownerRecordName);
    }

    var query = {
      recordType: recordType
    };

    var options = {
      zoneID: {
        zoneName: zoneID,
        ownerRecordName: ownerRecordName,
      },
    };

    return db.performQuery(query, options)
      .then((response) => {
        if (response.hasErrors) {
          console.log('query, error', recordType, response.errors[0]);
        } else {
          return response.records;
        }
      });
  }


  //  --------------------------------------------------------------------------
  //  GROUPS
  //  --------------------------------------------------------------------------

  // TODO: zoneID borde inte behövas eftersom man ska gå mot default
  loadGroupsInZone(db, zone) {
    console.log('loadGroupsInZone', zone);
    if (zone) {
      // console.log('loadGroupsInZone', zone.zoneName, ownerRecordName, db);
      return this.query(db, zone.zoneName, zone.ownerRecordName, 'Group');
    }
    else {
      console.log('loadGroupsInZone: no zone');
    }
  }


  //  --------------------------------------------------------------------------
  //  CONTACT
  //  --------------------------------------------------------------------------

  loadContacts(db) {
    // console.log('loadContacts');
    return this.query(db, this.defaultZoneName(), null, 'Contact');
  }

  //  --------------------------------------------------------------------------
  //  HARVEST SESSION
  //  --------------------------------------------------------------------------

  loadHarvestSessions(db) {
    console.log('loadHarvestSessions');
    return this.query(db, this.defaultZoneName(), null, 'HarvestSession');
  }


  //  --------------------------------------------------------------------------
  //  HIVES
  //  --------------------------------------------------------------------------

  loadHives(db) {
    console.log('loadHives');
    return this.query(db, this.defaultZoneName(), null, 'Hive');
  }

  // loadEvents(db) {
  //   console.log('loadEvents');
  //   return this.query(db, this.defaultZoneName(), null, 'Event');
  // }


  //  --------------------------------------------------------------------------
  //  SETTINGS
  //  --------------------------------------------------------------------------

  loadAllSettings(db) {
    var query = {
      recordType: 'Setting',
      // filterBy: [{
      //   comparator: 'LIST_CONTAINS_ANY',
      //   fieldName: 'groupRef',
      //   fieldValue: {value: reference, type: 'REFERENCE'},
      // }],
    };

    // var sortDescriptor = {
    //   fieldName: 'date',
    //   ascending: false,
    // };
    // query.sortBy = [sortDescriptor];

    var options = {
    };

    options.zoneID = this.defaultZoneName();// record.zoneID;

    return db.performQuery(query, options)
      .then((response) => {
        if (response.hasErrors) {
          console.log('error', response.errors[0]);
        } else {
          return response.records;
        }
      });
  }


  //  --------------------------------------------------------------------------
  //  EVENTS
  //  --------------------------------------------------------------------------

  loadAllEvents(db) {
    var query = {
      recordType: 'Event',
      // filterBy: [{
      //   comparator: 'LIST_CONTAINS_ANY',
      //   fieldName: 'groupRef',
      //   fieldValue: {value: reference, type: 'REFERENCE'},
      // }],
    };

    var sortDescriptor = {
      fieldName: 'date',
      ascending: false,
    };
    query.sortBy = [sortDescriptor];

    var options = {
    };

    options.zoneID = this.defaultZoneName();// record.zoneID;

    return db.performQuery(query, options)
      .then((response) => {
        if (response.hasErrors) {
          console.log('error', response.errors[0]);
        } else {
          return response.records;
        }
      });
  }

  loadEventsInGroup(db, record) {
    if (record) {
    }
    else {
      return Promise.resolve([]);
    }

    var query = {
      recordType: 'Event',
      filterBy: [{
        fieldName: 'groupRef',
        comparator: 'EQUALS',
        fieldValue: {value: {recordName: record.recordName}},
      }],
    };

    var sortDescriptor = {
      fieldName: 'date',
      ascending: false,
    };
    query.sortBy = [sortDescriptor];

    var options = {
    };

    options.zoneID = record.zoneID;

    return db.performQuery(query, options)
      .then((response) => {
        if (response.hasErrors) {
          console.log('error', response.errors[0]);
        } else {
          return response.records;
        }
      });
  }

  loadEventsInHive(db, hiveRecord) {
    if (hiveRecord) {
    }
    else {
      return Promise.resolve([]);
    }

    // console.log("");
    var query = {
      recordType: 'Event',
      filterBy: [{
        fieldName: 'hiveRef',
        comparator: 'EQUALS',
        fieldValue: {value: {recordName: hiveRecord.recordName}},
      }],
    };

    //TODO: bokstavsordning? eller kunna ange i gränssnittet om id eller bokstavs, eller tom temporär?
    //if (sortByField) {
      var sortDescriptor = {
        fieldName: 'date',
        ascending: false,
      };
      query.sortBy = [sortDescriptor];
    // }

    // Convert the filters to the appropriate format.
    // query.filterBy = filters.map(function(filter) {
    //   filter.fieldValue = { value: filter.fieldValue };
    //   return filter;
    // });

    var options = {
      // Restrict our returned fields to this array of keys.
      //desiredKeys: desiredKeys,
      // Fetch 5 results at a time.
      //resultsLimit: 5
    };

    options.zoneID = hiveRecord.zoneID;

    // If we have a continuation marker, use it to fetch the next 5 results.
    // var continuationMarker = getContinuationMarker();
    // if (continuationMarker) {
    //   options.continuationMarker = continuationMarker;
    // }

    return db.performQuery(query, options)
      .then((response) => {
        if (response.hasErrors) {
          console.log('error', response.errors[0]);
        } else {
          // Save the continuation marker so we can fetch more results.
          // saveContinuationMarker(response.continuationMarker);
          //console.log('loadEventsInHive ('+hiveRecord.fields.name.value+') got # records:', response.records);

          return response.records;
        }
      });
  }

  loadEventsWithEventType(db, eventTypeId) {
    // console.log("");

    var query = {
      recordType: 'Event',
      filterBy: [{
        fieldName: 'typeId',
        comparator: 'EQUALS',
        fieldValue: {
          value: eventTypeId,
        },
      }],
    };

    //if (sortByField) {
      // var sortDescriptor = {
      //   fieldName: 'date',
      //   ascending: false,
      // };
      // query.sortBy = [sortDescriptor];
    // }

    // Convert the filters to the appropriate format.
    // query.filterBy = filters.map(function(filter) {
    //   filter.fieldValue = { value: filter.fieldValue };
    //   return filter;
    // });

    var options = {
      // Restrict our returned fields to this array of keys.
      //desiredKeys: desiredKeys,
      // Fetch 5 results at a time.
      //resultsLimit: 5
    };

    options.zoneID = Constants.defaultZoneName;//hiveRecord.zoneID;

    // If we have a continuation marker, use it to fetch the next 5 results.
    // var continuationMarker = getContinuationMarker();
    // if (continuationMarker) {
    //   options.continuationMarker = continuationMarker;
    // }

    console.log('query, options', query, options);

    return db.performQuery(query, options)
      .then((response) => {
        if (response.hasErrors) {
          console.log('events of type, error', response.errors[0]);
        } else {
          // Save the continuation marker so we can fetch more results.
          // saveContinuationMarker(response.continuationMarker);
          // console.log('<<--events, # records:', eventTypeId, response.records);

          return response.records;
        }
      });
  }

  getGroupRecord(groups, recordName) {
    console.log("");
    const f = function(record) {
      return record.recordName === recordName;
    }

    var r = null;

    const recordP = groups.private.filter(f);
    if (recordP.length > 0) {
      r = recordP[0];
    }
    else {
      const recordS = groups.shared.filter(f);
      if (recordS.length > 0) {
        r = recordS[0];
      }
    }

    return r;
  }

  getHiveRecord(hives, recordName) {
    console.log("");
    const f = function(hive) {
      return hive.recordName === recordName;
    }

    var r = null;

    if (hives.private) {
      const recordP = hives.private.filter(f);
      if (recordP.length > 0) {
        r = recordP[0];
      }
      else {
        const recordS = hives.shared.filter(f);
        if (recordS.length > 0) {
          r = recordS[0];
        }
      }
    }
    else {
      // This is the case when hives contains all hives for a group (that is, not a dict with private or shared)
      return hives.filter(f);
    }

    return r;
  }

  getHivesInGroup(hives, groupRecordName) {
    //console.log("");
    const f = function(record) {
      return record.fields.groupRef.value.recordName === groupRecordName;
    }

    var r = [];

    if (hives.private) {
      // Private
      const recordP = hives.private.filter(f);
      if (recordP.length > 0) {
        r = recordP;
      }
      else {
        // Shared
        const recordS = hives.shared.filter(f);
        if (recordS.length > 0) {
          r = recordS;
        }
      }
    }
    else {
      return hives.filter(f);
    }

    // console.log('got hives for group', r, groupRecordName, recordP);
    return r;
  }


  // ----------------------------------------------------------
  // Query the hives that is referenced to by this group
  // ----------------------------------------------------------
  loadHivesInGroup(db, groupRecord) {
      var query = {
        recordType: 'Hive',
        filterBy: [{
          fieldName: 'groupRef',
          comparator: 'EQUALS',
          fieldValue: {value: {recordName: groupRecord.recordName}},
        }],
      };

      //TODO: bokstavsordning? eller kunna ange i gränssnittet om id eller bokstavs, eller tom temporär?
      // if (sortByField) {
      //   var sortDescriptor = {
      //     fieldName: 'name',
      //     ascending: ascending,
      //   };
      //   query.sortBy = [sortDescriptor];
      // }
      //
      // // Convert the filters to the appropriate format.
      // query.filterBy = filters.map(function(filter) {
      //   filter.fieldValue = { value: filter.fieldValue };
      //   return filter;
      // });

      var options = {
        // Restrict our returned fields to this array of keys.
        //desiredKeys: desiredKeys,
        // Fetch 5 results at a time.
        //resultsLimit: 5
      };

      options.zoneID = groupRecord.zoneID;

      // If we have a continuation marker, use it to fetch the next 5 results.
      // var continuationMarker = getContinuationMarker();
      // if (continuationMarker) {
      //   options.continuationMarker = continuationMarker;
      // }

      return db.performQuery(query, options)
        .then((response) => {
          if (response.hasErrors) {
            console.log('error', response.errors[0]);
          } else {
            // Save the continuation marker so we can fetch more results.
            // saveContinuationMarker(response.continuationMarker);
            return response.records;
          }
        });
  }

  // ----------------------------------------------------------
  // Update operations
  //https://developer.apple.com/library/archive/documentation/DataManagement/Conceptual/CloudKitWebServicesReference/ModifyRecords.html#//apple_ref/doc/uid/TP40015240-CH2-SW9
  //https://developer.apple.com/documentation/cloudkitjs/cloudkit/database
  // https://developer.apple.com/documentation/cloudkitjs/cloudkit/database/1628735-saverecords
  // https://cdn.apple-cloudkit.com/cloudkit-catalog/
  // ----------------------------------------------------------
  updateHive(hiveRecord, fieldsJson) {
    const ck = window.ckUtilInstance;
    var db = ck.recordIsPrivate(hiveRecord) ? ck.privateDb() : ck.sharedDb();

    var options = {
      "operationType" : "update",
      //zoneID: Constants.defaultZoneName,
      zoneID: hiveRecord.zoneID,
    };

    var record = {
      "fields": fieldsJson,
      "recordType": hiveRecord.recordType,
      "recordName" : hiveRecord.recordName,
      "recordChangeTag": hiveRecord.recordChangeTag,
    };

    //console.log("updateHive():", db, hiveRecord, record, options);
    db.saveRecords(record, options).then(function(response) {
      if (response.hasErrors) {
        console.error('ERROR: updateHive():', fieldsJson, hiveRecord, response.errors[0]);
      }
      else {
        console.log('OK: updateHive()', response);
      }
    });
  }

  // ----------------------------------------------------------
  // Util
  // ----------------------------------------------------------

  dbIsPrivate(db) {
    return db.databaseScope === 'PRIVATE';
  }

  dbIsShared(db) {
    return db.databaseScope === 'SHARED';
  }

  dbTypeString(db) {
    if (this.dbIsPrivate(db)) {
      return 'Privat';
    }
    else {
      return 'Delad';
    }
  }

  // GENERIC

  print(e) {

    alert('Hej!\n\nUtskriftsfunktionen är under utveckling och kan ibland ge märkliga resultat. Vänligen rapportera eventuella problem via email (se Kontakt i menyn).\n\nTack :-)');

    // Safari issue?
    try {
      document.execCommand('print', false, null);
      console.log('exec print', e);
    }
    catch(e) {
      window.print();
      console.log('window.print', e);
    }
  }

  // Fungerar inte i prod
  // demoFetchCurrentUserIdentity() {
  //
  //   // Fetch user's info.
  //   return this.container().fetchCurrentUserIdentity()
  //     .then(function(userIdentity) {
  //       var title = 'UserIdentity for current '+ (userIdentity.nameComponents ? 'discoverable' : 'non-discoverable')+' user:';
  //
  //       // Render the user's identity.
  //       console.log('demoFetchCurrentUserIdentity', title, userIdentity);
  //     });
  // }

  
}

export default CKUtil;
