import React, { Component } from 'react';
import './../css/HivesInGroupDetail.css';
import HiveItem from './HiveItem';
import * as SortOrderUtil from './SortOrderUtil';

class HivesInGroupDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNavBar: this.props.showNavBar,
      showHiveLinkButton: this.props.showHiveLinkButton,
      filterOnGroupRecord: this.props.filterOnGroupRecord,
      groups: this.props.groups,
      hivesInGroup: this.props.hivesInGroup,
    };
  }

  renderHives() {
    const {hivesInGroup, groups, showHiveLinkButton} = this.state;

    var results = [];

    if (groups.length === 0) {
      results.push(
        <div className="p-normal center-text space-above-small" key={Math.random()*100000}>
          Inga bigårdar
        </div>
      );
    }
    else if (hivesInGroup.length === 0) {
      results.push(
        <div className="p-normal center-text space-above-small" key={Math.random()*100000}>
          Inga samhällen i bigården
        </div>
      );
    }
    else {
      var sortedHivesInGroup = SortOrderUtil.hivesSortedOnSortOrder(hivesInGroup);
      for (var i = 0; i < sortedHivesInGroup.length; i++) {
        const record = sortedHivesInGroup[i];
        results.push(
          <div key={record.recordName + i}>
            <HiveItem
              hiveRecord={record}
              key={record.recordName}
              showHiveLinkButton={showHiveLinkButton}
              autoExpandCard={false} />
          </div>
        );
      }
    }
    return results;
  }

  setShowGroup = (groupName, groupRecordId) => {
    const {groups} = this.state;

    var groupRecord = null;
    for (var i = 0; i < groups.length; i++) {
      const record = groups[i];
      if (record.recordName === groupRecordId) {
        groupRecord = record;
      }
    }

    console.log('setShowGroup', groups, groupName, groupRecordId, groupRecord);

    this.setState({
      filterOnGroupRecord: groupRecord,
    }, () => {
      console.log("reloading groups");
    });
  }

  renderAllGroupLinks() {
    const {groups} = this.state;

    var links = [];

    for (var i = 0; i < groups.length; i++) {
      const record = groups[i];
      const rName = record.fields.name.value;
      const rId = record.recordName;
      links.push(<div className="dropdown-content-item" key={'dd'+i} onClick={this.setShowGroup.bind(this, rName, rId)}>{rName}</div>);
    }

    return links;
  }

  renderAllHiveLinks() {
    const {hivesInGroup} = this.state;

    var links = [];

    for (var i = 0; i < hivesInGroup.length; i++) {
      const record = hivesInGroup[i];
      const rName = record.fields.name.value;
      const rId = record.recordName;
      links.push(<div
        className="dropdown-content-item"
        key={'HiveItems' + rName + i}
        onClick={this.setShowHive.bind(this, rName, rId)}>{rName}</div>
      );
    }

    return links;
  }

  renderShowAllButton() {
    const {hivesInGroup, filterOnGroupRecord} = this.state;
    const selectedGroupName = filterOnGroupRecord.fields.name.value;

    if (hivesInGroup.length > 1) {
      return(
        <div className="dropdown">
          <button className="dropbtn" onClick={this.setShowAll}>
            Visa alla samhällen i bigården {selectedGroupName}
          </button>
      </div>);
    }

    return null;
  }

  renderSelectHivesButton() {
    const {hivesInGroup} = this.state;

    if (hivesInGroup.length > 0) {
      return(
        <div className="dropdown">
          <button className="dropbtn">Samhällen ▼
            <i className="fa fa-caret-down"></i>
          </button>
          <div className="dropdown-content">
            {this.renderAllHiveLinks()}
          </div>
        </div>);
    }

    return null;
  }

  renderSelectGroupsButton() {
    return (
      <div className="dropdown">
        <button className="dropbtn">Bigårdar ▼
          <i className="fa fa-caret-down"></i>
        </button>
        <div className="dropdown-content">
          {this.renderAllGroupLinks()}
        </div>
      </div>);
  }

  renderNavBar() {
    const {showNavBar} = this.state;
    if (showNavBar) {
      return (
          <div className="navbar">
            {this.renderSelectGroupsButton()}
            {this.renderSelectHivesButton()}
            {this.renderShowAllButton()}
          </div>
        );
    }
    else {
      return null;
    }
  }

  render() {
    return (
      <div className="HivesInGroupDetail">
        { this.renderNavBar() }
        { this.renderHives() }
      </div>
    );
  }
}

export default HivesInGroupDetail;
