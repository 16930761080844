import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom';
import './../css/LinkIcon.css';
import editIcon from './../img/icons8-edit.png';
import noEditIcon from './../img/icons8-no_edit.png';

class EditIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkArgument: this.props.linkArgument,
      editable: this.props.editable,
    };
  }

  icon() {
    return this.state.editable === true ? editIcon : noEditIcon;
  }

  linkArgument() {
    const {linkArgument} = this.state;

    if (linkArgument === undefined) {
      alert("UNDEFINED EDIT LINK ARGUMENT");
      return '/';
    }

    return linkArgument;
  }

  render() {
    return (
      <div className="EditIcon">
        <Link to={this.linkArgument()}>
          <img src={this.icon()} className="EditIcon-icon" alt='' />
        </Link>
      </div>
    );
  }
}

export default EditIcon;
