import React, { Component } from 'react';
import './../css/SegmentControllerItem.css';

class SegmentControllerItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titles: this.props.titles,
      callbacks: this.props.callbacks,
      selectedIndex: this.props.selectedIndex,
    };
  }

  button(index) {
    const {titles, callbacks, selectedIndex} = this.state;

    const cn = ((selectedIndex !== null) && (selectedIndex === index)) ? "SegmentControllerItem-button-selected" : "SegmentControllerItem-button"
    // console.log('Segment, index, selectedIndex, selected title', index, selectedIndex, titles[selectedIndex]);

    return (
      <div className={cn} onClick={callbacks[index]} key={index}>
        <div className="caption-small">{titles[index]}</div>
      </div>);
  }

  buttonSeparator(index) {
    return (
      <div className="SegmentControllerItem-buttonSeparator" key={'separator_' + index} />
    );
  }

  renderButtons() {
    const {titles} = this.state;
    const segments = [];
    for (var i = 0; i < titles.length; i++) {
      segments.push(this.button(i));
      if (i < (titles.length - 1)) {
        segments.push(this.buttonSeparator(i));
      }
    }
    return segments;
  }

  render() {
    return (
      <div className="SegmentControllerItem">
        {this.renderButtons()}
      </div>
    );
  }
}

export default SegmentControllerItem;
