export function csvStringFromArray(titles, dataSet) {
  var csv = '';

  if (titles.length === 0) {
    console.log('ERROR: No titles', titles);
  }
  else if (dataSet.length === 0) {
    console.log('ERROR: No data in data set', dataSet);
  }
  else if (titles.length !== dataSet[0].length) {
    console.log('ERROR: Invalid data set/title lengths', titles, dataSet);
  }
  else {
    var titleNo = 0;
    titles.forEach(function(title) {
      csv += title;
      titleNo++;
      if (titleNo < titles.length) {
        csv += ',';
      }
    });
    csv += '\n';

    dataSet.forEach(function(row) {
      csv += row.join(',');
      csv += "\n";
    });
  }

  //console.log(titles, dataSet, csv);

  return csv;
}
