import React, { Component } from 'react';
import * as Constants from './Constants';
import './../css/HivesOverviewTable.css';
import HivesOverviewTableItem from './HivesOverviewTableItem';
import UnderlinedIdAndCaptionSeparator from './UnderlinedIdAndCaptionSeparator';

class HivesOverviewTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: this.props.groups, // {private, shared}
      hives: this.props.hives, // {private, shared}
      presentationMineOrSubscribed: this.props.presentationMineOrSubscribed, // 0: mine, 1: subscribed
    };
  }


  // TOOD: setting om jag ska sortera på ID eller NAME för grupp reps hives
  renderTable(groupRecords, hiveRecords) {
    // const {hives} = this.state;
    var items = [];

    //console.log('-->', groupRecords, hiveRecords);

    const allhives = hiveRecords;//hives.private.concat(hives.shared);

    const sortedGroups = groupRecords.sort((a, b) => {
      return a.fields.name.value > b.fields.name.value;
    });

    // ALLA GROUPS
    for (var i = 0; i < sortedGroups.length; i++) {
      const groupRecord = sortedGroups[i];

      const link = '/' + Constants.groupDetailsPageId + '?id=' + groupRecord.recordName;

      //1) pusha en div med rubrik för bigårdens namn
      items.push(
        <UnderlinedIdAndCaptionSeparator record={groupRecord} key={i+'us'} link={link} />
      );
      //   <div className="caption-normal space-above-medium underlineRow">{groupRecord.fields.name.value}</div>

      //2) filtrera ut hives för denna gruppen
      const hiveRecords = allhives.filter(function (hive) {
        const g = hive.fields.groupRef.value.recordName;
        return g === groupRecord.recordName;
      });

      if (hiveRecords.length === 0) {
        items.push(
          <div className="HivesOverviewTable-section" key={'moti-no-hives' + i + new Date()}>
            <div className="p-normal center-text space-above-small">
              Inga samhällen i bigården
            </div>
          </div>
        );
      }
      else {
        //3) sortera hives
        const sortedHiveRecords = hiveRecords.sort((a, b) => {
          return a.fields.name.value > b.fields.name.value;
        });

        // ALLA HIVES IN DENNA GROUP
        for (var h = 0; h < sortedHiveRecords.length; h++) {
          const hiveRecord = sortedHiveRecords[h];
          items.push(
            <div className="HivesOverviewTable-item" key={'moti' + hiveRecord.recordName + i + new Date()}>
              <HivesOverviewTableItem hiveRecord={hiveRecord} key={hiveRecord.recordName + i} />
            </div>
          );
        }
      }
    }

    return items;
  }

  render() {
    const {groups, hives, presentationMineOrSubscribed} = this.state;

    var g = null;
    var h = null;
    if (presentationMineOrSubscribed === 0) {
      g = groups.private;
      h = hives.private;
    }
    else {
      g = groups.shared;
      h = hives.shared;
    }

    return (
      <div className="HivesOverviewTable">
        <div className="HivesOverviewTable-section">
          {this.renderTable(g, h)}
        </div>
      </div>
    );
  }
}

export default HivesOverviewTable;
