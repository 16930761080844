import React, { Component } from 'react';
import './../../css/GenericBarDiagram.css';
import LoadingIndicator from './../LoadingIndicator';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

class GenericBarDiagram extends Component {
  constructor(props) {
    super(props);

    // NOTE: validate input (array sizes is the same)

    //console.log('\nGenericBarDiagram::constructor', this.props);

    if (this.props.diagramType === 'pie') {
      console.error('UNSUPPORTED pie type');
    }

    //if (this.props.allData.length !== this.props.allLabels.length) {
    // const ds = this.props.chartData.datasets;
    // if (ds.length !== this.props.chartData.labels.length) {
    //   console.error('invalid chartData lengths', this.props.chartData);
    // }
    // else {
      // const validArrayLength = this.props.allSectionLabels.length;
      // var v = true;
      // for (var i=0; i<this.props.allData; i++) {
      //   if (this.props.allData.length !== validArrayLength) {
      //     v = false;
      //   }
      // }

      // if (!v) {
      //   console.error('The data sets must all have the same length', validArrayLength, this.props.allData);
      // }
      // else if (validArrayLength > 6) { // NOTE: warn on more than 6 values
      //   console.error('currently, max 6 data sections are allowed', validArrayLength);
      // }
      // else {
        //console.log('\n\nGenericBarDiagram::constructor', this.props);
        this.state = {
          divId: this.props.divId,
          showNoDataAvailable: this.props.showNoDataAvailable,

          chartData: this.props.chartData,

          allSectionLabels: this.props.allSectionLabels, // Deprekera

          diagramType: this.props.diagramType,

          chartContext: null,
          chartInstance: null,
          chartOptions: this.props.chartOptions,
        };
      // }
    // }
  }

  componentDidMount() {

    // console.log('DT', this.state);

    //https://www.chartjs.org/docs/latest/general/fonts.html
    Chart.defaults.global.defaultFontColor = 'black';
    // Chart.defaults.global.defaultFontStyle = 'normal';
    // Chart.defaults.global.defaultFontSize = '16';
    // Chart.defaults.global.defaultFontFamily = '';

    Chart.plugins.register(ChartDataLabels);
    // // Change default options for ALL charts
    //https://chartjs-plugin-datalabels.netlify.com/guide/options.html#scriptable-options
    Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
        color: 'black',
        font: {
          weight: 'bold',
        },
        anchor: 'end',
    });

    this.setupChart();
  }

  setupChart() {
    const {chartData, diagramType, chartContext, chartOptions, divId, /*allLabels, allData,allSectionLabels*/} = this.state;

    // var allDataSets = [];
    var data = null;

    if (diagramType === 'bar') {
      console.log(divId, diagramType);

      data = chartData;
    }
    else if (diagramType === 'pie') {
      console.log(divId, diagramType);
      data = chartData;
    }
    else {
      console.error("UNSUPPORTED DIAGRAM TYPE: " + diagramType);
      return;
    }

    if (chartContext) {
      // console.log('doDraw, chartContext');
    }
    else {
      const cchartCtx = document.getElementById(divId);
      // console.log('chartContext created', cchartCtx);

      const chart = new Chart(cchartCtx, {
        plugins: [ChartDataLabels],
        type: diagramType,
        data: data,
        options: chartOptions,
      });

      this.setState({
        chartContext: cchartCtx,
        chartInstance: chart,
      }, () => {
        console.log('chartContext + instantce created', cchartCtx);
      });
    }
  }

  render() {
    const {divId, chartContext, chartInstance, showNoDataAvailable} = this.state;
    //console.log('render generic diagram', divId);

    if (chartContext && chartInstance) {
      const d = document.getElementById(divId);
      if (showNoDataAvailable) {
        d.style.display = "none";
        return (<div className="center-text info-box">
          <br/>
          <br/>
          <br/>
          <br/>
          Data saknas
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>);
      }
      else {
        d.style.display = "block";
        return (
          <div/>
        );
      }
    }
    else {
      return <LoadingIndicator label={'chartContext...'}/>;
    }
  }
}

export default GenericBarDiagram;


/*

https://gionkunz.github.io/chartist-js/


https://www.chartjs.org/samples/latest/

https://cdnjs.com/libraries/Chart.js
https://chartjs-plugin-datalabels.netlify.com/guide/getting-started.html#installation

Tänkbara rubriker för statisit:
- skörd
• drottningar, släktskap
• sjukdomar och behandlingar, varroanedfall

SKÖRD:
• Testa sankey-flöden:
  sh-omg-bi-tot
  Sh omg res bi

  Gå igenom alla event typer och fundera vilken statistik den kan leda till

  Även vilka man kan slå ihop, tex styrka o vinterfld, eller vinterdöd o sjukdom

  Senare bildspel?

https://developers.google.com/chart/interactive/docs/gallery/sankey
https://plot.ly/javascript/sankey-diagram/

Cirkel
- ramfördelning i bigården
- skördresultat per bigård
- skördresultat per sh/bigård

TODO:
• segment: år
• segemnt för typ av skörd
• måste kunna visa en värdetabell
• label på varje sektion
*/
