import React, { Component } from 'react';
import './../css/MapOverview.css';

class MapOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupRecords: this.props.groupRecords,
      mapInstance: null,
      defaultRegion: null, // Used to zoom back
      annotations: null,
    };
  }

  componentDidMount() {
    if (window.mapkit) {
      var map = new window.mapkit.Map("map");
      if (!map) {
        console.log('fatal: mapkit no instance');
        return;
      }

      // 212_introducing_mapkit_js.pdf
      map.showsUserLocationControl = true;
      map.showsScale = window.mapkit.FeatureVisibility.Visible;
      // map.tintColor = "#ff4040";
      // map.isZoomEnabled = false;
      // map.isScrollEnabled = false;
      //map.isRotationEnabled = false;
      map.mapType = window.mapkit.Map.MapTypes.Hybrid;

      this.setState({mapInstance: map}, () => {
        this.initAnnotations();
      });
    }
    else {
      console.log('fatal: mapkit failed to load');
    }
  }

  initAnnotations() {
    const {groupRecords, annotations} = this.state;
    console.log('initAnnotations');

    function processRecords(isSingleRecord, record) {
      console.log('processRecords', isSingleRecord, record);

      function processOneRecord(c, title, shortName) {
        console.log('mapkit: processOneRecord', c, title, shortName);

        if (isSingleRecord) {
            let defaultSpan = 0.005;
            let defaultSpanHalf = defaultSpan / 2.0;

            lonMax = c.longitude + defaultSpanHalf;
            lonMin = c.longitude - defaultSpanHalf;
            latMax = c.latitude + defaultSpanHalf;
            latMin = c.latitude - defaultSpanHalf;
        }
        else {
            if (c.longitude > lonMax) {
                lonMax = c.longitude;
            }

            if (c.longitude < lonMin) {
                lonMin = c.longitude;
            }

            if (c.latitude > latMax) {
                latMax = c.latitude;
            }

            if (c.latitude < latMin) {
                latMin = c.latitude;
            }
        }

        const coordinate = new window.mapkit.Coordinate(c.latitude, c.longitude);

        // var workAnnotation = new MarkerAnnotation(work);
        // workAnnotation.color = "#969696";
        // workAnnotation.title = "Work";
        // workAnnotation.subtitle = "Apple Park";
        // workAnnotation.selected = "true";
        // workAnnotation.glyphText = "";
        // const sn = ((shortName !== undefined && shortName.length > 0) ? '['+shortName+'] ' : "").toUpperCase();
        // const t = sn + title;
        const sn = ((shortName !== undefined && shortName.length > 0) ? '['+shortName+'] ' : "").toUpperCase();
        const t = sn.length > 0 ? sn : title;
        // const a = new window.mapkit.MarkerAnnotation(coordinate, { color: "white", title: t, glyphText: "🐝" });
        // const imageUrl = record.fields.thumbnail.value.downloadURL;
        // console.log('r=', record);
        // console.log('i=', imageUrl);

        const a = new window.mapkit.MarkerAnnotation(coordinate, {
          color: "white",
          title: t,
          //subtitle: "5 samhällen",
          glyphText: "🐝",
        });

/*
new mapkit.MarkerAnnotation(coordinate, { title: "Fort de France",
subtitle: "Martinique",
color: "#53ac79",
glyphColor: "#fdc35d",
glyphImage: {
2: "palm_tree_2x.png" },
selectedGlyphImage: {
2: "palm_tree_selected_2x.png"
},
glyphText: "M" });
*/
        console.log('mapkit: coordinate -> a', a);
        tempAnnotations.push(a);
      }

      if (!record) {
        console.log("!record");
      }
      else {
        const c = record.fields.location.value;
        if (!c) {
          console.log("record.fields.location.value is undefined!", record.fields);
        }
        else if (c && c.longitude === 0 && c.latitude === 0) {
        }
        else {
          processOneRecord(c, record.fields.name.value, record.fields.shortName.value);
        }
      }
    }

    // Init annotations if not already done
    if (annotations) {
      console.log('annotations is already inited', annotations);
    }
    else {
      var lonMax = -180;
      var lonMin = 180;
      var latMax = -90;
      var latMin = 90;

      var tempAnnotations = [];

      if (groupRecords) {
        const isSingleRecord = groupRecords.length === 1;
        for (var i = 0; i < groupRecords.length; i++) {
          var gR = groupRecords[i];
          if (gR) {
            processRecords(isSingleRecord, gR);
          }
          else {
            console.log("!gR");
          }
        }

        this.setState({annotations: tempAnnotations}, () => {
          const f = 0.4;
          const spanLat = (latMax - latMin) * f;
          const spanLon = (lonMax - lonMin) * f;

          const region = new window.mapkit.CoordinateRegion(
            new window.mapkit.Coordinate((latMax - latMin)/2.0, (lonMax - lonMin)/2.0),
            new window.mapkit.CoordinateSpan(spanLat, spanLon)
          );

          this.focusInOnRegion(region);
        });
      }
      else {
        console.log('MO, !g');
      }
    }
  }

  focusInOnRegion(region) {
    if (window.mapkit) {
      this.setState({defaultRegion: region}, () => {
        const {mapInstance, defaultRegion, annotations} = this.state;
        mapInstance.region = defaultRegion;
        mapInstance.showItems(annotations);
      });
    }
  }

  renderMap() {
    if (!window.mapkit) {
      return (
        <div className="space-above-small space-under-small info-box-500">
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          Misslyckades att ladda kartan.
        </div>
      );
    }
    else {
      const {groupRecords} = this.state;
      var anyGroupHasCoordinatesSet = true;
      for (var i = 0; i < groupRecords.length; i++) {
        if (groupRecords[i].fields.location === null) {
          anyGroupHasCoordinatesSet = false;
        }
      }

      if (anyGroupHasCoordinatesSet) {
        return(<div className="space-above-small space-under-medium" id="map" />);
      }
      else {
        return (
          <div className="space-above-small space-under-small info-box-500">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            Dina bigårdar saknar koordinater. Placera ut dem på<br/>
            kartan via appen så kommer du se en kartöversikt här!
          </div>
        );
      }
    }
  }

  render() {
    const setDefaultRegion = () => {
      const {defaultRegion} = this.state;
      this.focusInOnRegion(defaultRegion);
    }

    return (
      <div>
        <div className="buttonRowLCR">
          <div className="generic-button" onClick={setDefaultRegion}>
            <div className="caption-small">Återställ zoom</div>
          </div>

          <div/>

          <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
            <div className="caption-small">Skriv ut</div>
          </div>
        </div>

        {this.renderMap()}
      </div>
    );
  }
}

export default MapOverview;
