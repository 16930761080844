import React, { Component } from 'react';
import * as Constants from './Constants';
import './../css/MapOverviewTableItem.css';
import { Link } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import placeholder from '../img/placeholder.png';

class MapOverviewTableItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupRecord: this.props.groupRecord,
      hivesInGroup: this.props.hivesInGroup,
    };
  }

  renderIsTemporaryStr() {
    const {groupRecord} = this.state;
    const isTemp = groupRecord.fields.isTemporaryLocation.value ? 'Tillfällig' : 'Permanent';
    return isTemp + ' uppställning';
  }

  renderHiveCountStr() {
    const {hivesInGroup} = this.state;
    var str = "";
    const count = hivesInGroup.length;
    if (count === 0) {
      str = "Inga samhällen";
    }
    else if (count === 1) {
      str = "1 samhälle";
    }
    else {
      str = count + " samhällen";
    }

    return str;
  }

  renderBadge(groupRecord) {
    const s = groupRecord.fields.shortName;

    if (s !== undefined && s.value.length > 0) {
      return (
        <div className="idBadge" key={'GOTI-badge'+groupRecord.recordName+new Date()}>
          <div className="caption-small">
            {s.value}
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  thumbURL(groupRecord) {
    const filename = groupRecord.fields.thumbnail;
    
    if (filename !== undefined) { // } && filename.value.length > 0) {
      const s = filename.value.downloadURL;
      if (s !== undefined) { // } && s.value.length > 0) {
        return s;
      }
    }

    return placeholder;
  }

  render() {
    const {groupRecord, hiveCount} = this.state;

    if (hiveCount !== null) {
      return (
        <div className="MapOverviewTableItem" key={'xGOTI'+new Date()}>
          <Link to={'/' + Constants.groupDetailsPageId + '?id=' + groupRecord.recordName}>

            <div className="MapOverviewTableItem-headerArea">
              <div className="caption-normal b center-text">
                {groupRecord.fields.name.value}
              </div>
            </div>

            <div className="MapOverviewTableItem-inner" key={groupRecord.recordName}>
              <div className="MapOverviewTableItem-descriptionArea">

                <div className="caption-normal">
                  {this.renderHiveCountStr()}
                </div>
                <div className="p-small">
                  <br/>
                  {this.renderIsTemporaryStr()}
                </div>
              </div>

              <div className="MapOverviewTableItem-badge-container">
                <img className="MapOverviewTableItem-groupImage" src={this.thumbURL(groupRecord)} alt={groupRecord.recordName} />
                <div className="MapOverviewTableItem-badge">{this.renderBadge(groupRecord)}</div>
              </div>

            </div>

          </Link>
        </div>
      );
    }
    else {
      return <LoadingIndicator label={'mapovti...'}/>;
    }
  }
}

export default MapOverviewTableItem;
