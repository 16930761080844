import React, { Component } from 'react';
import * as Constants from './Constants';
import './../css/EventsArea.css';
import SegmentControllerItem from './SegmentControllerItem';
import LoadingIndicator from './LoadingIndicator';
import EventTimeLineItem from './EventTimeLineItem';
import * as EventItemUtil from './EventItemUtil';
import ToggleDetailsItem from './ToggleDetailsItem';

class EventsArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiveRecord: this.props.hiveRecord, // If null -> get only group events
      groupRecord: this.props.groupRecord, // To get group events, to aggregate with hive events
      asTimeLine: this.props.asTimeLine,
      expanded: this.props.expanded,
      eventRecords: null,
    };
  }

  componentDidMount() {
    this.fetchEvents(this.state.hiveRecord, this.state.groupRecord);
  }

  fetchEvents(hiveRecord, groupRecord) {
    const ck = window.ckUtilInstance;

    var db = null;
    if (hiveRecord) {
      db = ck.recordIsPrivate(hiveRecord) ? ck.privateDb() : ck.sharedDb();
    }
    else if (groupRecord) {
      db = ck.recordIsPrivate(groupRecord) ? ck.privateDb() : ck.sharedDb();
    }
    else {
      console.error('no hive or group id passed');
    }

    ck.loadEventsInHive(db, hiveRecord).then((events) => {
      //console.log('got hive events', events);

      ck.loadEventsInGroup(db, groupRecord).then((groupEvents) => {
        //console.log('got group events', groupEvents, groupRecord, hiveRecord);

        var filteredGroupEvents = [];

        if (hiveRecord) {
          filteredGroupEvents = groupEvents.filter((groupEvent) => {
            const x = groupEvent.fields.hivesInGroupWhenCreated;
            if (x) {
              const a = x.value.split(';');
              //console.log('x', x, a, hiveRecord.recordName);
              return a.indexOf(hiveRecord.recordName) > -1;
            }
            else {
              return false;
            }
          });
        }
        else {
          filteredGroupEvents = groupEvents;
        }

        const hiveAndGroupEvents = events.concat(filteredGroupEvents);

        this.setState({
          eventRecords: hiveAndGroupEvents,
        });
      });
    });

    //
    //
    //
    // const db = ck.recordIsPrivate(hiveRecord) ? ck.privateDb() : ck.sharedDb();
    // ck.loadEventsInHive(db, hiveRecord).then((events) => {
    //   console.log('got hive events', events);
    //   ck.loadEventsInGroup(db, groupRecord).then((groupEvents) => {
    //     console.log('got hive events', events);
    //
    //     const hiveAndGroupEvents = events.concat(groupEvents);
    //
    //     this.setState({
    //       eventRecords: hiveAndGroupEvents,
    //     });
    //   });
    // });
  }

  renderSegment() {
    const {asTimeLine} = this.state;

    const setPresentationToTimeline = () => {
      this.setState({asTimeLine: true});
    }

    const setPresentationToTable = () => {
      this.setState({asTimeLine: false});
    }

    return(
      <SegmentControllerItem
        titles={['Tabell', 'Tidslinje']}
        callbacks={[setPresentationToTable, setPresentationToTimeline]}
        selectedIndex={(asTimeLine ? 1 : 0)}
        key={'sci' + new Date()} />
    );
  }

  sortedYearsFromEvents(eventRecords) {
    var years = new Set();
    eventRecords.map((event) => {
      years.add(new Date(event.fields.date.value).getFullYear());
      return null;
    });

    return Array.from(years).sort((year0, year1) => {
      return year1 - year0;
    });
  }

  sortedEventsForYear(eventRecords, year) {
    let eventsForYear = eventRecords.filter((event) => {
      return new Date(event.fields.date.value).getFullYear() === year;
    });

    return eventsForYear.sort((e0, e1) => {
      let e0d = e0.fields.date.value;
      let e1d = e1.fields.date.value;
      return e1d - e0d;
    });
  }

  renderEvents() {
    const {eventRecords, asTimeLine} = this.state;

    var result = [];

    // Render all events, separated by a year label
    this.sortedYearsFromEvents(eventRecords).forEach((year) => {
      var resultPerYear = [];
      var tableHeader = [];

      // For table, draw a header for each year
      if (asTimeLine) {
      }
      else {
        for (var h = 0; h < Constants.eventTypeTitles.length; h++) {
          tableHeader.push(
            <div className="caption-small rotated noWrap" key={'h' + h}>{Constants.eventTypeTitles[h]}</div>
          );
        }
      }

      // The events
      let sortedEventsForYear = this.sortedEventsForYear(eventRecords, year);
      for (var i = 0; i < sortedEventsForYear.length; i++) {
        const event = sortedEventsForYear[i];

        const isFirstInTimeLine = i === 0;
        const isLastInTimeLine = i === (sortedEventsForYear.length - 1);

        // -------------------------
        // Events as timeline
        // -------------------------
        if (asTimeLine) {
          resultPerYear.push(
            <EventTimeLineItem
              eventRecord={event}
              key={'ei' + new Date() + i}
              rowIndex={i}
              isFirstInTimeLine={isFirstInTimeLine}
              isLastInTimeLine={isLastInTimeLine} />
          );
        }
        // -------------------------
        // Events as table
        // -------------------------
        else {
          const f = event.fields;

          var r = 100 * i + 0;

          resultPerYear.push(<div className="p-small r-left" key={r++}>{EventItemUtil.formattedDateMonthDay(f.date.value)}</div>);
          resultPerYear.push(<div className="p-small r-left" key={r++}>{EventItemUtil.nameOfEvent(f.typeId.value)}</div>);

          switch (f.typeId.value) {
              case 0 /*inspection*/:
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.hiveStrengthStr(f.hiveStrength.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.temperamentStr(f.temperamentTypeIdNumber.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.marker(f.eggVisible.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.marker(f.larvasVisible.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.marker(f.queenObserved.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.marker(f.queenCellsVisible.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.numBroadFramesStr(f.numberOfBroodFrames.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.marker(f.foodIsAvailable.value)}</div>);
                resultPerYear.push(<div className="p-small r" key={r++}>{EventItemUtil.marker(f.expansionAllowed.value)}</div>);
                break;

              default:
                for (var j=0; j<9; j++) {
                  resultPerYear.push(<div className="p-small r" key={r++}></div>);
                }
                break;
          }

          // Set the comments field
          var notesStr = f.notes.value;
          switch (f.typeId.value) {
            case 5: /*Utfodring*/
              notesStr = EventItemUtil.d5(f) + '\n' + notesStr;
              break;

            case 6: /*Drönarram*/
              notesStr = EventItemUtil.d6(f) + '\n' + notesStr;
              break;

            case 8: /*Förlust*/
              notesStr = EventItemUtil.d8(f) + '\n' + notesStr;
              break;

            case 12: /*Hälsoåtgärd*/
              notesStr = EventItemUtil.d12(f) + '\n' + notesStr;
              break;

            case 100: /*Bild*/
              //<a href={f.asset.value.downloadURL} target="_blank">Länk till bild</a> + '\n' + notesStr;
              // notesStr = notesStr;
              break;

            default:
              break;
          }

          resultPerYear.push(
            <div className="p-small r-left" key={r++}>{notesStr}</div>
          );
        }

      }

      // A year label
      result.push(
        <div className="space-above-medium" key={year + '_' + asTimeLine}>
          <div className="caption-medium center-text underlineRow">
            {year}
          </div>
        </div>
      );

      // Format each year segment
      if (asTimeLine) {
        result.push(resultPerYear);
      }
      else {
        result.push(
          <div className="card" key={'card' + year}>
            {tableHeader}
            {resultPerYear}
          </div>
        );
      }

    });

    return result;
  }

  renderToggelArea() {
    const {expanded, eventRecords} = this.state;

    const setExpanded = () => {
      this.setState({expanded: true});
    }

    const setMinimized = () => {
      this.setState({expanded: false});
    }

    const segmentIfAnyEvents = () => {
      // const {eventRecords} = this.state;
      if (eventRecords.length > 0) {
        return this.renderSegment();
      }
      return null;
    }

    if (expanded) {
      return(
        <div className="EventsArea-segmentAndToggleArea-expanded">
          <div className="buttonRowLCR-fixed">
            <div/>

            <div className="EventsArea-segmentController-expanded">
              <div className="no-print">
                {segmentIfAnyEvents()}
              </div>
            </div>

            <div className="align-right">
              <div className="no-print">
                <ToggleDetailsItem
                  toggleState={expanded}
                  callbackToggleOn={setExpanded}
                  callbackToggleOff={setMinimized}
                  eventsCount={eventRecords.length} />
              </div>
            </div>

          </div>

          {this.renderEvents()}
        </div>
      );
    }
    else {
      if (eventRecords.length === 0) {
        return (
          <div className="EventsArea-segmentAndToggleArea-minimized">
            <ToggleDetailsItem
              toggleState={false}
              callbackToggleOn={null}
              callbackToggleOff={null}
              eventsCount={eventRecords.length} />
          </div>
        );
      }
      else {
        return (
          <div className="EventsArea-segmentAndToggleArea-minimized">
            <ToggleDetailsItem
              toggleState={expanded}
              callbackToggleOn={setExpanded}
              callbackToggleOff={setMinimized}
              eventsCount={eventRecords.length} />
          </div>
        );
      }
    }
  }

  render() {
    const {eventRecords} = this.state;
    if (eventRecords) {
      return (
        <div className="EventsArea">
          {this.renderToggelArea()}
        </div>
      );
    }
    else {
      return <LoadingIndicator label={'Events...'}/>;
    }
  }
}

export default EventsArea;
