import React, { Component } from 'react';
import '../css/App.css';
import * as Constants from './Constants';
import TopHeader from './TopHeader';
import TopHeaderMobile from './TopHeaderMobile';
import SideBySideArea from './SideBySideArea';
import InfoUseAppInstead from './InfoUseAppInstead';
import DownloadApp from './DownloadApp';
import UnsupportedBrowser from './UnsupportedBrowser';

class App extends Component {
  constructor() {
    super();

    this.state = {
      userInfo: null,
    };
  }

  onCKInitied() {
    const ui = window.ckUtilInstance.getUserInfo();
    this.setState({userInfo: ui});
  }

  onSetupAuthDone(e) {
    const ck = window.ckUtilInstance;
    console.log('all, onSetupAuthDone', e, ck);
    if (ck) {
      this.onCKInitied();
    }
  }

  onSignedIn(e) {
    const ck = window.ckUtilInstance;
    console.log('all, onSignedIn', e, ck);
    if (ck) {
      this.onCKInitied();
    }
  }

  componentDidMount() {
    // NOTE: Listen for events that tell that we are signed in and should re-render
    window.addEventListener(Constants.event__setupAuthDone, (e) => {this.onSetupAuthDone(e)});
    window.addEventListener(Constants.event__userSignedIn, (e) => {this.onSignedIn(e)});
  }

  componentWillUnmount() {
    //TODO
    // window.removeEventListener(Constants.event__setupAuthDone, {this.onSetupAuthDone});
    // window.removeEventListener(Constants.event__userSignedIn, {this.onSignedIn});
  }

  closeT() {
    var x = document.getElementById("snackbar");
    x.className = x.className.replace("show", "");
  }

  updateOnToast() {
    console.log("up");
    // this.setState({state: this.state});
    //this.closeToast();
    window.location.reload(); // TODO: är detta verkligen ok???
  }

  renderToastDiv() {
      return (
        <div id="snackbar">
          <div className="caption-normal">
            Dina data har uppdaterats
          </div>
          <div>
            Klicka på Uppdatera för att uppdatera sidan
          </div>
          <br/>
          <div className="buttonRow">
            <div className="toastButton" onClick={() => this.updateOnToast()}>Uppdatera</div>
            <div className="toastButton" onClick={() => this.closeT()}>Senare</div>
          </div>
        </div>
      );
  }

  renderSignedOut() {
    return (
      <div className="App-signedOut">
        <div className="App-signedOut-infoArea">
          <div className="caption-huge">Logga in med ditt Apple ID</div>
          <br/>
          <div className="p">
            <div className="center-text">
              Använd samma Apple ID som på enheterna där du har appen installerad.<br/>
            </div>
          </div>
          <br/>
          <br/>
          <div>
            <div id="apple-sign-in-button"></div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <div className="caption-medium">Vad erbjuder Mina bigårdar på webben?</div>
          <div className="p-small">
            <ul>
              <li>Alla information om dina bigårdar och samhällen</li>
              <li>Historik/skötselkort</li>
              <li>Statiskt och diagram</li>
              <li>Skriv ut all information</li>
              <li>Användarmanual för appen</li>
            </ul>
          </div>
          <br/>
          <br/>
          <br/>
          <DownloadApp />
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <div>
        <div className="desktop">
          <TopHeader />
        </div>
        <div className="mobile no-print">
          <TopHeaderMobile />
        </div>
      </div>
    );
  }

  renderDesktopView() {
    if (window.ckUtilInstance && window.ckUtilInstance.isSignedIn()) {
      return (
        <div>
          {this.renderHeader()}
          <SideBySideArea />
          {this.renderToastDiv()}
        </div>
      );
    }
    else {
      return (
        <div>
          {this.renderHeader()}
          {this.renderSignedOut()}
        </div>
      );
    }
  }

  isSupportedBrowser() {
    //return true // Fejka för att kunna testa IE mfl

    function detectBrowser() {
      if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ) {
        return 'Opera';
      }
      else if((navigator.userAgent.indexOf("Edge") !== -1 )) {
        return 'Edge';
      }
      else if(navigator.userAgent.indexOf("Safari") !== -1) {
          return 'Safari';
      }
      else if(navigator.userAgent.indexOf("Chrome") !== -1 ) {
          return 'Chrome';
      }
      else if(navigator.userAgent.indexOf("Firefox") !== -1 ) {
           return 'Firefox';
      }
      else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) { //IF IE > 10
        return 'IE';
      }

      return 'unknown';
    }

    const browserName = detectBrowser();
    console.log('browser', browserName);
    if (browserName === 'Safari' || browserName === 'Chrome') {
      return true;
    }

    return false;
  }

  render() {
    if (this.isSupportedBrowser()) {
      return (
        <div className="App">

          <div className="desktop">
            {this.renderDesktopView()}
          </div>

          <div className="mobile no-print">
            <InfoUseAppInstead />
          </div>

        </div>
      );
    }
    else {
      return (
        <div className="App">
          <UnsupportedBrowser />
        </div>
      );
    }
  }

}

export default App;
