import React, { Component } from 'react';
import './../css/UserGuidePage.css';
import './../css/navbar.css';
import PageHeader from './PageHeader';
import * as UserGuideSubPageContents from './userguide/UserGuideSubPageContents';
import UserGuideSubPage from './userguide/UserGuideSubPage';

class UserGuidePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      activeSubPageIndex: 0, // Default
    };
  }

  setShowHelpAction = (subPageIndex) => {
    this.setState({activeSubPageIndex: subPageIndex});
  }

  renderAllSubPageLinks() {
    var links = [];

    var titles = [];
    for (var i = 0; i < UserGuideSubPageContents.subPage_titles.length; i++) {
      titles.push(UserGuideSubPageContents.subPage_titles[i]);
    }

    for (var j = 0; j < titles.length; j++) {
      links.push(
        <div
          className="dropdown-content-item"
          key={'titles' + j}
          onClick={this.setShowHelpAction.bind(this, j)}>{titles[j]}</div>
      );
    }

    return links;
  }

  renderActiveSubPage() {
    const {activeSubPageIndex} = this.state;
    return (
      <UserGuideSubPage
        subPageIndex={activeSubPageIndex}
        key={'subPage' + activeSubPageIndex}
      />
    );
  }

  render() {
    const {title} = this.state;

    return (
      <div>
        <PageHeader title={title} />
        <div className="UserGuidePage">

          <div className="navbar">
            <div className="dropdown">
              <button className="dropbtn">Avsnitt ▼
                <i className="fa fa-caret-down"></i>
              </button>
              <div className="dropdown-content">
                {this.renderAllSubPageLinks()}
              </div>
            </div>
          </div>

          {this.renderActiveSubPage()}
        </div>
      </div>
    );
  }
}

export default UserGuidePage;
