import React, { Component } from 'react';
import './../css/StatisticsPage.css';
import PageHeader from './PageHeader';
import HarvestDiagramArea from './Diagrams/HarvestDiagramArea';
import HiveDeathDiagramArea from './Diagrams/HiveDeathDiagramArea';
import * as GenericDiagramUtil from './Diagrams/GenericDiagramUtil';

class StatisticsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      groups: this.props.groups, // {private, shared}
      allHives: this.props.allHives, // {private, shared}

      diagramDivIdsToShow: [], // If the div is here, show it, else hide
    };
  }

  showDiv(divId) {
    const {diagramDivIdsToShow} = this.state;
    console.log('show', diagramDivIdsToShow);
    this.setState({
      diagramDivIdsToShow: diagramDivIdsToShow.concat(divId),
    });
  }

  hideDiv(divId) {
    const {diagramDivIdsToShow} = this.state;
    console.log('hide', diagramDivIdsToShow);
    this.setState({
      diagramDivIdsToShow: diagramDivIdsToShow.filter((id) => {
        return id !== divId;
      })
    });
  }

  renderHarvestDiagramArea(divId) {
    const {diagramDivIdsToShow, groups, allHives} = this.state;

    const shouldShow = diagramDivIdsToShow.includes(divId);
    const buttonTitle = shouldShow ? 'Göm' : 'Visa';
    const buttonFunc = shouldShow ? () => this.hideDiv(divId) : () => this.showDiv(divId);
    const diagramDiv = shouldShow ? (<HarvestDiagramArea groups={groups} allHives={allHives} />) : null;
    const cssClassName = shouldShow ? "" : "no-print";

    return (
      <div className={cssClassName}>
        {GenericDiagramUtil.renderTitleWithShowHideButton('Skörd', buttonTitle, buttonFunc)}
        {GenericDiagramUtil.renderDescription('Mängd skörd för det valda året. Beräkningsunderlaget är den skörd som rapporterats i appen under Hjälpmedel > Skörda honung, pollen eller propolis.')}
        {diagramDiv}
      </div>
    );
  }

  renderHiveDeathDiagramArea(divId) {
    const {diagramDivIdsToShow} = this.state;

    const shouldShow = diagramDivIdsToShow.includes(divId);
    const buttonTitle = shouldShow ? 'Göm' : 'Visa';
    const buttonFunc = shouldShow ? () => this.hideDiv(divId) : () => this.showDiv(divId);
    const diagramDiv = shouldShow ? (<HiveDeathDiagramArea />) : null;
    const cssClassName = shouldShow ? "" : "no-print";

    return (
      <div className={cssClassName}>
        {GenericDiagramUtil.renderTitleWithShowHideButton('Vinterförluster', buttonTitle, buttonFunc)}
        {GenericDiagramUtil.renderDescription('Antal invintrade respektive förlorade samhällen, samt andel vinterförluster. Beräkningssunderlaget är 1 jan – 31 dec för invintring och 1 juni – 31 maj (året därpå) för förluster. Händelsetyperna som används är Invintring och Förlorat samhälle.')}
        {diagramDiv}
      </div>
    );
  }

  render() {
    const {title} = this.state;

    return (
      <div className="StatisticsPage">
        <PageHeader title={title} hidePrintButton={true} />

        <div className="StatisticsPage-content">

          <div className="buttonRowLCR space-under-medium">
            <div/>
            <div/>
            <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
              <div className="caption-small">Skriv ut</div>
            </div>
          </div>

          <div/>

          {this.renderHarvestDiagramArea('harvestDiagramArea')}
          {this.renderHiveDeathDiagramArea('hiveDeathDiagramArea')}

        </div>
      </div>
    );
  }
}

export default StatisticsPage;
