import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import * as Constants from './Constants';
import './../css/GroupsItem.css';
import './../css/navbar.css';
import GroupDetail from './GroupDetail';

class GroupsItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: this.props.groups,
      hives: this.props.hives,
    };
  }

  renderGroups() {
    const {groups, hives} = this.state;
    var z = [];
    for (var i = 0; i < groups.length; i++) {
      const record = groups[i];
      const link = '/' + Constants.groupDetailsPageId + '?id=' + record.recordName;
      z.push(
        <GroupDetail
          groupRecord={record}
          hivesInGroup={window.ckUtilInstance.getHivesInGroup(hives, record.recordName)}
          link={link}
          key={record.recordName} />
      );
    }
    return z;
  }

  // renderAllGroupLinks() {
  //   const {groups} = this.state;
  //   var links = [];
  //   for (var i = 0; i < groups.length; i++) {
  //     const groupRecord = groups[i];
  //     const rName = groupRecord.fields.name.value;
  //     const rId = groupRecord.recordName;
  //     links.push(
  //       <Link to={'/' + Constants.groupDetailsPageId + '?id=' + rId}>
  //         <div className="dropdown-content-item" key={'dd'+i} >{rName}</div>
  //       </Link>
  //     );
  //   }
  //
  //   return links;
  // }

  render() {
    return (
      <div className="GroupsItem">
        <div className="GroupsItem-content">
          {this.renderGroups()}
        </div>
      </div>
    );

    // return (
    //   <div className="GroupsItem">
    //     <div className="GroupsItem-header">
    //
    //       <div className="navbar">
    //         <div className="dropdown">
    //           <button className="dropbtn">Bigårdar ▼
    //             <i className="fa fa-caret-down"></i>
    //           </button>
    //           <div className="dropdown-content">
    //             {this.renderAllGroupLinks()}
    //           </div>
    //         </div>
    //       </div>
    //
    //     </div>
    //     <div className="GroupsItem-content">
    //       {this.renderGroups()}
    //     </div>
    //   </div>
    // );
  }
}

export default GroupsItem;
