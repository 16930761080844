import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom';
import './../css/LinkIcon.css';
import linkIcon from './../img/icons8-external-link-512.png';
import linkIconWhite from './../img/icons8-external-link-512-white.png';

class LinkIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkArgument: this.props.linkArgument,
      whiteIcon: this.props.whiteIcon,
    };
  }

  icon() {
    const {whiteIcon} = this.state;
    if (whiteIcon) {
      return linkIconWhite;
    }
    else {
      return linkIcon;
    }
  }

  linkArgument() {
    const {linkArgument} = this.state;

    if (linkArgument === undefined) {
      //alert("UNDEFINED LINK ARGUMENT");
      return '/';
    }

    return linkArgument;
  }

  render() {
    return (
      <div className="LinkIcon">
        <Link to={this.linkArgument()}>
          <img src={this.icon()} className="LinkIcon-icon" alt='' />
        </Link>
      </div>
    );
  }
}

export default LinkIcon;
