import React, { Component } from 'react';
import DownloadApp from './DownloadApp';

class InfoInitByUsingApp extends Component {
  render() {
    return (
      <div>

          <div className="space-above-big space-under-medium center-text">
            <div className="caption-huge">
              Ladda ner appen först...
            </div>
          </div>
          <div className="center-box">
            <div className="box-item-w60">
              <div className="p left-text">
                Mina bigårdar på webben är ett komplement till appen. Innan du kan se någon
                information här, behöver du ladda ner och starta appen.
                <br />
                <br />
                När du registrerat dina bigårdar och samhällen i appen, kommer de visas här.
                <br />
                <br />
                <br />
                <DownloadApp />
              </div>
            </div>
          </div>

      </div>
    );
  }
}

export default InfoInitByUsingApp;
