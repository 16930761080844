import React, { Component } from 'react';
import logo from './../img/loading-indicators/1.svg';
import './../css/LoadingIndicator.css';

class LoadingIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: this.props.label,
    };
  }

  render() {
    return (
      <div className="LoadingIndicator">
        <img src={logo} className="LoadingIndicator-logo" alt="logo" />
      </div>
    );
    // const getLabel = () => {
    //   const {label} = this.state;
    //   return (<div>{label}</div>);
    // }
    //
    // return (
    //   <div className="LoadingIndicator">
    //     <img src={logo} className="LoadingIndicator-logo" alt="logo" />
    //     <div className="caption-huge">{getLabel()}</div>
    //   </div>
    // );
  }
}

export default LoadingIndicator;
