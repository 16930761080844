import React, { Component } from 'react';
import './../css/EventTimeLineVerticalLine.css';

class EventTimeLineVerticalLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invisible: this.props.invisible,
    };
  }

  render() {
    const {invisible} = this.state;
    const css = invisible ? "EventTimeLineVerticalLine-invisible" : "EventTimeLineVerticalLine";
    return (
      <div className={css} />
    );
  }
}

export default EventTimeLineVerticalLine;
