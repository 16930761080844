import React, { Component } from 'react';
import logo from '../img/logo_200x200.png';
import '../css/TopHeaderMobile.css';
import * as Constants from './Constants';

class TopHeaderMobile extends Component {

  render() {
    return (
      <div>
        <div className="TopHeaderMobile">
          <div className="buttonRowLR">
            <div className="TopHeaderMobile-title">
              <div className="caption-huge">{Constants.appName()}</div><br/>
              <div className="p-small">Rickard Dahl</div>
            </div>

            <div className="TopHeader-logoArea">
              <img className="TopHeaderMobile-logo-img" src={logo} alt="logo" />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default TopHeaderMobile;
