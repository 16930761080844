import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './css/index.css';
import App from './component/App';
import * as Constants from './component/Constants';
import CKUtil from './component/CKUtil';

// -------------------
// MapKit
// -------------------

function initMapKit() {
  if (window.mapkit) {
    // window.mapkit.init({
    //   authorizationCallback: function(done) {
    //     // Origin: https://www.minabigardar.se
    //     done('eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjU5UzMyMjlHVzYifQ.eyJpc3MiOiJGSFA1QVpGVkg3IiwiaWF0IjoxNTgzOTUwNDE2LCJleHAiOjE2MTM0MTI4MTYsIm9yaWdpbiI6Imh0dHBzOi8vd3d3Lm1pbmFiaWdhcmRhci5zZSJ9.22mTEfzvS_heU3vVPlGU7oJbrs3WMJqn6V79SmIHIbxaTZrrBb_V3b7HCweDK_2ly6N4zOrE4bZA_vpCNydoVg');
    //   },
    //   // language: Constants.mapKitLangugageCode(),
    //   language: 'en',
    // });

    window.mapkit.init({
        authorizationCallback: function(done) {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", "/services/jwt");
          xhr.addEventListener("load", function() {
            const tokenID = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjU5UzMyMjlHVzYifQ.eyJpc3MiOiJGSFA1QVpGVkg3IiwiaWF0IjoxNjg1MzkwMjIxLCJleHAiOjE3MTY5NDA4MDAsIm9yaWdpbiI6Imh0dHBzOi8vd3d3Lm1pbmFiaWdhcmRhci5zZSJ9.Be02OFdA4ntVWux0za-y_ArJQPR5emAw0nT5X9J-30f_xZZqYGagmkeqTWymke1f49lJ8fplmAxk3Ztt-fldqQ";
            done(tokenID);
          });
          xhr.send();
      },
      language: 'en',
    });

    window.mapkit.addEventListener("configuration-change", (event) => {
      switch (event.status) {
      case "Initialized":
        // MapKit JS initialized and configured.
        console.log("NOTIFICATION: MapKit initialized");
        break;

      case "Refreshed":
        // MapKit JS configuration updated.
        console.log('NOTIFICATION: MapKit refreshed');
        break;

      default:
        console.log('NOTIFICATION: MapKit other event', event);
        break;
      }
    });
  }
  else {
    console.log('fatal: mapkit failed to load');
  }
}


// -------------------
// Language
// -------------------

function languageFromNavigatorLanguage() {
  //http://www.lingoes.net/en/translator/langcode.htm
  const twoFirst = navigator.language ? navigator.language.substring(0,2) : null;
  console.log('navigator.language', navigator.language, twoFirst);

  // --- Temporary translation fix ---
  console.log('[DISABLED] *** Translation is disabled. Will always use', Constants.languageIndexes.se);

  return Constants.languageIndexes.se;
  // TODO: To activate later...
  // switch (twoFirst) {
  //   case 'sv':
  //     return Constants.languageIndexes.se;
  //
  //   default:
  //     return Constants.languageIndexes.en;
  // }
}


/*
 * Init all things...
 */
window.languageIndex = languageFromNavigatorLanguage(); // Do first to ensure the rest is in the proper locale
console.log('Language is now set to', window.languageIndex, Constants.languageName());

document.title = Constants.appName();

initMapKit();

window.google.charts.load("current", {packages:["sankey", "corechart"]});
// window.google.charts.load("current", {packages:["sankey"]});
// window.google.charts.setOnLoadCallback(this.drawChart);

window.ckUtilInstance = new CKUtil();

console.log('********************\nInitial setup completed\n********************');

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

registerServiceWorker();
