import React from 'react';
// import './../css//GenericDiagramUtil.css';
// import Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels.js';

export const grayColor = 'rgba(192, 192, 192, 0.5)';
export const grayColorLine = 'rgba(192, 192, 192, 0.8)';


export const redColor = 'rgba(255, 99, 132, 0.2)';
export const yellowColor = 'rgba(255, 206, 86, 0.2)';
export const blueColor = 'rgba(54, 162, 235, 0.2)';
//export const greenColor = 'rgba(75, 192, 192, 0.2)';
export const greenColor = 'rgba(202, 223, 128, 0.2)';
export const purpleColor = 'rgba(153, 102, 255, 0.2)';
export const orangeColor = 'rgba(255, 159, 64, 0.2)';

export const redColorLine = 'rgba(255,99,132, 0.8)';
export const yellowColorLine = 'rgba(255, 206, 86, 0.8)';
export const blueColorLine = 'rgba(54, 162, 235, 0.8)';
//export const greenColorLine = 'rgba(75, 192, 192, 0.8)';
export const greenColorLine = 'rgba(202, 223, 128, 0.8)';
export const purpleColorLine = 'rgba(153, 102, 255, 0.8)';
export const orangeColorLine = 'rgba(255, 159, 64, 0.2)';

export function yearColors(idx) {
  const yearColors = [
    redColor,
    greenColor,
    blueColor,
    yellowColor,
    purpleColor,
    orangeColor,

    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
  ];

  return yearColors[idx % yearColors.length];
}

export function yearColorsLine(idx) {
  const yearColorsLine = [
    redColorLine,
    greenColorLine,
    blueColorLine,
    yellowColorLine,
    purpleColorLine,
    orangeColorLine,

    'rgba(255,99,132,0.8)',
    'rgba(54, 162, 235, 0.8)',
    'rgba(255, 206, 86, 0.8)',
    'rgba(75, 192, 192, 0.8)',
    'rgba(153, 102, 255, 0.8)',
    'rgba(255, 159, 64, 0.8)',
  ];

  return yearColorsLine[idx % yearColorsLine.length];
}


export function positiveYearColors(idx) {
  const yearColors = [
    greenColor,
    blueColor,
    yellowColor,
    purpleColor,
    orangeColor,

    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
  ];

  return yearColors[idx % yearColors.length];
}

export function positiveYearColorsLine(idx) {
  const yearColorsLine = [
    greenColorLine,
    blueColorLine,
    yellowColorLine,
    purpleColorLine,
    orangeColorLine,

    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
  ];

  return yearColorsLine[idx % yearColorsLine.length];
}



// export const greenRedColors = [
//   greenColor, // är blå
//   redColor,
//   blueColor, // också blå
//   yellowColor,
// ];
//
// export const greenRedColorsLine = [
//   greenColorLine,
//   redColorLine,
//   blueColorLine,
//   yellowColorLine,
// ];


export function renderTitleWithShowHideButton(title, buttonText, callBackOnClicked) {
  return (
    <div className="underlineRow space-above-tiny space-under-tiny" key={title}>
      <div className="buttonRowLCR">
        <div className="caption-normal b">
          {title}
        </div>

        <div/>

        <div className="generic-button-small" onClick={callBackOnClicked}>
          {buttonText}
        </div>
      </div>
    </div>
  );
}

export function renderTitle(title) {
  return (
    <div className="underlineRow space-above-tiny space-under-tiny" key={title}>
      <div className="caption-normal b">
        {title}
      </div>
    </div>
  );
}

export function renderSubTitle(subTitle) {
  return (
    <div className="space-above-small space-under-small center-text" key={subTitle}>
      <div className="caption-small smallcaps">
        {subTitle}
      </div>
    </div>
  );
}

export function renderDescription(description) {
  return (
    <div className="space-under-medium" key={description}>
      <div className="p-small">
        {description}
      </div>
    </div>
  );
}
