import React, { Component } from 'react';

class UnsupportedBrowser extends Component {
  //<div className="full-height">
  render() {
    return (
      <div className="full-height">

        <div className="space-above-big space-under-medium center-text">
          <div className="caption-huge">
            Mina bigårdar på webben fungerar för<br/>
            närvarande endast med webbläsarna<br/>
            Chrome och Safari<br/>
          </div>
        </div>

        <div className="center-box">
          <div className="box-item-w60">
            <div className="p center-text">
              Safari finns förinstallerat på Mac och behöver inte laddas ner.
              <br />
              <br />
              <a href="https://www.google.com/chrome/" target="blank">Klicka här för att ladda ner Chrome kostnadsfritt för Mac eller Windows.</a><br/>
              <br />
              <br />
              <br />
              <br />
            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default UnsupportedBrowser;
