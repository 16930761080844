import React, { Component } from 'react';
import './../css//HiveDetailsPage.css';
import * as Constants from './Constants';
import PageHeader from './PageHeader';
import HiveItem from './HiveItem';
import { Link } from 'react-router-dom';

class HiveDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      hiveRecord: this.props.hiveRecord,
    };
  }

  renderButtons() {
    return (
      <div className="buttonRowLCR">
        <Link to={'/' + Constants.allHivesPageId}>
          <div className="generic-button">
            <div className="caption-small">Visa alla samhällen</div>
          </div>
        </Link>

        <div/>

        <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
          <div className="caption-small">Skriv ut</div>
        </div>
      </div>
    );
  }

  render() {
    const {title, hiveRecord} = this.state;

    return (
      <div className="HiveDetailsPage">
        <PageHeader title={title} hidePrintButton={true}/>

        {this.renderButtons()}

        <div className="space-above-medium" key={title + new Date()}>
          <HiveItem
            hiveRecord={hiveRecord}
            key={hiveRecord}
            showHiveLinkButton={false}
            autoExpandCard={true} />
        </div>
      </div>
    );

  }
}

export default HiveDetailsPage;
