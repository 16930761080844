import React, { Component } from 'react';
import './../../css/UserGuideSubPage.css';
import * as UserGuideSubPageContents from './UserGuideSubPageContents';

class UserGuideSubPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subPageIndex: this.props.subPageIndex,
    };
  }

  renderTopic(topic) {

    const renderImg = (topic) => {
      if (topic.icon) {
        return (
          <div>
            <img
              className="UserGuideSubPage-2cell-img"
              src={topic.icon} alt={topic.title} />
          </div>
        );
      }

      return null;
    };

    const renderSubTopics = (subTopics) => {
      var a = [];
      for (var i = 0; i < subTopics.length; i++) {
        const s = subTopics[i];
        a.push(
          <div className="UserGuideSubPage-main" key={s.title + i}>
            <div className="caption-medium space-above-small space-under-small">{s.title}</div>
            <div className="p-small">{s.text}</div>
          </div>
        );
      }

      return a;
    }

    var allContents = [];

    allContents.push(
      <div className="UserGuideSubPage-2cell" key={'UserGuideSubPage-2cell' + new Date()}>
        <div className="UserGuideSubPage-2cell-imgcell">
          {renderImg(topic)}
        </div>
        <div className="UserGuideSubPage-subTopics">
          <div className="underlineRow center-text caption-normal space-above-medium">{topic.topicTitle}</div>
          {renderSubTopics(topic.subTopics)}
        </div>
      </div>
    );

    return allContents;
  }

  renderAllTopics() {
    const {subPageIndex} = this.state;
    var allTopics = [];

    const subPage = UserGuideSubPageContents.subPages[subPageIndex];

    allTopics.push(
      <div className="left-text space-under-medium space-above-medium" key={'title' + new Date()}>
        <div className="caption-big">{subPage.title}</div>
      </div>
    );

    const topics = subPage.topics;
    for (var i = 0; i < topics.length; i++) {
      allTopics.push(this.renderTopic(topics[i]));
    }
    return allTopics;
  }

  render() {
    return (
      <div className="UserGuideSubPage" key={'UserGuideSubPage' + new Date()}>
        {this.renderAllTopics()}
      </div>
    );
  }
}

export default UserGuideSubPage;
