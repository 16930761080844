// TODO: catch på alla promisses
// TODO: retry på alla fetch(), inklusive bilduppladdning
// TODO: gör en baseUrl

// när alla bilder är uppladdade -> visa alert

// Process:
// - avsluta med att sätta migrering done i SIMPLY + skicka email med bekräftelse migrering genomförd

import React, { Component } from 'react';
import '../css/DownloadFileButton.css';
import CKUtil from './CKUtil';

class MigrateToMyApiaryJournalButton extends Component {
  static ckRecordNameToIntIdMappings = [];

  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      email: this.props.email,
      userId: this.props.userId,
      authKey: this.props.authKey,
      password: this.props.password,
      groups: this.props.groups,
      hives: this.props.hives,
    };
  }


// ====================================================================================================
// Generic functions
// ====================================================================================================
  arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }


// ====================================================================================================
// uploadImage
// ====================================================================================================
  uploadImage(urlImage, fileName) {
    const {userId, authKey} = this.state;

    return new Promise((resolve, reject) => {
      fetch(urlImage)
        .then(response => response.arrayBuffer())
        .then(imageFileB64Data => {
          const urlImageUpload = 'https://www.minabigardar.se/php/v1/uploadImage.php';
          //console.log("Calling", urlImageUpload, fileName);
          fetch(urlImageUpload, { 
            method: 'POST',
            body: JSON.stringify({
              userId: this.userId,
              authKey: this.authKey,
              image_filename: fileName,
              image_b64_data: this.arrayBufferToBase64(imageFileB64Data)
            })
          })
          .then(data=>{
            return data.json();
          })
          .then(result=>{
            if (result.errorCode == 0) {
              resolve("UPLOADED");
            } else if (result.errorCode == 113) {
              resolve("ALREADY UPLOADED");
            } else {
              //console.log("Image uploaded failed:", result, fileName);
              reject(result);
            }
          });
        });
      });
  }


// ====================================================================================================
// groupsPayload()
// ====================================================================================================
groupsPayload() {
  const {groups} = this.state;
  var payload = [];

  for (var idx in groups.private) {
    const group = groups.private[idx];
    const hivePayload = {
      ck_id: group.recordName, // CloudKit recordName to use for primary key id mapping
      comments: group.fields.description.value,
      name: group.fields.name.value,
      shortname: group.fields.shortName.value,
      locationLat: group.fields.location.value.latitude,
      locationLong: group.fields.location.value.longitude,
      temporaryLocation: group.fields.isTemporaryLocation.value,
      imageFilename: group.recordName + ".png",
    };
    payload.push(hivePayload);
  }

  return payload;
}


// ====================================================================================================
// hivesPayload()
// ====================================================================================================
hivesPayload() {
  const {hives} = this.state;
  var payload = [];

  for (var idx in hives.private) {
    const hive = hives.private[idx];
    let locations = hive.fields.sortOrder.value.split(":");
    const hivePayload = {
      ck_id: hive.recordName, // CloudKit recordName to use for primary key id mapping
      comments: hive.fields.description.value,
      name: hive.fields.name.value,
      hiveTypeId: hive.fields.hiveTypeIndex.value,
      frameTypeId: hive.fields.frameTypeIndex.value,
      harvestFrameTypeId: hive.fields.harvestFrameTypeIndex.value,
      ck_groupId: hive.fields.groupRef.value.recordName, // CloudKit recordName to use for primary key id mapping
      primaryLocation: locations[0],
      secondaryLocation: locations[1],
      imageFilename: hive.recordName + ".png",

      wingCut: hive.fields.queenWingCut.value,
      year: hive.fields.queenYear.value,
      raceId: hive.fields.queenRaceIndex.value,
    };
    payload.push(hivePayload);
  }

  return payload;
}


// ====================================================================================================
// settingsPayload()
// ====================================================================================================
settingsPayload(settings) {
  var payload = [];
  //console.log("Settings", settings);
  for (var idx in settings) {
    const setting = settings[idx];
    const settingPayload = {
      key: setting.fields.key.value,
      value: setting.fields.value.value,
    };
    payload.push(settingPayload);
  }
  return payload;
}


// ====================================================================================================
// contactsPayload()
// ====================================================================================================
contactsPayload(contacts) {
  const {groups} = this.state;
  var payload = [];

  for (var idx in contacts) {
    const contact = contacts[idx];
    const contactsPayload = {
      address: contact.fields.address1.value + ", " + contact.fields.address1.value + ", " + contact.fields.zipNumber.value + ", " + contact.fields.city.value,
      phone: contact.fields.phone.value,
      email: contact.fields.email.value,
      ck_associatedGroupId: contact.fields.groupRef.value.recordName, // Replace serverside. Use this entitys ID for lookup
    };
    payload.push(contactsPayload);
  }

  return payload;
}


// ====================================================================================================
// harvestSessionsPayload()
// ====================================================================================================
harvestSessionsPayload(sessions) {
  var payload = [];

//  console.log("SESSIONS:", sessions);
  for (var idx in sessions) {
    const session = sessions[idx];
    const amountValueInGrams = session.fields.amountUnit.value == 0 ? session.fields.amountValue.value : session.fields.amountValue.value * 1000;

    // TODO: bättre skicka json? {sessions:[{hiveId:..., frameTypeId: ..., count: ...}, ...]}
    // const ck_framesInSessionJson = ...

    const sessionsPayload = {
      "amountInGrams": amountValueInGrams,
      "batchId": session.fields.batchId.value,
      "comments": session.fields.description.value,
      "completedDateTime": new Date(session.fields.finishedDate.value).toLocaleString('sv-SE', { timeZone: 'UTC' }),
      "ck_framesInSession": session.fields.framesInSession.value,
      "harvestTypeId": session.fields.harvestType.value,
    };
    payload.push(sessionsPayload);
  }

  return payload;
}


// ====================================================================================================
// eventsPayload()
// ====================================================================================================
eventsPayload(events) {
  var payload = [];

  //console.log("Events:", events);
  for (var idx in events) {
    const event = events[idx];
    //console.log("E", idx, event);
    
    var gID = "";
    if (event.fields.groupRef != undefined) {
      gID = event.fields.groupRef.value.recordName;
    }

    var hID = "";
    if (event.fields.hiveRef != undefined) {
      hID = event.fields.hiveRef.value.recordName;
    }

    const eventsPayload = {
      "date": new Date(event.fields.date.value).toLocaleString('sv-SE', { timeZone: 'UTC' }),
      "ck_hiveId": hID ?? "",
      "ck_groupId": gID ?? "",
      "droneFrameCutOutTypeIdNumber": event.fields.droneFrameCutOutTypeIdNumber.value,
      "typeId": event.fields.typeId.value,
      "temperamentTypeIdNumber": event.fields.temperamentTypeIdNumber.value,
      "slungatKg": event.fields.slungatKg.value,
      "queenObserved": event.fields.queenObserved.value,
      "queenCellsVisible": event.fields.queenCellsVisible.value,
      "numberOfBroodFrames": event.fields.numberOfBroodFrames.value,
      "notes": event.fields.notes.value,
      "medicationTypeIdNumber": event.fields.medicationTypeIdNumber.value,
      "larvasVisible": event.fields.larvasVisible.value,
      "hiveStrength": event.fields.hiveStrength.value,
      "hiveDeathTypeIdNumber": event.fields.hiveDeathTypeIdNumber.value,
      "harvestBatchId": event.fields.harvestBatchId.value,
      "foodIsAvailable": event.fields.foodIsAvailable.value,
      "feedingTypeIdNumber": event.fields.feedingTypeIdNumber.value,
      "expansionAllowed": event.fields.expansionAllowed.value,
      "eggVisible": event.fields.eggVisible.value,
    };
    payload.push(eventsPayload);
  }

  return payload;
}

// ====================================================================================================
// uploadAsTransaction()
// ====================================================================================================
uploadAsTransaction(payload) {
  const {userId, authKey} = this.state;

  // On failure, retry the call after a delay
  var retryCount = 3;

  const body = JSON.stringify({
    userId: this.userId,
    authKey: this.authKey,
    payload: payload,
  });

  return new Promise((resolve, reject) => {

    function tryMigrate(body) {
      const url = 'https://www.minabigardar.se/php/v1/migrate.php';
      console.log("Calling", url, body);

      function retryOrReject(error) {
        if (retryCount > 0) {
          const delay = parseInt(Math.random() * 8000 + 2000); // 2..10 sec
          console.log(url, "Retrying after", delay);

          retryCount = retryCount - 1;

          setTimeout(function(){
            tryMigrate(body);
          }, delay);
        } else {
          console.log(url, "Too many retry attempts, rejecting");
          reject(error);
        }    
      }

      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Methods': 'POST GET',
          'Access-Control-Allow-Headers': 'Content-Type',
        },
        body: body,
      })
      .then(data=>{
        const json = data.json()
        //console.log("Migration result", json);
        return json;
      })
      .then(result=>{
        if (result.errorCode == 0) {
          console.log(url, "Transaction completed", result);
          resolve(result);
        } else {
          console.log(url, "Transaction failed");
          retryOrReject(result);
        }
      })
      .catch(error=>{
        console.log(url, "Transaction failed (exception)", error);
        retryOrReject(error);
      });
    }

    tryMigrate(body);
  });
}

ul(url, filename, delay) {
  console.log("Uploading image in " + delay + " msec...", filename);
  
  setTimeout(() => {
    this.uploadImage(url, filename)
      .then(result => {
        console.log("Uploaded image (" + result + ")", filename);
      })
      .catch(err => {
        console.log("Upload image failed", filename, err);
      });
    }, delay);
}

uploadGroupAndHiveImages() {
  const {groups, hives} = this.state;
  
  var delay = 0;
  const offset = 200;

  for (var idx in hives.private) {
    const hive = hives.private[idx];
    this.ul(hive.fields.thumbnail.value.downloadURL, hive.recordName + ".png", delay);
    delay += offset;
  }

  for (var idx in groups.private) {
    const group = groups.private[idx];
    this.ul(group.fields.thumbnail.value.downloadURL, group.recordName + ".png", delay);
    delay += offset;
  }
}


// ====================================================================================================
// startMigrationUploadData()
// ====================================================================================================
startMigrationUploadData(payload) {
  console.log("startMigrationUploadData", payload);

  return new Promise((resolve, reject) => {
  // 2. Upload as transaction
  this.uploadAsTransaction(payload)
    .then(result=>{
      //console.log("startMigrationUploadData OK", result);
      resolve(result);

      // this.uploadGroupAndHiveImages(); // !!!!! This will start AFTER resolve, and will not be part of the transaction!
    })
    .catch(error=>{
      reject(error);
    });

  });
}


// ====================================================================================================
// startMigrationGatherData()
// ====================================================================================================
startMigrationGatherData() {
    const {groups, hives} = this.state;
    console.log("startMigrationGatherData", groups.private, hives.private);

    // The payloads dictionary
    var payload = [];

    // 1. Gather all data to upload
    const g = this.groupsPayload();
    const h = this.hivesPayload();

    const ck = window.ckUtilInstance;
    const db = ck.privateDb();

    ck.loadContacts(db)
      .then((contacts) => {
        const c = this.contactsPayload(contacts);

        ck.loadHarvestSessions(db)
          .then((sessions) => {
            const hs = this.harvestSessionsPayload(sessions);

            ck.loadAllEvents(db)
              .then((events) => {
                const e = this.eventsPayload(events);

                ck.loadAllSettings(db)
                  .then((settings) => {
                    const s = this.settingsPayload(settings);

                    this.startMigrationUploadData({
                      groups: g,
                      hives: h,
                      contacts: c,
                      harvestSessions: hs,
                      events: e,
                      settings: s,
                    })
                    .then(result=>{
                      console.log("startMigrationUploadData OK", result);
    
                      this.setMigrationDone()
                        .then(resultMigrationDone => {
                          console.log("setMigratinDone OK", resultMigrationDone);

                          alert("Migration completed. Will now upload images...");
                          
                          // 
                          // Start uploading images after the transaction has completed.
                          //
                          this.uploadGroupAndHiveImages();
                        })
                        .catch(error => {
                          console.log("setMigratinDone failed", error);
                        });

                    })
                    .catch(error=>{
                      console.log("startTransferingData failed", error);
                    });
                  });
      
                });

          });
  
      });
  }

setMigrationDone() {
  const {userId, authKey} = this.state;
  return new Promise((resolve, reject) => {
    const url = 'https://www.minabigardar.se/php/v1/setSetting.php';
    console.log('Calling:', url);
  
    const body = JSON.stringify({
      userId: this.userId,
      authKey: this.authKey,
      settingKey: 'CloudKitMigrationCompleted',
      settingValue: '1',
    });
  
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods': 'POST GET',
        'Access-Control-Allow-Headers': 'Content-Type',  
      },
      body: body,
    })
    .then(data=>{
      //console.log(url, data);
      resolve(data.json());
    })
    .catch(err => {
      reject(err)
    });
  
  });
}


// ====================================================================================================
// prepareForMigrationFromCKToSimply()
// ====================================================================================================
  prepareForMigrationFromCKToSimply(email, password) {
    const {userId, authKey} = this.state;
    //console.log("--------[ migrateAllImagesFromCKToSimply ]-----------", email, password);

    if (email.length == 0 || password.length == 0) {
      alert("Invalid email or password!");
      return;
    }

    // LOGIN
    const urlLogin = 'https://www.minabigardar.se/php/v1/login.php?email=' + email + "&password=" + password;
    console.log("Calling", urlLogin);
    fetch(urlLogin, { 
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods': 'POST GET',
        'Access-Control-Allow-Headers': 'Content-Type',
      },
    })
    .then(dataLogin=>{
      //console.log("Login response:", dataLogin.status, dataLogin);
      return dataLogin.json();
    })
    .then(resLogin=>{
      //console.log("X Login response:", resLogin, resLogin.errorCode, resLogin.data);
      if (resLogin != null) {
        this.userId = resLogin["userId"];
        this.authKey = resLogin["authKey"];

        const url = 'https://www.minabigardar.se/php/v1/getSetting.php';
        console.log('Calling:', url);

        const body = JSON.stringify({
          userId: this.userId,
          authKey: this.authKey,
          settingKey: 'CloudKitMigrationCompleted',
        });
      
        fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'POST GET',
            'Access-Control-Allow-Headers': 'Content-Type',  
          },
          body: body,
        })
        .then(data=>{
          //console.log(url, "data", data);
          return data.json();
        })
        .then(res=>{
          if (res.errorCode == 0/*success*/ || res.errorCode == 100/*no such key*/) {
            const settingValue = res.data[0];
            //console.log("MIGRATION SETTING:", settingValue, res);
            if (settingValue.length > 0) { // If valid date, the a year will be available here, else not
              console.log(url, "Already migrated:", settingValue);
              alert("Already migrated!");
            } else {
              console.log(url, "OK TO MIGRATE:", res, settingValue.length);
              this.startMigrationGatherData();
            }
          } else {
            console.log(url, "Auth failed", res);
          }
        })
        .catch(err => { throw err });
      } else {
        console.log("LOGIN FAILED:", resLogin);
      }      
    })
    .catch(err => { throw err });
  }


// ====================================================================================================
// buttonTapped()
// ====================================================================================================
  buttonTapped() {
    var email = prompt("Email", "rdahl@mac.com");
    var password = prompt("Lösenord", "password");

    this.email = email;
    this.password = password;

    if (email != undefined && password != undefined) {
      this.prepareForMigrationFromCKToSimply(this.email, this.password);
    } else {
      console.log("Invalid email or password");
    }
  }

  render() {
    const {title, email, password} = this.state;
    return (
        <div className="generic-button" onClick={() => this.buttonTapped()}>
          <div className="caption-small">{title}</div>
        </div>
    );
  }
}

export default MigrateToMyApiaryJournalButton;
