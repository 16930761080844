import React, { Component } from 'react';
import './../css/UnderlinedIdAndCaptionSeparator.css';
import LinkIcon from './LinkIcon';

class UnderlinedIdAndCaptionSeparator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      record: this.props.record,
      link: this.props.link, // if not null, defines a link
    };
  }

  renderBadgeShortNameAndTitle(fields, link) {
    function badge() {
      if (fields.shortName !== undefined && fields.shortName.value !== "") {
        return (
          <div className="idBadge">
            <div className="caption-small">{fields.shortName.value}</div>
          </div>
        );
      }
      else {
        return null;
      }
    }

    function renderLink(link) {
      if (link) {
        return (
          <LinkIcon linkArgument={link} whiteIcon={false} />
        );
      }
      else {
        return (<div/>);
      }
    }

    return (
      <div className="buttonRowLR">
        <div>
          {badge()}
          <div className="caption-normal">{fields.name.value}</div>
        </div>
        {renderLink(link)}
      </div>
    );
  }

  render() {
    const {record, link} = this.state;
    return (
      <div className="UnderlinedIdAndCaptionSeparator underlineRow left-text space-above-medium">
        {this.renderBadgeShortNameAndTitle(record.fields, link)}
      </div>
    );
  }
}

export default UnderlinedIdAndCaptionSeparator;
