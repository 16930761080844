import React, { Component } from 'react';
import './../css//GroupPage.css';
import GroupsItem from './GroupsItem';
import SegmentControllerItem from './SegmentControllerItem';
import PageHeader from './PageHeader';

class GroupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      presentationMineOrSubscribed: this.props.presentationMineOrSubscribed, // 0: mine, 1: subscribed
      zones: this.props.zones, // {private, shared} where shared is an array
      groups: this.props.groups, // {private, shared}
      hives: this.props.hives, // {private, shared}
    };
  }

  renderZones() {
    const {groups, hives, presentationMineOrSubscribed} = this.state;

    function msg(isPrivate) {
      if (isPrivate) {
        return "Du är har inga registrerade bigårdar";
      }
      else {
        return "Du är inte inbjuden att dela några bigårdar";
      }
    }

    const getGroupsItem = (g, h, isPrivate) => {
      if (g.length === 0) {
        return (
          <div className="p-normal center-text space-above-medium">
            {msg(isPrivate)}
          </div>
        );
      }
      else {
        return (
          <GroupsItem
            groups={g}
            hives={h}
            key={'gp' + new Date()} />
        );
      }
    }

    if (presentationMineOrSubscribed === 0) {
      return getGroupsItem(groups.private, hives.private, true);
    }
    else {
      return getGroupsItem(groups.shared, hives.shared, false);
    }
  }

  // NOTES: Denna visar en segment controller, men bara om det finns subscribed groups.
  renderOwnedAndSharedSegment() {
    const {groups, presentationMineOrSubscribed} = this.state;
    const existSubscribedGroups = groups.shared !== null;

    if (existSubscribedGroups) {
      const setPresentationToMine = () => {
        this.setState({presentationMineOrSubscribed: 0});
      }

      const setPresentationToSubscribed = () => {
        this.setState({presentationMineOrSubscribed: 1});
      }

      // return (
      //   <div className="GroupsItem">
      //     <div className="GroupsItem-header">
      //
      //       <div className="navbar">
      //         <div className="dropdown">
      //           <button className="dropbtn">Bigårdar ▼
      //             <i className="fa fa-caret-down"></i>
      //           </button>
      //           <div className="dropdown-content">
      //             {this.renderAllGroupLinks()}
      //           </div>
      //         </div>
      //       </div>
      //
      //     </div>
      //     <div className="GroupsItem-content">
      //       {this.renderGroups()}
      //     </div>
      //   </div>
      // );

      // <div>
      //   <Link to={'/' + Constants.allGroupsPageId}>
      //     <div className="generic-button">
      //       <div className="caption-small">Visa alla bigårdar</div>
      //     </div>
      //   </Link>
      // </div>

// console.log("EFFEKTIVISERA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
//       function renderAllGroupLinks() {
//         var links = [];
//         for (var i = 0; i < groups.private.length; i++) {
//           const record = groups.private[i];
//           const rName = record.fields.name.value;
//           const rId = record.recordName;
//           links.push(
//             <a className="dropbtn" key={rId+i} href={'/' + Constants.groupDetailsPageId + '?id=' + rId}>{rName}</a>
//           );
//         }
//
//         for (var s = 0; s < groups.shared.length; s++) {
//           const record = groups.shared[s];
//           const rName = record.fields.name.value;
//           const rId = record.recordName;
//           links.push(
//             <a className="dropbtn" key={rId+i} href={'/' + Constants.groupDetailsPageId + '?id=' + rId}>{rName}</a>
//           );
//         }
//
//         return links;
//       }

      function renderGroupSelection() {
        return (<div/>);
        // <div class="dropdown">
        //   <button onclick="myFunction()" class="dropbtn">BIGÅRDAR</button>
        //   <div id="myDropdown" class="dropdown-content">
        //     {renderAllGroupLinks()}
        //   </div>
        // </div>
      }

      return (
        <div className="buttonRowLCR">
          {renderGroupSelection()}

          <SegmentControllerItem
            titles={['Mina', 'Delade']}
            callbacks={[setPresentationToMine, setPresentationToSubscribed]}
            selectedIndex={presentationMineOrSubscribed}
            key={'sc' + new Date()} />

            <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
              <div className="caption-small">Skriv ut</div>
            </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  render() {
    const {title} = this.state;
    return (
      <div className="GroupPage">
        <PageHeader title={title} hidePrintButton={true} />

        {this.renderOwnedAndSharedSegment()}

        <div className="">
          {this.renderZones()}
        </div>
      </div>
    );
  }
}

export default GroupPage;
