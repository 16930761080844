import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import * as Constants from './Constants';
import '../css/SideBySideArea.css';
import OverviewPage from './OverviewPage';
import GroupPage from './GroupPage';
import GroupDetailsPage from './GroupDetailsPage';
import HivePage from './HivePage';
import HiveDetailsPage from './HiveDetailsPage';
import StatisticsPage from './StatisticsPage';
import UserGuidePage from './UserGuidePage';
import ContactPage from './ContactPage';
import NewsletterPage from './NewsletterPage';
import ToolsPage from './ToolsPage';
import LoadingIndicator from './LoadingIndicator';
import InfoInitByUsingApp from './InfoInitByUsingApp';

class SideBySideArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zones: null, // {private, shared} where shared is an array of zones (since there is one shared zone per person who shares something)
      groups: null, // {private, shared} where shared is a concaternation of all groups in all shared zones
      hives: null, // {private, shared} where shared is a concaternation of all hives in all groups in all shared zones
      hasTriedToLoadZones: false, //
    };
  }

  componentDidMount() {
    const ck = window.ckUtilInstance;

    Promise.all([
      ck.loadPrivateZones(),
      ck.loadSharedZones()
    ]).then((zones) => {
      const privateZone = zones[0].length > 0 ? zones[0][0].zoneID : null;

      var sharedZones = [];
      if (zones.length > 0) {
        const sharedZ = zones[1];
        if (sharedZ) {
          for (var i=0; i<sharedZ.length; i++) {
            sharedZones.push(sharedZ[i].zoneID);
          }
        }
      }

      if (privateZone === null || privateZone.length === 0) {
        const msg = "ERROR: Found no private zones | " + Constants.defaultZoneName + " | " + JSON.stringify(ck.getUserInfo());
        console.log(msg);
        alert(msg);
        this.setState({
          hasTriedToLoadZones: true,
        });
        return;
      }
      else {
        console.log('Zone (private)', privateZone);
        console.log('Zones (shared)', sharedZones);
      }

      // GROUPS ------------

      var zonesPromises = [];
      zonesPromises.push(ck.loadGroupsInZone(ck.privateDb(), privateZone));

      for (var j=0; j<sharedZones.length; j++) {
        zonesPromises.push(ck.loadGroupsInZone(ck.sharedDb(), sharedZones[j]));
      }


      Promise.all(zonesPromises).then((groups) => {
        if (groups) {
          console.log("Got groups", groups);
        }
        else {
          console.log("Got no groups in any zone");
          alert("ERROR: Got no groups in any zone");
          return;
        }


        // HIVES ------------------

        var promisesPrivate = [];
        const privateGroupsIncludingShared = groups[0];
        var privateGroups = [];

        // console.log('Groups (private, before filtering out shared)', privateGroupsIncludingShared);

        // NOTE: Private DB includes the shared, but we want to have them separated
        // so we remove them from private here.
        if (groups.length > 1) {
          const sharedGroups = groups[1];
          if (sharedGroups !== null) {
            for (var ipp=0; ipp<privateGroupsIncludingShared.length; ipp++) {
              const pg = privateGroupsIncludingShared[ipp];
              var found = false;
              for (var iss=0; iss<sharedGroups.length; iss++) {
                const s = sharedGroups[iss];
                // console.log('comp', s, pg);
                if (s.recordName === pg.recordName) {
                  found = true;
                }
              }

              if (!found) {
                privateGroups.push(pg);
              }
            }
          }
          else {
            privateGroups = privateGroupsIncludingShared;
          }
        }
        else {
          privateGroups = privateGroupsIncludingShared;
        }

        // console.log('Groups (private, after filtering out shared)', privateGroups);
        for (var ip = 0; ip < privateGroups.length; ip++) {
          promisesPrivate.push(ck.loadHivesInGroup(ck.privateDb(), privateGroups[ip]));
        }

        var promisesShared = [];
        if (groups.length > 1) {
          for (var k=1; k<groups.length; k++) {
            const groupK = groups[k];
            console.log('Groups (shared[*])', k, groupK);
            for (var is = 0; is < groupK.length; is++) {
              promisesShared.push(ck.loadHivesInGroup(ck.sharedDb(), groupK[is]));
            }
          }
        }

        function hivesFromGroupAndHivesArray(hives) {
          // här får jag en [groups][hives]  som sk retuneras som [hives]
          var flatArray = [];
          for (var i = 0; i < hives.length; i++) {
            for (var j = 0; j < hives[i].length; j++) {
              flatArray.push(hives[i][j]);
            }
          }
          return flatArray;
        }

        const pP = Promise.all(promisesPrivate).then((hives) => {
          return hivesFromGroupAndHivesArray(hives);
        });

        var pS = [];
        pS = Promise.all(promisesShared).then((hives) => {
          return hivesFromGroupAndHivesArray(hives);
        });

        Promise.all([pP, pS]).then((hives) => {
          const privateHives = hives[0];
          const sharedHives = hives[1];

          console.log('Hives (private)', privateHives);
          console.log('Hives (shared)', sharedHives);

          var allSharedGroups = [];
          if (groups.length > 1) {
            for (var i=1; i<groups.length; i++) {
              allSharedGroups = allSharedGroups.concat(groups[i]);
            }
          }

          function sortHiveOrGroupAlphatetically(a, b) {
            return a.fields.name.value.localeCompare(b.fields.name.value);
          }

          this.setState({
            hives: {
              private: privateHives.sort(sortHiveOrGroupAlphatetically),
              shared: sharedHives.sort(sortHiveOrGroupAlphatetically),
            },
            groups: {
              private: privateGroups.sort(sortHiveOrGroupAlphatetically),
              shared: allSharedGroups.sort(sortHiveOrGroupAlphatetically),
            },
            zones: {
              private: privateZone === null ? [] : privateZone,
              shared: sharedZones === null ? [] : sharedZones,
            },
            hasTriedToLoadZones: true,
          });
        });
      });
    });
  }

  getSideItems() {
    // NOTE: Do not include hive or group details here!
    const sidePanelItems = [
      {title: Constants.overviewPageTitle(), pageId: Constants.overviewPageId},
      {title: Constants.allGroupsPageTitle(), pageId: Constants.allGroupsPageId},
      {title: Constants.allHivesPageTitle(), pageId: Constants.allHivesPageId},
      {title: Constants.statisticsPageTitle(), pageId: Constants.statisticsPageId},
      {title: Constants.userGuidePageTitle(), pageId: Constants.userGuidePageId},
      {title: Constants.toolsPageTitle(), pageId: Constants.toolsPageId},
      {title: Constants.contactPageTitle(), pageId: Constants.contactPageId},
      //{title: Constants.newsletterPageTitle(), pageId: Constants.newsletterPageId},
    ];

    var items = [];
    for (var i = 0; i < sidePanelItems.length; i++) {
      const sidePanelItem = sidePanelItems[i];
      const pid = sidePanelItem.pageId;
      items.push(
        <div className="SideBySideArea-menuItem" key={'mi'+pid+i}>
          <Link to={'/' + pid} key={'li'+pid+i}>
            <div className="caption-normal">{sidePanelItem.title}</div>
          </Link>
          <br/>
        </div>
      );
    }
    return items;
  }

  getQueryVariable(url, variable) {
    var result = null;

    var query = url.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
        if (pair[0] === variable) {
          result = pair[1];
        }
     }

     return result;
  }

  getThisYear() {
    return (new Date()).getFullYear();
  }

  render() {
    const {zones, groups, hives, hasTriedToLoadZones} = this.state;

    const ck = window.ckUtilInstance;

    // const paramId = this.getQueryVariable(document.location.search, 'id');
    // console.log('paramId', paramId);
    //
    const Overview = () => (
      <OverviewPage
        pageId={Constants.overviewPageId}
        title={Constants.overviewPageTitle()}
        zones={zones}
        groups={groups}
        hives={hives} />
    )

    const Groups = ({ location }) => (
      <GroupPage
        pageId={Constants.allGroupsPageId}
        title={Constants.allGroupsPageTitle()}
        presentationMineOrSubscribed={0}
        zones={zones}
        groups={groups}
        hives={hives} />
    )

    const GroupDetails = ({ location }) => (
      <GroupDetailsPage
        pageId={Constants.groupDetailsPageId}
        title={Constants.groupDetailsPageTitle()}
        groupRecord={ck.getGroupRecord(groups, this.getQueryVariable(location.search, 'id'))}
        hivesInGroup={ck.getHivesInGroup(hives, this.getQueryVariable(location.search, 'id'))} />
    )

    const Hives = ({ location }) => (
      <HivePage
        pageId={Constants.allHivesPageId}
        title={Constants.allHivesPageTitle()}
        presentationMineOrSubscribed={0}
        zones={zones}
        groups={groups}
        hives={hives} />
    )

    const HiveDetails = ({ location }) => (
      <HiveDetailsPage
        pageId={Constants.hiveDetailsPageId}
        title={Constants.hiveDetailsPageTitle()}
        hiveRecord={ck.getHiveRecord(hives, this.getQueryVariable(location.search, 'id'))} />
    )

    const Statistics = () => (
      <StatisticsPage
        pageId={Constants.statisticsPageId}
        title={Constants.statisticsPageTitle()}
        groups={groups}
        allHives={hives} />
    )

    const UserGuide = () => (
        <UserGuidePage
          pageId={Constants.userGuidePageId}
          title={Constants.userGuidePageTitle()} />
    )

    const Tools = ({ location }) => (
      <ToolsPage
        pageId={Constants.toolsPageId}
        title={Constants.toolsPageTitle()}
        //presentationMineOrSubscribed={0}
        zones={zones}
        groups={groups}
        hives={hives} />
    )

    const Contact = () => (
        <ContactPage
          pageId={Constants.contactPageId}
          title={Constants.contactPageTitle()} />
    )

    // const Newsletter = () => (
    //     <NewsletterPage
    //       pageId={Constants.newsletterPageId}
    //       title={Constants.newsletterPageTitle()} />
    // )

    if (hasTriedToLoadZones) {
      if (zones) {
        if (groups) {
          // console.log('----------> Zp:', zones.private);
          // console.log('----------> Zs:', zones.shared);
          // console.log('----------> G:', groups);
          // console.log('----------> H:', hives);

          return (

              <Router>
                <div className="SideBySideArea">
                  <div className="SideBySideArea-MenuArea no-print">
                    {this.getSideItems()}
                  </div>

                  <div className="SideBySideArea-PageArea">
                    <Route exact path="/" component={Overview}/>

                    <Route path={'/' + Constants.overviewPageId} component={Overview}/>
                    <Route path={'/' + Constants.allGroupsPageId} component={Groups}/>
                    <Route path={'/' + Constants.groupDetailsPageId} component={GroupDetails}/>
                    <Route path={'/' + Constants.allHivesPageId} component={Hives}/>
                    <Route path={'/' + Constants.hiveDetailsPageId} component={HiveDetails}/>
                    <Route path={'/' + Constants.statisticsPageId} component={Statistics}/>
                    <Route path={'/' + Constants.userGuidePageId} component={UserGuide}/>
                    <Route path={'/' + Constants.toolsPageId} component={Tools}/>
                    <Route path={'/' + Constants.contactPageId} component={Contact}/>
                  </div>
                </div>
              </Router>

          );
        }
        else {
          return <LoadingIndicator label={'Laddar all data...'}/>;
        }
      }
      else {
        return (<InfoInitByUsingApp />);
      }
    }
    else {
      return <LoadingIndicator label={'Laddar all data...'}/>;
    }
  }
}

export default SideBySideArea;
