import React, { Component } from 'react';
import './../css/ToolsPage.css';
import PageHeader from './PageHeader';
import DownloadFileButton from './DownloadFileButton';
import MigrateToMyApiaryJournalButton from './MigrateToMyApiaryJournalButton';
import LoadingIndicator from './LoadingIndicator';
import * as CSVUtil from './CSVUtil';
import * as EventItemUtil from './EventItemUtil';


// OBS! Dessa får inte innehålla kommatecken!!!
const groupTitles = ['ID', 'Skapat datum', 'Namn', 'Förkortning', 'Temporär uppställning', 'Koordinater (SWEREF84)', 'Anteckningar'];
const hiveTitles = ['ID', 'Skapat datum', 'Namn', 'Tillhör bigård (id för bigård)', 'Ramtyp (index)', 'Kuptyp (index)', 'Drottning: ras (index)', 'Drottning: vingklippt', 'Drottning: år', 'Anteckningar'];
const hiveEventTitles = ['ID', 'Skapat datum', 'Händelsetyp', 'Tillhör samhälle (id för samhälle)', 'Anteckningar', 'Data'];
const groupEventTitles = ['ID', 'Skapat datum', 'Händelsetyp', 'Tillhör bigård (id för bigård)', 'Anteckningar', 'Data'];
const frameTypeTitles = ['ID', 'Namn'];
const hiveTypeTitles = ['ID', 'Namn'];
const queenRaceTitles = ['ID', 'Namn'];
const eventTypeTitles = ['ID', 'Namn'];

class ToolsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      // presentationMineOrSubscribed: this.props.presentationMineOrSubscribed, // 0: mine, 1: subscribed
      zones: this.props.zones, // {private, shared}
      groups: this.props.groups, // {private, shared}
      hives: this.props.hives, // {private, shared}

      hiveEvents: null,
      groupEvents: null,
    };
  }

  componentDidMount() {
    const ck = window.ckUtilInstance;
    ck.loadAllEvents(ck.privateDb()).then((events) => {

      const hiveEvents = events.filter((event) => {
        if (event.fields.hiveRef) {
          return true;
        }
        return false;
      });

      const groupEvents = events.filter((event) => {
        if (event.fields.groupRef) {
          return true;
        }
        return false;
      });

      console.log('ALL EVENTS', hiveEvents, groupEvents);

      this.setState({
        hiveEvents: hiveEvents,
        groupEvents: groupEvents,
      });
    });
  }

  csvSafeFormatString(str) {
    var safeStr = str
      .replace(/\"/g, "'") // = -> ' eftersom hela beskrivningen måste kapslas in i "
      .replace(/;/g, ' | ')
      .replace(/,/g, '/')
      .replace(/\n/g, ' | ');
    if (str !== safeStr) {
      console.log('SAFE', str, safeStr);
      return '"' + safeStr + '"';
    }

    return str // If no modifications
  }

  // // NOTES: Denna visar en segment controller, men bara om det finns subscribed groups.
  // renderOwnedAndSharedSegment() {
  //   const {sharedGroups, presentationMineOrSubscribed} = this.state;
  //   const existSubscribedGroups = sharedGroups !== null;
  //
  //   if (existSubscribedGroups) {
  //     const setPresentationToMine = () => {
  //       this.setState({presentationMineOrSubscribed: 0});
  //     }
  //
  //     const setPresentationToSubscribed = () => {
  //       this.setState({presentationMineOrSubscribed: 1});
  //     }
  //
  //     return (
  //       <div className="buttonRowLCR">
  //         <div />
  //
  //         <SegmentControllerItem
  //           titles={['Mina', 'Delade']}
  //           callbacks={[setPresentationToMine, setPresentationToSubscribed]}
  //           selectedIndex={presentationMineOrSubscribed}
  //           key={'sc' + new Date()} />
  //
  //         <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
  //           <div className="caption-small">Skriv ut</div>
  //         </div>
  //       </div>
  //     );
  //   }
  //   else {
  //     return null;
  //   }
  // }



  // ---------------------------------------------------------------------------
  // Get CSV data
  // ---------------------------------------------------------------------------

  // The all-data file contains comments (non standard CSV)
  allDataAsCSVString() {
    const g = this.groupDataAsCSVString();
    const h = this.hiveDataAsCSVString();
    const he = this.hiveEventDataAsCSVString();
    const ge = this.groupEventDataAsCSVString();
    const frameType = this.frameTypeDataAsCSVString();
    const hiveType = this.hiveTypeDataAsCSVString();
    const queenRace = this.queenRaceDataAsCSVString();
    const eventTypes = this.eventTypeDataAsCSVString();

    // här ska events och harvestsessions också finnas med

    return 'Den här filen skapades av Mina bigårdar på webben ' +
      new Date().toLocaleDateString() + '.\n' +
      '\n' +
      '\n' +
      '[BIGÅRDAR]\n' + g + '\n' +
      '\n' +
      '[SAMHÄLLEN]\n' + h + '\n' +
      '\n' +
      '[BIGÅRDHÄNDELSER]\n' + ge + '\n' +
      '\n' +
      '[HÄNDELSER]\n' + he + '\n' +
      '\n' +
      '[RAMFORMAT]\n' + frameType + '\n' +
      '\n' +
      '[KUPTYPER]\n' + hiveType + '\n' +
      '\n' +
      '[DROTTNINGRASER]\n' + queenRace + '\n' +
      '\n' +
      '[HÄNDELSETYPER]\n' + eventTypes + '\n';
  }

  groupDataAsCSVString() {
    return CSVUtil.csvStringFromArray(groupTitles, this.groupDataRows());
  }

  hiveDataAsCSVString() {
    return CSVUtil.csvStringFromArray(hiveTitles, this.hiveDataRows());
  }

  hiveEventDataAsCSVString() {
    return CSVUtil.csvStringFromArray(hiveEventTitles, this.hiveEventDataRows());
  }

  groupEventDataAsCSVString() {
    return CSVUtil.csvStringFromArray(groupEventTitles, this.groupEventDataRows());
  }

  frameTypeDataAsCSVString() {
    return CSVUtil.csvStringFromArray(frameTypeTitles, this.frameTypeDataRows());
  }

  hiveTypeDataAsCSVString() {
    return CSVUtil.csvStringFromArray(hiveTypeTitles, this.hiveTypeDataRows());
  }

  queenRaceDataAsCSVString() {
    return CSVUtil.csvStringFromArray(queenRaceTitles, this.queenRaceDataRows());
  }

  eventTypeDataAsCSVString() {
    return CSVUtil.csvStringFromArray(eventTypeTitles, this.eventTypeDataRows());
  }


  // ---------------------------------------------------------------------------
  // Get data rows
  // The param forPrivateDb decides if to go for the private or shared db
  // ---------------------------------------------------------------------------

  groupDataRows(forPrivateDb = true) {
    const {groups} = this.state;
    var rows = [];
    const records = forPrivateDb ? groups.private : groups.shared;
    for (var i=0; i<records.length; i++) {
      const record = records[i];

      const fields = record.fields;

      const location = fields.location.value;
      const locationStr = location === null ? "" : location.latitude + "/" + location.longitude;

      const description = "\"" + fields.description.value + "\"";

      // Finns inte på grupp ännu...
      var cd = "n/a";
      if (fields.createdDate) {
        cd = new Date(fields.createdDate.value).toLocaleDateString();
      }

      const row = [
        record.recordName,
        cd,
        fields.name.value,
        fields.shortName.value,
        fields.isTemporaryLocation.value,
        locationStr,
        this.csvSafeFormatString(description),
      ];
      rows.push(row);
    }
    return rows;
  }

  hiveDataRows(forPrivateDb = true) {
    const {hives} = this.state;
    var rows = [];
    const records = forPrivateDb ? hives.private : hives.shared;
    for (var i=0; i<records.length; i++) {
      const record = records[i];
      const fields = record.fields;

      const gRef = fields.groupRef;
      const groupId = gRef ? gRef.value.recordName : 'n/a';

      const description = "\"" + fields.description.value + "\"";

      var cd = "n/a";
      if (fields.createdDate) {
        const v = fields.createdDate.value;
        if (v !== "") {
          cd = new Date(v).toLocaleDateString();
        }
      }

      const row = [
        record.recordName,
        cd,
        fields.name.value,
        groupId,
        fields.frameTypeIndex.value,
        fields.hiveTypeIndex.value,
        fields.queenRaceIndex.value,
        fields.queenWingCut.value,
        fields.queenYear.value,
        this.csvSafeFormatString(description),
      ];
      rows.push(row);
    }
    return rows;
  }

  genericEventDataRows(records) {
    var rows = [];
    for (var i=0; i<records.length; i++) {
      const record = records[i];
      const fields = record.fields;

      const hRef = fields.hiveRef;
      const gRef = fields.groupRef;
      const hiveOrGroupId = hRef ? hRef.value.recordName : (gRef ? gRef.value.recordName : '?');

      var cd = "n/a";
      if (fields.date) {
        const v = fields.date.value;
        if (v !== "") {
          cd = new Date(v).toLocaleDateString();
        }
      }

      const typeId = fields.typeId.value;

 // TODO: Gör definitionsfiler!!!
      var generic = '';
      if (typeId === 0) { // Inspektion
        generic +=
          "Synliga ägg: " + fields.eggVisible.value + " | " +
          "Synligt yngel: " + fields.larvasVisible.value + " | " +
          "Synliga viceceller: " + fields.queenCellsVisible.value + " | " +
          "Drottning observerad: " + fields.queenObserved.value + " | " +
          "Antal yngelramar: " + fields.numberOfBroodFrames.value + " | " +
          "Styrka: " + fields.hiveStrength.value + " | " +
          "Temperament: " + fields.temperamentTypeIdNumber.value + " | " +
          "Tillgängång foder: " + fields.foodIsAvailable.value + " | " +
          "Expansionsutrymme: " + fields.expansionAllowed.value;
      }
      else if (typeId === 1) { // Anteckning
      }
      else if (typeId === 2) { // skörd
        generic += "BatchID: " + fields.harvestBatchId.value + " / Mängd: " + fields.slungatKg.value;
      }
      else if (typeId === 3) { // inps tillsynsman
      }
      else if (typeId === 4) { // invintring
      }
      else if (typeId === 5) { // utfordr
        const d = ["Annat", "Foderdeg", "Fodersaft", "Sockerlösning", "Honungsram"];
        generic += d[fields.feedingTypeIdNumber.value];
      }
      else if (typeId === 6) { // drönarram
        const d = ["[x--]", "[-x-]", "[--x]", "[x-]", "[-x]", "Hel ram"];
        generic += d[fields.droneFrameCutOutTypeIdNumber.value];
      }
      else if (typeId === 7) { // rensn
      }
      else if (typeId === 8) { // Förlorat
        generic += EventItemUtil.d8constants[fields.hiveDeathTypeIdNumber.value];
      }
      else if (typeId === 9) { // svärm
      }
      else if (typeId === 10) { // Drottningbyt
      }
      else if (typeId === 11) { // Avläggare
      }
      else if (typeId === 12) { // hälso
        generic += EventItemUtil.d12constants[fields.medicationTypeIdNumber.value];
      }
      else if (typeId === 13) { // avyttrat
      }
      else if (typeId === 14) { // rapport
      }
      else if (typeId === 15) { // Bistick
      }
      else {
      }

      var description = fields.notes.value;

      const hInG = fields.hivesInGroupWhenCreated;
      if (hInG) {
        if (hInG.value !== "") {
          description += " | Ingående samhällen (id för samhälle): " + hInG.value;
        }
      }

      const row = [
        record.recordName,
        cd,
        typeId,
        hiveOrGroupId,
        this.csvSafeFormatString(description),
        this.csvSafeFormatString(generic),
      ];
      rows.push(row);
    }
    return rows;
  }

  hiveEventDataRows(/*forPrivateDb = true*/) {
    return this.genericEventDataRows(this.state.hiveEvents);
  }

  groupEventDataRows(/*forPrivateDb = true*/) {
    return this.genericEventDataRows(this.state.groupEvents);
  }

  frameTypeDataRows() {
    const ids = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 133, 14, 15];
    const names = ["Svea", "Halv Svea", "Lågnormal (LN)", "Lågnormal Svea (LS)", "Halv Lågnormal Svea (Halv LS)", "Norsk", "Halv Norsk", "Wieslander", "Hoffmann Lågnormal", "Langstroth", "Farrar (3/4 Langstroth)", "Shallow (låglangstroth)", "Dadant", "Flow Hive (Langstroth)", "Flow Hive (Lågnormal)", "Vaxkaka på topplist", "Halmkupa"];

    var rows = [];
    for (var i=0; i<ids.length; i++) {
      const row = [
        ids[i],
        names[i],
      ];
      rows.push(row);
    }
    return rows;
  }

  hiveTypeDataRows() {
    const ids = [0, 1, 2, 3, 4, 5, 6];
    const names = ["Trågkupa", "Stapling/vandringskupa", "Halmkupa", "Avläggare i trågkupa", "Flow Hive", "Topplistkupa", "Apidea"];

    var rows = [];
    for (var i=0; i<ids.length; i++) {
      const row = [
        ids[i],
        names[i],
      ];
      rows.push(row);
    }
    return rows;
  }

  queenRaceDataRows() {
    const ids = [0, 1, 2, 3, 4, 5, 6, 7];
    const names = ["Italiensk", "Krainer", "Nordisk", "Kaukasisk", "Buckfast", "Annan", "Elgon", "Drottning saknas"]; // Måste synkas med appen

    var rows = [];
    for (var i=0; i<ids.length; i++) {
      const row = [
        ids[i],
        names[i],
      ];
      rows.push(row);
    }
    return rows;
  }

  eventTypeDataRows() {
    const ids = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 100, 101];
    const names = ["Inspektion", "Anteckning", "Skörd", "Inspektion tillsynsman", "Invintring", "Utfordring", "Drönarram utskuren", "Rensningsflykt", "Förlorat samhälle", "Svärmning", "Drottningbyte", "Avläggare", "Hälsoåtgärd", "Avyttrat samhälle", "Rapport av uppställning", "Bild", "Film"];
    var rows = [];
    for (var i=0; i<ids.length; i++) {
      const row = [
        ids[i],
        names[i],
      ];
      rows.push(row);
    }
    return rows;
  }


  // ---------------------------------------------------------------------------
  // Util
  // ---------------------------------------------------------------------------

  fileName(customStr) {
    return 'Mina bigårdar, ' + new Date().toLocaleDateString() + ' (' + customStr + ').csv';
  }


  // ---------------------------------------------------------------------------
  // Render
  // ---------------------------------------------------------------------------

  renderMigrateToMyApiaryJournal() {
    const {groups, hives} = this.state;

    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">[BILD] Överför all din data till appen <i>My Apiary Journal</i>. Innan du kan överföra din data behöver du ladda ner appen (länk).</div>
        <div/>
        <MigrateToMyApiaryJournalButton title={'Överför'} email={this.email} password={this.password} groups={groups} hives={hives} /*...*//>
      </div>
    );
  }


  renderDownloadAllData(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">All data (bigårdar, samhällen, bigårdhändelser, händelser och definitioner)</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('alla data')}
          data={this.allDataAsCSVString()} />
      </div>
    );
  }


  renderDownloadOnlyGroups(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Endast bigårdar</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('bigårdar')}
          data={this.groupDataAsCSVString()} />
      </div>
    );
  }

  renderDownloadOnlyHives(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Endast samhällen</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('samhällen')}
          data={this.hiveDataAsCSVString()} />
      </div>
    );
  }

  renderDownloadOnlyHiveEvents(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Endast händelser</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('händelser')}
          data={this.hiveEventDataAsCSVString()} />
      </div>
    );
  }

  renderDownloadOnlyGroupEvents(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Endast bigårdhändelser</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('bigårdhändelser')}
          data={this.groupEventDataAsCSVString()} />
      </div>
    );
  }


  renderDownloadFrameTypes(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Ramformat</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('ramformat')}
          data={this.frameTypeDataAsCSVString()} />
      </div>
    );
  }

  renderDownloadHiveTypes(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Kuptyper</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('kuptyper')}
          data={this.hiveTypeDataAsCSVString()} />
      </div>
    );
  }

  renderDownloadQueenRace(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Drottningraser</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('drottningraser')}
          data={this.queenRaceDataAsCSVString()} />
      </div>
    );
  }

  renderDownloadEventTypes(fileName) {
    return (
      <div className="buttonRowLCR space-above-small">
        <div className="p-small">Händelsetyper</div>
        <div/>
        <DownloadFileButton
          title={'Ladda ner CSV-fil'}
          fileName={this.fileName('händelsetyper')}
          data={this.eventTypeDataAsCSVString()} />
      </div>
    );
  }

  render() {
    const {title} = this.state;

// 1. genereing av data får itne ske vid renderar
// 2. exportera frametype, hivetype, queenrace,
// 3. exportera events

// -----

// lägg till stöd för markägare...?

// index måste exporteras separat för alla datatyper som refereras av csv-filen

// I exportsidan, skriv en liten förklaring till vad som ingår, inte ingår, och beskrivning av datat. (tex att det ligger flera datatyper separerade med en rad)

// Skriv att bilder inte ingår i exporten (ännu)

//TODO: Använd json som backupformat. CSV är inte tillräckligt!

//!!!!!!!!!!!!OBS!
// OBS!!! måste fixa så det är först vid klick som csvdatat skapas. Nu görs det hela tiden vid laddning
// så som DownloadFileButton anropas ovan så kommer datat genereras då sidan renderar....
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// Flytta in denna koden innan "All data" nedan:
  // <div className="underlineRow space-above-medium">Överför all data till My Apiary Journal</div>
  // {this.renderMigrateToMyApiaryJournal()}

    if (this.state.groupEvents) {
      if (this.state.hiveEvents) {
        return (
          <div className="ToolsPage">
            <PageHeader title={title} hidePrintButton={true} />

            <div className="p-small">
              Här kan du exportera all data som du registrerat i appen.<br/>
              <br/>
              Du kan välja att exportera till formatet CSV (comma separated file format), vilket kan importeras i tex Excel eller Numbers.<br/>
              <br/>
              Du kan välja att ladda ner all data i en enda fil, eller endast delmängder. Definitionerna innehåller information om hur ramformat, kuptyper och drottningraser ska tolkas.<br/>
              <br/>
              <b><i>OBS: Exportfunktionen är under utveckling och har ännu inte stöd för export av bilder och kontaktinformation. Tills vidare kan du endast exportera din egen data, och alltså inte data någon delat ut till dig.</i></b>
            </div>

            <div className="underlineRow space-above-medium">All data</div>
            {this.renderDownloadAllData()}

            <div className="underlineRow space-above-medium">Delmängder</div>
            {this.renderDownloadOnlyGroups()}
            {this.renderDownloadOnlyHives()}
            {this.renderDownloadOnlyGroupEvents()}
            {this.renderDownloadOnlyHiveEvents()}

            <div className="underlineRow space-above-medium">Definitioner</div>
            {this.renderDownloadFrameTypes()}
            {this.renderDownloadHiveTypes()}
            {this.renderDownloadQueenRace()}
            {this.renderDownloadEventTypes()}

          </div>
        );
      }
      else {
        return <LoadingIndicator label={'hive Events...'}/>;
      }
    }
    else {
      return <LoadingIndicator label={'group Events...'}/>;
    }

  }
}

export default ToolsPage;
