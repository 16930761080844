import React, { Component } from 'react';
import './../css/EventImageItem.css';

class EventImageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: this.props.image,
    };
  }

  render() {
    return (
      <div className="EventImageItem">
        <img src={this.state.image} className="EventImageItem-image" alt={''} />
      </div>
    );
  }
}

export default EventImageItem;
