import React, { Component } from 'react';
import * as Constants from './Constants';
import './../css/GroupHiveArrangementArea.css';
//import SegmentControllerItem from './SegmentControllerItem';
import LoadingIndicator from './LoadingIndicator';
// import ToggleDetailsItem from './ToggleDetailsItem';
import * as SortOrderUtil from './SortOrderUtil';

class GroupHiveArrangementArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hivesInGroupRecords: this.props.hivesInGroupRecords,
      groupRecord: this.props.groupRecord,
      expanded: this.props.expanded,
    };
  }

  updateHiveSortOrderInCK(hiveRecord, fieldName, sortOrder) {
    // console.log("updateHiveSortOrderInCK", hiveRecord, sortOrder);
    if (hiveRecord) {
      window.ckUtilInstance.updateHive(hiveRecord, {fieldName :{"value": sortOrder}});
    }
    else {
      console.error('missing hive record');
    }
  }

// TODO_RADERA!!!!
  testupdatesortorder(hives) {
    console.log("!!!!!!!!!!!!!!!!!!RADERA!!!!!!!!!!!!!!!!!!!");
    if (hives.length > 0) {
      this.updateHiveSortOrderInCK(hives[0], "7:9");
    }
  }

  hasSecondaryOrPrimary(idx, hive, value) {
    if (idx !== Constants.idxForPrimary && idx !== Constants.idxForSecondary) {
      console.log("invalid idx", idx);
      return -1;
    }
    var sortOrderString = SortOrderUtil.sortOrderComponentsForHive(hive);
    return Number(sortOrderString[idx]) === Number(value);
  }
  hasPrimary(hive, value) { return this.hasSecondaryOrPrimary(Constants.idxForPrimary, hive, value); }
  hasSecondary(hive, value) { return this.hasSecondaryOrPrimary(Constants.idxForSecondary, hive, value); }

  getMaxPrimaryOrSecondary(hives, idx) {
    if (idx !== Constants.idxForPrimary && idx !== Constants.idxForSecondary) {
      console.log("invalid idx", idx);
      return 0;
    }

    var max = 0;
    hives.forEach((hive) => {
      var x = SortOrderUtil.sortOrderComponentsForHive(hive)[idx];
      //console.log('MMM', hive, x, max);
      if (x > max) { max = x; };
    });
    return Number(max);
  }

  getHivesForPrimary(p) {
    const {hivesInGroupRecords} = this.state;
    var r = hivesInGroupRecords.filter((hive) => {
      return this.hasPrimary(hive, p);
    });
    //console.log("•••hivesInGroupRecords", s, hivesInGroupRecords, r);
    return r;
  }

  getHivesForSecondary(s) {
    const {hivesInGroupRecords} = this.state;
    var r = hivesInGroupRecords.filter((hive) => {
      return this.hasSecondary(hive, s);
    });
    //console.log("•••hivesInGroupRecords", s, hivesInGroupRecords, r);
    return r;
  }

  renderHorizontalHivesList() {
    const {groupRecord, hivesInGroupRecords} = this.state;

    var allColumns = [];

    const maxPrimary = this.getMaxPrimaryOrSecondary(hivesInGroupRecords, Constants.idxForPrimary);
    const maxSecondary = this.getMaxPrimaryOrSecondary(hivesInGroupRecords, Constants.idxForSecondary);
    // console.log("••• MAX", maxPrimary, maxSecondary);

    const kDate = new Date().getTime();

    for (var p=0; p<=maxPrimary; p++) {
      var allRowsInCol = [];

      const pKey = p + kDate + groupRecord.recordName;

      const hivesForPrimarySortedOnSecondary = this.getHivesForPrimary(p).sort((h0, h1) => {
        const a = SortOrderUtil.sortOrderComponentsForHive(h0)[Constants.idxForSecondary];
        const b = SortOrderUtil.sortOrderComponentsForHive(h1)[Constants.idxForSecondary];
        return a > b;
      });

      //console.log("••• hivesForPrimarySortedOnSecondary", hivesForPrimarySortedOnSecondary, p);

      // First, the caption
      allRowsInCol.push(<div className="hiveArrangementSquare itemCaption" key={pKey + "title"}>Plats {p}</div>);


      var maxSecondaryForThisPrimary = -1;
      if (hivesForPrimarySortedOnSecondary.length > 0) {
        const h = hivesForPrimarySortedOnSecondary[hivesForPrimarySortedOnSecondary.length - 1];
        maxSecondaryForThisPrimary = SortOrderUtil.sortOrderComponentsForHive(h)[Constants.idxForSecondary];
      }
// console.log("xxx maxSecondaryForThisPrimary", p, maxSecondaryForThisPrimary);


      // for (var s=0; s<=maxSecondary; s++) {
      for (var s=maxSecondary; s>=0; s--) {
        const sortOrderCandidate = p + ":" + s;
        const sKey = pKey + s;

        const hives = SortOrderUtil.hivesMatchingSortOrder(hivesForPrimarySortedOnSecondary, p, s);
        // const hives = hivesForPrimarySortedOnSecondary.filter((hive) => {
        //   var so = this.sortOrderComponentsForHive(hive);
        //   if (so[0] === p && so[1] === s) {
        //     // console.log("hive match", so, p, s);
        //     return true;
        //   }
        //   else {
        //     // console.log("hive missmatch", p, s);
        //     return false;
        //   }
        // });

        var hive = null;
        if (hives.length > 0) {
          hive = hives[0];
          const sss = SortOrderUtil.sortOrderStringForHive(hive);
          if (sss !== sortOrderCandidate) {
            hive = null;
          }
        }

        if (hive === null) {
          if (s > maxSecondaryForThisPrimary) {
            // allRowsInCol.push(<div className="hiveArrangementSquare itemEmpty" key={sKey}>göm {s}</div>);
            allRowsInCol.push(<div className="hiveArrangementSquare itemEmptyInvisible" key={sKey}></div>);
          }
          else {
            allRowsInCol.push(<div className="hiveArrangementSquare itemEmpty" key={sKey}>{s}</div>);
          }
        }
        else {
          const name = hive.fields.name.value;
          allRowsInCol.push(
            <div className="hiveArrangementSquare itemOccupied" key={sKey}>{name}</div>);
        }

      }
      allColumns.push(<div className="GHAA-column" key={pKey + "col"}>{allRowsInCol}</div>);
    }

    return (
      <div key={"result" + groupRecord.recordName + new Date().getTime()}>
        <div className="GHAA-start" key={"allColumns" + new Date().getTime()}>{allColumns}</div>
      </div>
    );
  }

  renderToggelArea() {
    const {expanded, hivesInGroupRecords} = this.state;

    // const setExpanded = () => {
    //   this.setState({expanded: true});
    // }

    // const setMinimized = () => {
    //   this.setState({expanded: false});
    // }

    // const segmentIfAnyEvents = () => {
    //   // const {eventRecords} = this.state;
    //   if (eventRecords.length > 0) {
    //     return this.renderSegment();
    //   }
    //   return null;
    // }


//<div>[Hela denna sektionen ska kunna minimeras, men börja i utfällt läge]</div>
//<div>[Visa här en redigering/maximering-knapp: poppar upp en full screen-vy där editering tillåts]</div>
// <br/>
// <div onClick={() => this.testupdatesortorder(hivesInGroupRecords)}>UPPDATERA SORTORDER</div>
    if (expanded) {
      //console.log("GroupHiveArrangementArea, expanded");
      return(
        <div>
          visa alla hives i en fullscreen overlay
          <div>knapp Stäng</div>
        </div>
      );
    }
    else {
      //console.log("GroupHiveArrangementArea, !expanded", hivesInGroupRecords);
      if (hivesInGroupRecords.length === 0) {
        return (
          <div>Samhällen saknas</div>
        );
      }
      else {
        return (
          <div>
            {this.renderHorizontalHivesList()}
          </div>
        );
      }
    }
  }

  render() {
    const {hivesInGroupRecords} = this.state;
    if (hivesInGroupRecords) {
      return (
        <div className="GroupHiveArrangementArea">
          {this.renderToggelArea()}
        </div>
      );
    }
    else {
      return <LoadingIndicator label={'Events...'}/>;
    }
  }
}

export default GroupHiveArrangementArea;
