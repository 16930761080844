import React, { Component } from 'react';
import * as Constants from './Constants';
import './../css/NewsletterPage.css';
import PageHeader from './PageHeader';
import newsletterJuni2019Pdf from './../newsletters/Nyhetsbrev_Mina_bigårdar_Juni_2019.pdf';
import pdfIcon from './../img/icons8-pdf_red.png';

class NewsletterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
    };
  }

  aboutTheNewsletter() {
    return (
      <div className="space-above-small p-medium">
          Mina bigårdar utvecklas fortlöpande med nya funktioner i både appen och på webben. Nyhetsbrevet utkommer
          några gånger per år och ger dig tips på hur du kan använda appen mer effektivt, och informerar när nya
          större funktioner släpps i appen och på webben.
      </div>
    );
  }

  signUpForNewsletter() {
    return (
      <div>
        <div className="space-above-medium dark">Hur anmäler jag mig?</div>
        <div className="space-above-small p-medium">
            Anmäl dig till nyhetsbrevet genom att skicka ett mail till <b><a href={Constants.link__newsletterEmailaHref()}>{Constants.link__newsletterEmail}</a></b>.
            <br/>
            <br/>
            Nyhetsbrevet skickas till den email-adress som du skickar anmälan från. Du kan när som helst avbryta prenumerationen, eller
            ändra email genom att meddela detta via ovanstående email.<br/>
            <br/>
            Genom att anmäla dig till nyhetsbrevet godkänner du att dina kontaktuppgifter sparas med syfte att användas för utskick
            av detta nyhetsbrev.
        </div>
      </div>
    );
  }

  newsletterDownloads() {
    return (
      <div className="">
        <div className="space-above-medium dark">Arkiv (klicka för nedladdning av PDF-fil)</div>
        <div className="p-medium newsletter-bkg">
          <div className="space-under-small center-text">
            <a href={newsletterJuni2019Pdf} download="Nyhetsbrev – Mina bigårdar, Juni 2019.pdf"><img src={pdfIcon} alt="" height="40px" /><br />Nyhetsbrev Juni 2019</a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {title} = this.state;

    return (
      <div>
        <PageHeader title={title} hidePrintButton={true} />
        <div className="NewsletterPage w750">
          {this.aboutTheNewsletter()}
          {this.signUpForNewsletter()}
          {this.newsletterDownloads()}
        </div>
      </div>
    );
  }
}

export default NewsletterPage;
