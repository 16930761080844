import React, { Component } from 'react';
import * as Constants from './Constants';
import './../css/HiveItem.css';
import LinkIcon from './LinkIcon';
import EditIcon from './EditIcon';
import LoadingIndicator from './LoadingIndicator';
import HiveImageItem from './HiveImageItem';
import EventsArea from './EventsArea';
import placeholder from '../img/placeholder.png';

export function queenRaceClearTextName(queenRaceIdx) {
  switch (queenRaceIdx) {
    case 0: return "Italiensk";
    case 1: return "Krainer";
    case 2: return "Nordisk";
    case 3: return "Kaukasisk";
    case 4: return "Buckfast";
    case 5: return "Annan";
    case 6: return "Elgon";
    case 7: return "Drottning saknas";
    default: return "?";
  }
}

export function hiveClearTextName(hiveTypeIdx) {
  switch (hiveTypeIdx) {
    case 0: return "Trågkupa";
    case 1: return "Stapling/vandringskupa";
    case 2: return "Halmkupa";
    case 3: return "Avläggare i trågkupa";
    case 4: return "Flow Hive";
    case 5: return "Topplistkupa";
    case 6: return "Apidea"
    default: return "?";
  }
}

export function framesClearTextName(frameTypeIdx) {
  switch (frameTypeIdx) {
    case 0: return "Svea";
    case 1: return "Halv Svea";
    case 2: return "Lågnormal (LN)";
    case 3: return "Lågnormal Svea (LS)";
    case 4: return "Halv Lågnormal Svea (Halv LS)";
    case 5: return "Norsk";
    case 6: return "Halv Norsk";
    case 7: return "Wieslander";
    case 8: return "Hoffmann Lågnormal";
    case 9: return "Langstroth";
    case 10: return "Farrar (3/4 Langstroth)";
    case 11: return "Shallow (låglangstroth)";
    case 12: return "Dadant";
    case 13: return "Flow Hive (Langstroth)";
    case 133: return "Flow Hive (Lågnormal)";
    case 14: return "Vaxkaka på topplist";
    case 15: return "Halmkupa";
    default: return "?";
  }
}

export function yearColorSymbol(hiveRecord) {
  const colorStyleName = ['blueStyle', 'whiteStyle', 'yellowStyle', 'redStyle', 'greenStyle'];
  const year = hiveRecord.fields.queenYear.value;
  const idx = parseInt(year, 10) % 5;
  return (
    <div className="HivesInGroupDetail-twoColGrid-year">
      <div className={colorStyleName[idx]}></div><div className="p-small"> {year}</div>
    </div>
  );
}

export function isWingCut(isWingCut) {
  return isWingCut ? "Ja" : "Nej";
}

// Borde heta BigHiveItem
class HiveItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiveRecord: this.props.hiveRecord,
      showHiveLinkButton: this.props.showHiveLinkButton,
      autoExpandCard: this.props.autoExpandCard,
    };
  }

  renderHiveLink() {
    const {hiveRecord, showHiveLinkButton} = this.state;
    if (showHiveLinkButton) {
      return (
        <LinkIcon
          linkArgument={'/' + Constants.hiveDetailsPageId + '?id=' + hiveRecord.recordName}
          whiteIcon={false} />
        );
    }
    else {
      return null;
    }
  }

  renderEditButton() {
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //console.log("EDIT BUTTON IS DISABED");
    return null;
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    return (
      <EditIcon
        linkArgument={'/'}
        editable={true} />
    );
  }

  renderHarvestFrameTypeIfExist() {
    const {hiveRecord} = this.state;
    if (hiveRecord.fields.harvestFrameTypeIndex) {
      return (
        <div className="HivesInGroupDetail-twoColGrid-auto">
          <div className="p-small">Skattlådor: {framesClearTextName(hiveRecord.fields.harvestFrameTypeIndex.value)}</div>
        </div>
      );
    }
    return null;
  }

  thumbURL(record) {
    const filename = record.fields.thumbnail;
    
    if (filename !== undefined) { // } && filename.value.length > 0) {
      const s = filename.value.downloadURL;
      if (s !== undefined) { // } && s.value.length > 0) {
        return s;
      }
    }

    return placeholder;
  }

  render() {
    const {hiveRecord, autoExpandCard} = this.state;

    // console.log('hiveRecord', hiveRecord);
    const groupRecord = hiveRecord.fields.groupRef.value;
console.log('RENDER HIVE -- här efter blir det bara vit sida, men bara för vissa...', hiveRecord.recordName);
    if (hiveRecord) {
      var sortOrderStr = "Ej angiven";
      if (hiveRecord.fields.sortOrder) {
        sortOrderStr = hiveRecord.fields.sortOrder.value;
      }

      return (
        <div className="HiveItem space-under-small print-break-inside-never">
          <div className="HiveItem-infoArea">
            <div className="pp">
              <div className="buttonRowLR space-under-small">

                <div className="HivesInGroupDetail-twoColGrid-auto">
                  <div>
                    <div className="arrangementBadge">Plats {sortOrderStr[2] === '0' ? sortOrderStr[0] : sortOrderStr}</div>
                    <div className="EditIcon">{this.renderEditButton()}</div>
                  </div>
                  <div className="caption-normal">{hiveRecord.fields.name.value}</div>
                </div>

                {this.renderHiveLink()}
              </div>

              <div className="HiveItem-titleDescriptionSeparator" />

              <div className="r2">
                <div className="HiveItem-thumbnailArea">
                  <div>
                    <HiveImageItem url={this.thumbURL(hiveRecord)} />
                  </div>
                </div>

                <div className="HiveItem-detailsAndEventsArea">

                  <div className="HiveItem-detailsArea">
                    <div className="HiveItem-description p-small">{hiveRecord.fields.description.value}</div>

                    <div className="HiveItem-details">
                      {yearColorSymbol(hiveRecord)}
                      <div className="HivesInGroupDetail-twoColGrid-auto">
                        <div className="caption-small">Drottning:</div>
                        <div className="p-small">{queenRaceClearTextName(hiveRecord.fields.queenRaceIndex.value)}</div>
                      </div>
                      <div className="HivesInGroupDetail-twoColGrid-auto">
                        <div className="caption-small">Vingklippt:</div>
                        <div className="p-small">{isWingCut(hiveRecord.fields.queenWingCut.value)}</div>
                      </div>
                    </div>

                    <div className="HiveItem-details2">
                      <div className="HivesInGroupDetail-twoColGrid-auto">
                        <div className="caption-small">Kupa:</div>
                        <div className="p-small">{hiveClearTextName(hiveRecord.fields.hiveTypeIndex.value)}</div>
                        <br/>
                      </div>
                      <div className="HivesInGroupDetail-twoColGrid-auto">
                        <div className="caption-small">Typ av ramar:</div>
                      </div>
                      <div className="HivesInGroupDetail-twoColGrid-auto">
                        <div className="p-small">Yngelrum: {framesClearTextName(hiveRecord.fields.frameTypeIndex.value)}</div>
                      </div>
                      {this.renderHarvestFrameTypeIfExist()}
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div className="pp">
              <EventsArea hiveRecord={hiveRecord} groupRecord={groupRecord} expanded={autoExpandCard} asTimeLine={false} />
            </div>

          </div>

        </div>
      );
    }
    else {
      return <LoadingIndicator label={'Hive...'}/>;
    }
  }
}

export default HiveItem;
