import React, { Component } from 'react';
import '../css/DownloadFileButton.css';

class DownloadFileButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      fileName: this.props.fileName,
      data: this.props.data,
    };
  }

  downloadAsCSVFromNDimensionArray(filename, data) {
    this.download(filename, 'data:text/csv;charset=utf-8,' + encodeURI(data));
  }

  // downloadAsTestCSV(filename) {
  //   var titles = ['Name', 'Title'];
  //   var dataSet = [
  //      ['Foo', 'programmer'],
  //      ['Bar', 'bus driver'],
  //      ['Moo', 'reindeer hunter']
  //   ];
  //
  //   // const data = this.csvStringFromArray(titles, dataSet);
  //   // this.download(filename, 'data:text/csv;charset=utf-8,' + encodeURI(data));
  //   this.downloadAsCSVFromNDimensionArray('test data.csv', titles, dataSet);
  // }

  // downloadAsText(filename, data) {
  //   this.download(filename, 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
  // }

  download(filename, href) {
    var element = document.createElement('a');
    element.setAttribute('href', href);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    const {title, fileName, data} = this.state;

    // console.log('DLBUTTON', fileName, data);

    return (
        <div className="generic-button" onClick={() => this.downloadAsCSVFromNDimensionArray(fileName, data)}>
          <div className="caption-small">{title}</div>
        </div>
    );
    // return (
    //   <div className="generic-button"
    //     onClick={() => this.downloadAsText(fileName, fileContents)}>
    //     {title}
    //   </div>
    // );
  }
}

export default DownloadFileButton;
