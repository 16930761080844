import React, { Component } from 'react';
import logo from '../img/logo_200x200.png';
import userIcon from '../img/icons8-customer-90.png';
//import clearCookiesIcon from '../img/icons8-cookies-90.png';
import '../css/TopHeader.css';
import * as Constants from './Constants';
import DownloadApp from './DownloadApp';

class TopHeader extends Component {

  onClickedUserIcon() {
    // document.getElementById('userPopup').style.display = "block";
    document.getElementById("userOverlay").style.width = "100%";
  }

  onClickedCloseUserIcon() {
    // alert('onClickedCloseUserIcon');
    // document.getElementById('userPopup').style.display = "none";
    document.getElementById("userOverlay").style.width = "0%";
    return false;
  }

  // renderUserPopup() {
  //   return (
  //     <div id="userPopup" className="TopHeader-userPopup">
  //       <div onClick={this.onClickedCloseUserIcon}>X</div>
  //       Userinfo:
  //       <div id="apple-sign-out-button">Logga ut</div>
  //     </div>
  //   );
  // }

  renderUserButton() {

    // function clearCookies() {
    //   if (window.confirm("Vill du återställa alla inställningar för webbplatsen?")) {
    //     document.cookie.split(';').forEach(function(c) {
    //       document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    //     });
    //
    //     alert("Alla inställningar för webbplatsen har återställts!");
    //   }
    // }

    const isSignedIn = window.ckUtilInstance.isSignedIn();
    if (isSignedIn) {
      return (
        <div className="TopHeader-userIcon no-print" onClick={this.onClickedUserIcon}>
          <div className="TopHeader-userIcon-tab">
            <img src={userIcon} className="TopHeader-userIcon-img" alt=""/>
          </div>
        </div>
      );
    }
    else {
      return null;
      // Visa inte radera-cookies-knappen
      // return (
      //   <div className="TopHeader-userIcon no-print" onClick={clearCookies}>
      //     <div className="TopHeader-userIcon-tab">
      //       <img src={clearCookiesIcon} className="TopHeader-userIcon-img" alt=""/>
      //     </div>
      //   </div>
      // );
    }
  }

  signOutStuff() {
    // alert('signOutStuff');
    window.location.reload(true);
    console.log('gör istället en ck-signout + gå till /');
  }

  userName() {
    const userInfo = window.ckUtilInstance.getUserInfo();
    // console.log(userInfo);
//Ingen av din information finns kvar på datorn om du stänger detta fönster.<br/>
    if (userInfo) {
      return (
        <div className="p-small center-text">
          Klicka på knappen för att logga ut.<br/>
        </div>
      );
      // Klicka på knappen för att logga ut {userInfo.nameComponents.givenName} {userInfo.nameComponents.familyName}.<br/>Ingen av din information finns kvar på datorn efter att du stängt detta fönster.

      // nameComponents verkar itne vara satt i production. Använda någon funktion för att begära datat istället?
      // return (
      //   <div>
      //     Du är inloggad som {userInfo.nameComponents.givenName} {userInfo.nameComponents.familyName}
      //   </div>
      // );
    }
    else {
      return null;
    }
  }

  renderOverlay() {
    const ck = window.ckUtilInstance;
    return (
      <div id="userOverlay" className="overlay">
        <div className="closebtn" onClick={() => this.onClickedCloseUserIcon()}>&times;</div>

        <div className="signOutArea">
          <div className="space-above-big center-text p-small box-item-w60">
            {this.userName()}
            <br/>
            <div className="buttonRowLCR">
              <div/>
              <div id="apple-sign-out-button" onClick={() => this.signOutStuff()} />
              <div/>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            {Constants.appName()} utvecklas av Rickard Dahl som ett hobbyprojekt. Ambitionen är
            att erbjuda, i första hand svenska biodlare ett trevligt och effektivt sätt att hantera
            sin biodling, samt erbjuda funktioner för att kunna samarbeta och ta hjälp av andra biodlare.
            <br/>
            <br/>
            Ny funktionalitet tillkommer fortlöpande och anpassningar görs baserat på feedback. I dagsläget finns det en app för iOS samt denna webbsida. En app för Android är planerad, samt andra digitala lösningar.
            <br/>
            <br/>
            <b>Felsökningsinformation:</b><br/>
            {Constants.defaultZoneName}<br/>
            {JSON.stringify(ck.getUserInfo())}
            <br/>
            <br/>
            <br/>
          </div>
        </div>

        <DownloadApp/ >
      </div>
    );
  }

  getDate() {
    const dateStr = new Date().toLocaleDateString();
    return (<div>{dateStr}</div>);
  }

  render() {
    return (
      <div>
        <div className="TopHeader">
          <div className="TopHeader-title">
            <div className="caption-huge">{Constants.appName()}</div><br/>
            <div className="p-small no-print">Utvecklas av Rickard Dahl</div>
            <div className="p-small no-print text-almost-invisible">Version {Constants.debugMode === true ? "DEV" : Constants.releaseVersion}</div>
            <div className="p-small only-print">{this.getDate()}</div>
          </div>

          <div className="TopHeader-logo">
            <img className="TopHeader-logo-img" src={logo} alt="logo" />
          </div>

          {this.renderUserButton()}

        </div>
        {this.renderOverlay()}
      </div>
    );
  }
}

export default TopHeader;
