import React, { Component } from 'react';
import './../css/HivePage.css';
import PageHeader from './PageHeader';
import SegmentControllerItem from './SegmentControllerItem';
import HivesOverviewTable from './HivesOverviewTable';

class HivePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageId: this.props.pageId,
      title: this.props.title,
      presentationMineOrSubscribed: this.props.presentationMineOrSubscribed, // 0: mine, 1: subscribed
      zones: this.props.zones, // {private, shared}
      groups: this.props.groups, // {private, shared}
      hives: this.props.hives, // {private, shared}
    };
  }

  // NOTES: Denna visar en segment controller, men bara om det finns subscribed groups.
  renderOwnedAndSharedSegment() {
    const {sharedGroups, presentationMineOrSubscribed} = this.state;
    const existSubscribedGroups = sharedGroups !== null;

    if (existSubscribedGroups) {
      const setPresentationToMine = () => {
        this.setState({presentationMineOrSubscribed: 0});
      }

      const setPresentationToSubscribed = () => {
        this.setState({presentationMineOrSubscribed: 1});
      }

      return (
        <div className="buttonRowLCR">
          <div />

          <SegmentControllerItem
            titles={['Mina', 'Delade']}
            callbacks={[setPresentationToMine, setPresentationToSubscribed]}
            selectedIndex={presentationMineOrSubscribed}
            key={'sc' + new Date()} />

          <div className="generic-button" onClick={(e) => window.ckUtilInstance.print(e)}>
            <div className="caption-small">Skriv ut</div>
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  render() {
    const {title, hives, groups, presentationMineOrSubscribed} = this.state;
    return (
      <div className="HivePage">
        <PageHeader title={title} hidePrintButton={true}/>

        {this.renderOwnedAndSharedSegment()}

        <div className="space-above-medium" key={title + new Date()}>

        <HivesOverviewTable
          groups={groups}
          hives={hives}
          presentationMineOrSubscribed={presentationMineOrSubscribed}
          key={'HOT' + new Date()} />
        </div>
      </div>
    );
  }
}

export default HivePage;
