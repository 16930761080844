import React, { Component } from 'react';
// import * as Constants from './Constants';
//import '../css/InfoUseAppInstead.css';
import DownloadApp from './DownloadApp';

class InfoUseAppInstead extends Component {
  render() {
    return (
      <div>

          <div className="space-above-big space-under-small center-text">
            <div className="caption-huge">
              Ladda ner appen<br/>
              för iPhone/iPad!
            </div>
          </div>
          <div className="center-box">
            <div className="box-item-w70">
              <div className="p-small center-text space-under-medium">
                Mina bigårdar på webben är ett komplement
                till appen, och är avsedd att användas på
                en dator eller tablet.
              </div>

              <DownloadApp/ >
            </div>
          </div>

      </div>
    );
  }
}

export default InfoUseAppInstead;
